import Style from "./SignInModal.module.scss";
import { useEffect, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useLogin } from "../../../Logic/useLogin";
import { Dropdown } from "react-bootstrap";
import * as Yup from "yup";
import CountryCodeInput from "../../../utils/CountryCodeInput";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../Layouts/Assets";
import { useTranslation } from "react-i18next";
import {
  changeScreen,
  storeCurrentAuthData,
} from "../../../store/slices/authSlice";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";

let hasErrors = false;
let hasErrorsPhone = false;

const SignInModal = ({ handleClickRegister, handleClickFP, status }) => {
  const dispatch = useDispatch();

  const { currentAuthData, loginCreateOtpStatus } = useSelector(
    (state) => state.auth
  );
  const { i18n, t } = useTranslation(["authentication"]);
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const [tab, setTab] = useState("login_email");
  const [isVisible, setIsVisible] = useState(false);
  const [code, setCode] = useState(+91);
  const {
    loginUserFunction,
    loginCreateOtpFunction,
    loginErrors,
    setLoginErrors,
  } = useLogin();
  const [isKeyDown, setIsKeyDown] = useState(false);

  const [loginInputs, setLoginInputs] = useState({
    phone_number: "",
    country_dial_code: "+91",
    country_code: "IN",
  });

  useEffect(() => {
    return () => {
      setTab("login_email");
    };
  }, []);

  // useEffect(() => {
  //   let setAddClass = document.querySelector("html").classList;
  //   setAddClass.toggle("modal-shown", );
  // }, [isKeyDown]);

  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(isKeyDown === false);
  }, [isKeyDown]);

  const handleVisibility = (e) => {
    e.preventDefault();
    if (!isKeyDown) {
      setIsVisible(!isVisible);
    }
  };

  useEffect(() => {
    if (currentAuthData?.pageFrom) {
      setTab(currentAuthData?.pageFrom);
    }

    setLoginInputs({
      ...loginInputs,
      country_dial_code: currentAuthData.country_dial_code
        ? currentAuthData.country_dial_code
        : loginInputs.country_dial_code,
      phone_number: currentAuthData?.phone_number
        ? currentAuthData?.phone_number
        : loginInputs.phone_number,
    });
  }, [currentAuthData]);

  const nameRegExp = /^[a-zA-Z ]+$/;
  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const loginEmailSchema = Yup.object().shape({
    mail: Yup.string()
      .email("Invalid email format.")
      .required("Email is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password lentgh must be a minimum of six."),
  });

  const loginPhoneSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .required("Mobile number is required")
      .min(7, "Invalid  mobile number")
      .max(14, "Invalid  mobile number"),
  });

  useEffect(() => {
    // setTimeout(() => {
    //   if (tab) {
    //     document.querySelectorAll(".modal input")[0].focus();
    //   }
    // }, 500);
  }, [tab]);

  const handleTabChange = (type) => {
    if (type == "login_email") {
      setTab("login_email");
      if (currentAuthData?.phone_number) {
        dispatch(storeCurrentAuthData({}));
      }
    } else {
      setTab("login_phone");
      setIsEmailChanged(false);
    }
    setLoginErrors("");
  };

  const handleGotoRegister = () => {
    handleClickRegister();
    dispatch(storeCurrentAuthData({}));
  };

  return (
    <div className={Style.signin_modal}>
      <div
        className={Style.signin_modal_content}
        onClick={(e) => e.stopPropagation()}
      >
        <>
          <div className={Style.signin_modal_header}>
            <h4>{t("sign_in_title")}</h4>
          </div>
          <div className={Style.signin_modal_tabs}>
            <div
              className={Style.tab_item}
              onClick={() => handleTabChange("login_email")}
            >
              <span>{t("sign_in_tab1")}</span>
            </div>
            <div
              className={Style.tab_item}
              onClick={() => handleTabChange("login_phone")}
            >
              <span>{t("sign_in_tab2")}</span>
            </div>
            <div
              className={Style.tab}
              style={{ left: tab === "login_email" ? "1%" : "51%" }}
            />
          </div>
          {tab === "login_email" ? (
            <Formik
              initialValues={{
                mail: "",
                password: "",
              }}
              onSubmit={(values) => {
                loginUserFunction(values);
              }}
              validationSchema={loginEmailSchema}
            >
              {({
                errors,
                touched,
                values,
                handleBlur,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => {
                hasErrors = Object.keys(errors).length == 0;
                return (
                  <Form className={Style.signin_modal_form}>
                    <div
                      className={
                        errors.mail && touched.mail
                          ? "form-group error"
                          : "form-group"
                      }
                    >
                      <label htmlFor="mail">{`${t("register_email")} *`}</label>
                      <div className="input-holder">
                        <input
                          type="email"
                          name="mail"
                          id="mail"
                          value={values.mail}
                          placeholder={t("register_email_placeholder")}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            setIsEmailChanged(true);
                            setFieldValue("mail", e.target.value);
                          }}
                          onClick={(e) => {
                            e.target.value = e.target.value;
                          }}
                          className="form-control"
                          autoComplete="mail"
                        />
                      </div>
                      {/* {isEmailChanged && errors?.mail && touched?.mail ? ( */}
                      <ErrorMessage name="mail" className="field_error_message">
                        {(msg) => (
                          <div
                            style={{
                              color: "#FF6261",
                              fontSize: "13px",
                              marginTop: "5px",
                            }}
                            className="error-msg"
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      {/* // ) : (
                      //   ""
                      // )} */}
                    </div>
                    <div
                      className={
                        errors.password && touched.password
                          ? "form-group error password"
                          : "form-group password"
                      }
                    >
                      <label htmlFor="password">{`${t(
                        "register_pwd"
                      )} *`}</label>
                      <div className="input-holder">
                        <input
                          name="password"
                          value={values.password}
                          className="form-control"
                          placeholder={t("register_password_placeholder")}
                          type={isVisible ? "text" : "password"}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.code == "Enter") {
                              setIsKeyDown(true);
                              handleSubmit();
                            }
                          }}
                        />
                        <button
                          className={`eye ${isVisible ? "show-pwd" : ""}`}
                          onClick={(e) => handleVisibility(e)}
                        >
                          {isVisible ? (
                            <>
                              <img
                                src={Assets.icon_eye_visible}
                                alt="icon-eye"
                              />
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.192"
                                height="15.192"
                                viewBox="0 0 15.192 15.192"
                              >
                                <g
                                  id="Icon_feather-eye-off"
                                  data-name="Icon feather-eye-off"
                                  transform="translate(1.061 1.061)"
                                >
                                  <path
                                    id="Path_68039"
                                    data-name="Path 68039"
                                    d="M11.564,14.282a5.983,5.983,0,0,1-3.529,1.224c-4.159,0-6.535-4.753-6.535-4.753A10.961,10.961,0,0,1,4.506,7.224M6.788,6.143A5.418,5.418,0,0,1,8.035,6c4.159,0,6.535,4.753,6.535,4.753a10.991,10.991,0,0,1-1.283,1.9m-3.992-.636A1.782,1.782,0,1,1,6.776,9.493"
                                    transform="translate(-1.5 -4.218)"
                                    fill="none"
                                    stroke="#a2a4ac"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_68040"
                                    data-name="Path 68040"
                                    d="M1.5,1.5,14.57,14.57"
                                    transform="translate(-1.5 -1.5)"
                                    fill="none"
                                    stroke="#a2a4ac"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </>
                          )}
                        </button>
                        <ErrorMessage
                          name="password"
                          className="field_error_message"
                        >
                          {(msg) => (
                            <div
                              style={{
                                color: "#FF6261",
                                fontSize: "13px",
                                marginTop: "5px",
                              }}
                              className="error-msg"
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className={Style.btn_forgot_password}>
                        <button onClick={() => handleClickFP()}>
                          {t("register_forgot_pwd")}
                        </button>
                      </div>
                    </div>
                    <div className={Style.errorMessage_wrap}>
                      {loginErrors ? (
                        <>
                          <span className={Style.errorMessage}>
                            <p>{loginErrors}</p>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <button
                      disabled={!hasErrors || !dirty}
                      type="submit"
                      className={`${
                        Style.signin_button
                      } ${"btn btn-secondary w-100"} ${
                        status == "loading" ? Style.otp_continue_btn_loader : ""
                      } `}
                    >
                      {status == "loading" ? "" : t("sign_in")}
                      {/* {t("sign_in")} */}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Formik
              initialValues={loginInputs}
              onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                loginCreateOtpFunction(values);
              }}
              validationSchema={loginPhoneSchema}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                values,
                handleBlur,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => {
                // console.log(values, "mobile login");

                hasErrorsPhone = Object.keys(errors).length == 0;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className={Style.signin_modal_form}
                  >
                    <CountryCodeInput
                      handleChangeHandler={(e) => {
                        if (e.target.value.length < 15) {
                          setFieldValue("phone_number", e.target.value);
                          if (!values?.country_dial_code) {
                            setFieldValue("country_dial_code", "+91");
                            setFieldValue("country_code", "IN");
                          }
                        }
                      }}
                      handleChangeCode={(c) =>
                        setFieldValue("country_dial_code", c)
                      }
                      handleChangeCountryCode={(c) => {
                        setFieldValue("country_code", c);
                      }}
                      currentAuthData={currentAuthData}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      values={values}
                      touched={touched?.phone_number}
                      errors={errors?.phone_number}
                      placeholder={t("register_phone_placeholder")}
                    />

                    <div className="my-1">
                      <div className={`${Style.errorMessage_wrap} py-1`}>
                        {loginErrors ? (
                          <>
                            <span className={Style.errorMessage}>
                              <p>{loginErrors}</p>
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <button
                      disabled={!hasErrorsPhone}
                      type="submit"
                      className={`${
                        Style.signin_button
                      } ${"btn btn-secondary w-100"} ${
                        loginCreateOtpStatus == "loading"
                          ? Style.otp_continue_btn_loader
                          : ""
                      }`}
                    >
                      {loginCreateOtpStatus == "loading"
                        ? ""
                        : t("sign_in_btn")}
                      {/* {t("sign_in_btn")} */}
                    </button>
                  </form>
                );
              }}
            </Formik>
          )}

          <div className={`${Style.signin_modal_create_account} mt-4`}>
            <span>{t("sign_in_create_account")}</span>
            <button onClick={() => handleGotoRegister()}>
              {t("sign_in_create_account_btn")}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default SignInModal;
