import Style from "./CorporateHomeMemoriesSlide.module.scss";
import Assets from "../../Layouts/Assets";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CorporateHomeMemoriesSlide = () => {
    return (
      <>
      <div className={Style.home_memories}>
      <div className={`${Style.home_memories_column} ${Style.memory_group} slider-column`}>
          <div className={Style.column_top}>
            <div className={Style.column_top_left}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_1} anim load-anim`}>
                <LazyLoadImage src={Assets.memories_immortal_01} alt="memory_1" />
              </figure>
              <figure className={`${Style.home_memories_image} ${Style.aspect_2} anim load-anim`}>
                <LazyLoadImage src={Assets.memories_immortal_02} alt="memory_2" />
              </figure>
            </div>
            <div className={Style.column_top_right}>
              <figure className={`${Style.home_memories_image} ${Style.aspect_3} anim load-anim`}>
                <LazyLoadImage src={Assets.memories_immortal_03} alt="memory_3" />
              </figure>
            </div>
          </div>
          <div className={Style.column_bottom}>
            <figure className={`${Style.home_memories_image} ${Style.aspect_4} anim load-anim`}>
              <LazyLoadImage src={Assets.memories_immortal_04} alt="memory_4" />
            </figure>
          </div>
        </div>

        <div className={`${Style.home_memories_column} slider-column`}>
          <figure className={`${Style.home_memories_image} ${Style.aspect_6} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_05} alt="memory_6" />
          </figure>
        </div>

        <div className={`${Style.home_memories_column} slider-column`}>
          <figure className={`${Style.home_memories_image} ${Style.aspect_7} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_06} alt="memory_7" />
          </figure>
          <figure className={`${Style.home_memories_image} ${Style.aspect_8} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_07} alt="memory_8" />
          </figure>
        </div>

        <div className={`${Style.home_memories_column} slider-column`}>
          <figure className={`${Style.home_memories_image} ${Style.aspect_9} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_08} alt="memory_9" />
          </figure>
        </div>

        <div className={`${Style.home_memories_column} slider-column`}>
          <figure className={`${Style.home_memories_image} ${Style.aspect_8} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_09} alt="memory_10" />
          </figure>
          <figure className={`${Style.home_memories_image} ${Style.aspect_7} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_10} alt="memory_11" />
          </figure>
        </div>

        <div className={`${Style.home_memories_column} slider-column`}>
          <figure className={`${Style.home_memories_image} ${Style.aspect_6} anim load-anim`}>
            <LazyLoadImage src={Assets.memories_immortal_11} alt="memory_6" />
          </figure>
        </div>

        </div>
      </>
    );
};
export default CorporateHomeMemoriesSlide;