import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Style from "./FullScreenPreviewNew.module.scss";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useSingleKey } from "../../../utils/KeyBoardShortCuts/index";
import Icons from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";
import Zoom from "../Zoom";
import ShareModal from "../../ShareModal";
import { useSocial } from "../../../Logic/useSocial";
import { usePreview } from "./usePreview";
import { useControls } from "./useControls";
import CustomButton from "./CustomButton";
import MoreInfo from "./MoreInfo";
import SlideViewer from "./SlideViewer";
import { useGalleryTab } from "../../../Logic/GalleryHooks/useGalleryTab";

const FullscreenPreviewNew = ({
    previewObj,
    openSlideShow,
    showFullView,
    setShowFullView,
    total
}) => {
    const windowDimensions = useDimensions();
    const {
        swiperRef,
        slideCount,
        menuVisible,
        navAreaLeftHover,
        navAreaRightHover,
        setMenuVisible,
        setSlideCount,
        previousHandler,
        nextHandler,
        setNavAreaLeftHover,
        setNavAreaRightHover,
        show,
        setNavHover,
        handleInfoClose,
        handleInfoShow
    } = useControls({ data: previewObj.images })

    const { zoomRef, isZoom, fullViewThumbs, fullViewThumbIndex, setFullViewThumbs, setFullView, handleZoom, closeButtonHandle, handleEscape, initialIndex, setInitialIndex, handleSlideChange } = usePreview({ showFullView, setShowFullView, setSlideCount })
    const { fetchSocialDetails, secretToken, openShare, setOpenShare } = useSocial();
    const { loadMore } = useGalleryTab()



    // useEffect(() => {
    // setInitialIndex(previewObj?.images?.findIndex((item) => item.id == showFullView.imageId));
    // }, [showFullView]);




    const handleOpenShareModal = () => {
        setOpenShare(!openShare);
        fetchSocialDetails(previewObj?.images[fullViewThumbIndex]?.id);
    };

    const slideChangeHandler = (e) => {
        if (e.activeIndex == previewObj?.images?.length - 1) {
            // loadMore();
        }
    };

    useSingleKey("ArrowLeft", previousHandler);
    useSingleKey("ArrowRight", nextHandler);
    useSingleKey("Escape", handleEscape);

    //FIXME using old code for now


    return ReactDOM.createPortal(
        <>
            <div className={`${Style.album_full_view} ${isZoom && menuVisible ? "menu-visible" : ""} ${isZoom ? Style.zoom_view : ""} 
             ${navAreaLeftHover && swiperRef?.current?.swiper?.activeIndex !== 0 ? Style.nav_left_active : ""} 
             ${navAreaRightHover &&
                    swiperRef?.current?.swiper?.activeIndex !==
                    swiperRef?.current?.swiper?.slides?.length - 1
                    ? Style.nav_right_active
                    : ""
                }`}
                ref={zoomRef}
            >
                {!isZoom && (
                    <>
                        <div
                            className={Style.nav_area_left}
                            onClick={previousHandler}
                            onMouseEnter={() => setNavAreaLeftHover(true)}
                            onMouseLeave={() => setNavAreaLeftHover(false)}
                        ></div>
                        <div
                            className={Style.nav_area_right}
                            onClick={nextHandler}
                            onMouseEnter={() => setNavAreaRightHover(true)}
                            onMouseLeave={() => setNavAreaRightHover(false)}
                        ></div>
                        <button
                            className={`${Style.control_btn} swiper-button-prev`}
                            onClick={previousHandler}
                            onMouseEnter={() => setNavAreaLeftHover(true)}
                            onMouseLeave={() => setNavAreaLeftHover(false)}
                        >
                            <Icons icon={"prev-arrow"} size={15} />
                        </button>
                        <button
                            className={`${Style.control_btn} swiper-button-next`}
                            onClick={nextHandler}
                            onMouseEnter={() => setNavAreaRightHover(true)}
                            onMouseLeave={() => setNavAreaRightHover(false)}
                        >
                            <Icons icon={"next-arrow"} size={15} />
                        </button>
                    </>
                )}

                <div
                    className={`${Style.album_full_view_header} ${menuVisible ? Style.menu_show : ""
                        }`}
                >
                    <div className={Style.album_full_view_header_leftnav}>
                        {!isZoom ? (
                            <button
                                className={Style.album_full_view_header_btn}
                                onClick={() => closeButtonHandle()}
                                onMouseEnter={() => setNavHover(true)}
                                onMouseLeave={() => setNavHover(false)}
                            >
                                <Icons
                                    icon={"back-arrow"}
                                    size={windowDimensions.width >= 1279 ? 20 : 14}
                                />
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className={Style.album_full_view_header_rightnav}>
                        {windowDimensions.width < 992 ? (
                            <>
                                {/* <button className={Style.album_full_view_header_btn_mob}>
                                    <Link
                                        to={`/dashboard/image-edit/${pathFrom}/${ImageEditSection}/${data[fullViewThumbIndex]?.id}`}
                                    >
                                        <Icons icon={"edit"} size={17} />
                                    </Link>
                                </button>
                                <button
                                    className={`${Style.album_full_view_header_btn_mob} ${Style.tray_actions_btn_no_like}`}
                                >
                                    <Icons icon={"icon-love"} size={17} />
                                </button>
                                {data?.length > 1 ? (
                                    <button className={Style.album_full_view_header_btn_mob}>
                                        <Icons icon={"slideshow"} size={17} />
                                    </button>
                                ) : (
                                    ""
                                )}

                                <button
                                    className={Style.album_full_view_header_btn_mob}
                                    onClick={() => handleInfoShow()}
                                >
                                    <Icons icon={"info"} size={18} />
                                </button> */}
                            </>
                        ) : !isZoom ? (
                            <>
                                <CustomButton
                                    baseClass={Style.album_full_view_header_btn}
                                    onMouseEnter={() => {
                                        setNavAreaRightHover(true);
                                        setNavHover(true);
                                    }}
                                    onMouseLeave={() => {
                                        setNavAreaRightHover(false);
                                        setNavHover(false);
                                    }}
                                    onClick={() => handleOpenShareModal()}
                                    iconName={"share2"}
                                    iconSize={17}
                                    LinkUrl={""}
                                    label={"Share"}
                                />

                                {previewObj.pathFrom !== "searchlist" ? (

                                    <CustomButton
                                        baseClass={Style.album_full_view_header_btn}
                                        onMouseEnter={() => {
                                            setNavAreaRightHover(true);
                                            setNavHover(true);
                                        }}
                                        onMouseLeave={() => {
                                            setNavAreaRightHover(false);
                                            setNavHover(false);
                                        }}
                                        onClick={() => handleOpenShareModal()}
                                        iconName={"edit"}
                                        iconSize={17}
                                        LinkUrl={`/dashboard/image-edit/${previewObj.pathFrom}/${previewObj.section}/${previewObj?.images[fullViewThumbIndex]?.id}`}
                                        label={"Edit"}
                                    />

                                ) : (
                                    ""
                                )}
                                {previewObj?.images?.length > 1 ? (
                                    <CustomButton
                                        baseClass={Style.album_full_view_header_btn}
                                        onMouseEnter={() => {
                                            setNavAreaRightHover(true);
                                            setNavHover(true);
                                        }}
                                        onMouseLeave={() => {
                                            setNavAreaRightHover(false);
                                            setNavHover(false);
                                        }}
                                        onClick={() => openSlideShow()}
                                        iconName={"slideshow"}
                                        iconSize={20}
                                        LinkUrl={""}
                                        label={"Slideshow"}
                                    />
                                ) : (
                                    ""
                                )}

                                <CustomButton
                                    baseClass={Style.album_full_view_header_btn}
                                    onMouseEnter={() => {
                                        setNavAreaRightHover(true);
                                        setNavHover(true);
                                    }}
                                    onMouseLeave={() => {
                                        setNavAreaRightHover(false);
                                        setNavHover(false);
                                    }}
                                    onClick={() => handleInfoShow()}
                                    iconName={"info"}
                                    iconSize={20}
                                    LinkUrl={""}
                                    label={" More Info"}
                                />
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                {isZoom ? (
                    <Zoom
                        imgData={previewObj?.images[slideCount]?.src}
                        currentIndex={slideCount}
                        allData={previewObj?.images}
                        handleClose={() => handleEscape()}
                    />
                ) : (
                    <>
                        {previewObj?.images?.length > 0 ? (
                            <SlideViewer
                                swiperRef={swiperRef}
                                data={previewObj?.images}
                                initialIndex={initialIndex}
                                slideCount={slideCount}
                                initialSlideIndex={showFullView.index}
                                fullViewThumbs={fullViewThumbs}
                                setFullView={setFullView}
                                setFullViewThumbs={setFullViewThumbs}
                                handleSlideChange={handleSlideChange}
                                fullViewThumbIndex={fullViewThumbIndex}
                                handleZoom={handleZoom}
                                slideChangeHandler={slideChangeHandler}
                                total={total}
                            />
                        ) : (
                            ""
                        )}

                    </>
                )}

                <MoreInfo
                    open={show}
                    close={handleInfoClose}
                    data={previewObj?.images}
                    currentIndex={fullViewThumbIndex}
                    albumIndex={showFullView?.albumId}
                />

                <ShareModal
                    openShare={openShare}
                    setOpenShare={setOpenShare}
                    from="zoomView"
                    secretToken={secretToken}
                />

            </div>
        </>,
        document.getElementById("slideshow-root")
    );
};
export default FullscreenPreviewNew;
