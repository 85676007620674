import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  isDeletingAlbum: "idle",
  isDeletingAll: "idle",
  error: null,
  selectedAlbum: "",
  resultData: {},
};

export const getAlbumItem = createAsyncThunk(
  "albumDetails/getAlbumItem",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMoreImages = createAsyncThunk(
  "albumDetails/loadMoreImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-view/${params.id}?page=${params.page}&&sort=${params.slug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAlbumImages = createAsyncThunk(
  "albumDetails/deleteAlbumImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reArrangeAlbum = createAsyncThunk(
  "albumDetails/reArrangeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album-image-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const imageAddToAlbum = createAsyncThunk(
  "albumDetails/imageAddToAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const albumDetailsSlice = createSlice({
  name: "albumDetails",
  initialState,
  reducers: {
    handleSelectedAlbum: (state, action) => {
      state.selectedAlbum = action.payload;
    },

    removeAlbumImagesFromState: (state, action) => {
      let AlbumImages = current(
        state.resultData.data[state.selectedAlbum].images
      );

      let filterAlbumImages = AlbumImages?.filter(
        (item, index) => !action.payload.includes(item.id)
      );

      state.resultData.data[state.selectedAlbum] = {
        ...state.resultData.data[state.selectedAlbum],
        images: state.resultData.data[state.selectedAlbum].images.filter(
          (item, index) => !action.payload.includes(item.id)
        ),

        total: filterAlbumImages?.length,
      };
    },

    rearrange: (state, action) => {
      let draggedIndex = state.resultData.data[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.draggedItem.id);
      let changedIndex = state.resultData.data[
        state.selectedAlbum
      ].images.findIndex((item) => item.id == action.payload.changedItem.id);

      state.resultData.data[state.selectedAlbum].images[draggedIndex].position =
        action.payload.changedItem.position;

      state.resultData.data[state.selectedAlbum].images[changedIndex].position =
        action.payload.draggedItem.position;

      let data1 =
        state.resultData.data[state.selectedAlbum].images[changedIndex];
      let data2 =
        state.resultData.data[state.selectedAlbum].images[draggedIndex];

      state.resultData.data[state.selectedAlbum].images[draggedIndex] = data1;
      state.resultData.data[state.selectedAlbum].images[changedIndex] = data2;
    },

    updateAlbumUploadedImages: (state, action) => {
      state.resultData.data[state.selectedAlbum] = {
        ...state.resultData.data[state.selectedAlbum],
        images: [
          action.payload,
          ...state.resultData.data[state.selectedAlbum].images,
        ],
      };
    },
  },

  extraReducers: {
    [getAlbumItem.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAlbumItem.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = {
        data: {
          ...state.resultData.data,
          [state.selectedAlbum]: { ...action.payload.data.result },
        },
      };
    },
    [getAlbumItem.rejected]: (state, action) => {
      state.status = "failed";
    },


    [deleteAlbumImages.pending]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "loading";
      } else {
        state.isDeletingAll = "loading";
      }

    },
    [deleteAlbumImages.fulfilled]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "succeeded";
      } else {
        state.isDeletingAll = "succeeded";
      }

    },
    [deleteAlbumImages.rejected]: (state, action) => {
      if (action?.meta?.arg?.type == "album") {
        state.isDeletingAlbum = "failed";
      } else {
        state.isDeletingAll = "failed";
      }
    },


    [loadMoreImages.fulfilled]: (state, action) => {
      state.resultData.data[action.payload.data.result.album.id].images = [...state.resultData.data[action.payload.data.result.album.id].images, ...action.payload.data.result.images]
      state.resultData.data[action.payload.data.result.album.id].current_page = action.payload.data.result.current_page;
    },
    [imageAddToAlbum.pending]: (state, action) => {
      state.status = "loading";
    },

    [imageAddToAlbum.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData.data[state.selectedAlbum].images =
        action.payload.data.result.images;
    },

    [imageAddToAlbum.rejected]: (state, action) => {
      state.status = "failed";
    },

    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },
    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = "";
        if (action.payload.data.result.id) {
          imgIndex = current(state.resultData.data[state.selectedAlbum].images).findIndex((obj) => obj.id === action.payload.data.result.id)

        } else {
          imgIndex = current(state.resultData.data[state.selectedAlbum].images).findIndex((obj) => obj.id === action.payload.data.result.image_id)

        }
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags
          state.resultData.data[state.selectedAlbum].images[imgIndex].tags = tagData
        }
      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = state.resultData.data[state.selectedAlbum].images.findIndex((obj) => obj.id === action.payload.data.result.id)
        let tagData = action.payload.data.result.tags
        state.resultData.data[state.selectedAlbum].images[imgIndex].tags = tagData
      }
    },
  },
});
export const {
  handleSelectedAlbum,
  removeAlbumImagesFromState,
  rearrange,
  updateAlbumUploadedImages,
} = albumDetailsSlice.actions;
export default albumDetailsSlice.reducer;
