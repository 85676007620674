import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAlbumItem,
  handleSelectedAlbum,
  loadMoreImages,
  rearrange,
  reArrangeAlbum,
} from "../../../store/slices/Album/AlbumDetails";
import {
  currentAlbumId,
  endTabSession,
  clearTabState,
} from "../../../store/slices/GalleryTabSlice";
import { frameList } from "../../../store/slices/frameSlice";
// import { getGalleryImages, LoadmoreImages } from "../../store/slices/GalleryImagesSlice";

export const useAlbumView = () => {
  const dispatch = useDispatch();
  const AlbumDetails = useSelector((state) => state?.albumDetails);
  const selections = useSelector(
    (state) => state.galleryTab.ImageAlbumDetailsTab
  );

  const albumAddToSelection = useSelector(
    (state) => state.galleryTab.ImageAlbumAddToTab
  );

  const frames = useSelector((state) => state?.frames);

  const { albumId } = useParams();

  useEffect(() => {
    if (frames?.status == "idle") {
      dispatch(frameList());
    }
  }, []);

  useEffect(() => {
    let obj = {
      page: "1",
      slug: selections?.sort,
      id: albumId,
    };
    dispatch(getAlbumItem(obj));
    dispatch(handleSelectedAlbum(albumId));
    dispatch(currentAlbumId(albumId));

    return () => {
      dispatch(clearTabState());
    };
  }, []);

  const rearrangeItems = (dragIndex, dropIndex) => {
    let imgData = AlbumDetails?.resultData?.data?.[albumId].images;
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
        album_id: albumId,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
        album_id: albumId,
      },
    ];
    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrangeAlbum({ data: obj }));
    }
    dispatch(
      rearrange({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const loadMore = () => {
    if (AlbumDetails?.resultData?.data?.[albumId]?.current_page < AlbumDetails?.resultData?.data?.[albumId]?.last_page) {
      let obj = {
        page: AlbumDetails?.resultData?.data?.[albumId]?.current_page + 1,
        slug: selections?.sort,
        id:albumId
      };
      dispatch(loadMoreImages(obj));
    }
  };

  return {
    albumAddToSelection,
    selections,
    data: AlbumDetails?.resultData?.data?.[albumId],
    rearrangeItems,
    frames,
    loadMore
  };
};
