import Style from "./LeftFilteredCategory.module.scss";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import {
//   changeCurrentTabView,
//   currentTabSelection,
//   callFunction,
// } from "../../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../../../components/Layouts/Icons";
import { usePreference } from "../../../../Logic/usePreference";
import { useParams } from "react-router-dom";
import RightFilteredCategory from "../RightFilteredCategory";
import { useWindowSize } from "@react-hook/window-size";
import { getFavoriteImages } from "../../../../store/slices/favoriteSlice";
import { getSlideshows } from "../../../../store/slices/slideshow/slideshowSlice";
import { getGalleryImages } from "../../../../store/slices/GalleryImagesSlice";
import useLeftData from "./useLeftData";

const LeftFilteredCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabRef = useRef(null);
  const dispatch = useDispatch();
  let { albumId } = useParams();
  const [width, height] = useWindowSize();

  const { getAllImages } = useLeftData()


  // const { currentTab, currentView, selectedAlbum, sections } = useSelector(
  //   (state) => state.gallery //FIXME
  // );

  const { galleryAllTab, favorite, album, archive, slideshow, trash } = useSelector((state) => state);
  const { default_view } = useSelector((state) => state.userpreference);
  // const [floater, setFloater] = useState({
  //   floaterWidth: 22,
  //   leftValue: 0,
  // });
  const { view, setView } = usePreference();
  const [albumItemTab, setAlbumItemTab] = useState(false);
  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");
  const { t } = useTranslation(["dashboard"]);

  const handleFloater = (e, pathName, path) => {
    navigate(`${path}`);
  };



  useEffect(() => {
    if (favorite.status == 'idle') {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getFavoriteImages(obj))
    }

    if (slideshow.status == 'idle') {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getSlideshows(obj))
    }

    if (galleryAllTab.getTotalStatus == 'idle') {
      getAllImages()
    }
  }, [])




  const getActiveTabIndicator = () => {
    if (currentPath === "archive" || currentPath === "trash") {
      return { width: 0, left: 0 };
    }
    else if (currentPath == "") {
      return { width: tabRef?.current?.offsetWidth, left: 0 };
    } else if (currentPath == "trash" || currentPath == "archive" || albumId) {
      return { width: 0, left: 0 };
    } else {
      return {
        width: tabRef?.current?.offsetWidth,
        left: tabRef?.current?.offsetLeft,
      };
    }
  };


  const getCount = (tab) => {
    let count = null;
    if (tab == "all") {
      count = galleryAllTab?.resultData?.total;
    } else if (tab == "favorites") {
      count = favorite?.resultData?.total;
    } else if (tab == "album") {
      count = album?.resultData?.total;
    } else if (tab == "archive") {
      count = archive?.resultData?.total;
    } else if (tab == "slideshow") {
      count = slideshow?.resultData?.total;
    } else if (tab == "trash") {
      count = trash?.resultData?.total;
    }
    return CurrentCount(count);
  };

  const CurrentCount = (count) => {
    return (
      <>
        {count != null || count != undefined ? (
          <span //FIXME count doesnot change when image updated
            className={`${Style.currentItemCount} 
          `}
          >
            {count > 0 && count.toString().length > 3 ? "999+" : count}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const isOthertabs = (path) => {
    let tab = false;
    if (path == "archive" || path == "trash") {
      tab = true;
    } else {
      tab = false;
    }
    return tab;
  };

  const handleClickGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <nav className={Style.left_filtered_category}>
        <div
          className={Style.floater}
          style={{
            width: `${getActiveTabIndicator().width}px`,
            left: `${getActiveTabIndicator().left}px`,
          }}
        />

        <div className={`${Style.left_filtered_category_list}`}>
          {albumId ? (
            <div className={Style.left_filtered_category_list}>
              <div className={Style.Album_item}>
                <button
                  className={Style.back_button}
                  onClick={() => handleClickGoBack()}
                >
                  <Icons icon={"back-arrow"} size={20} />
                </button>
                <a
                  className={Style.tab_active}
                  onClick={() => handleClickGoBack()}
                >
                  {"Album"}
                </a>
              </div>
            </div>
          ) : (
            <>
              {isOthertabs(currentPath)
                ? [
                  {
                    name: currentPath,
                    path: currentPath,
                  },
                ].map((item) => {
                  return (
                    <div key={item?.name}>
                      <a
                        className={`${currentPath == item?.path && Style.tab_active
                          }`}
                      >
                        <button
                          className={Style.back_button}
                          onClick={() => handleClickGoBack()}
                        >
                          <Icons icon={"back-arrow"} size={20} />
                        </button>
                        {"images"}
                        {getCount(item.name)}
                      </a>
                    </div>
                  );
                })
                : routeData.map((item) => {
                  return (
                    <div
                      key={item?.name}
                      ref={currentPath == item?.name || currentPath == "" && item?.name == "all" ? tabRef : null}
                      onClick={(e) => handleFloater(e, item.name, item.path)}
                      data-name={item?.name}
                    >
                      <a
                        className={`${currentPath == item?.path && Style.tab_active
                          }`}
                      >
                        {item.name == "slideshow"
                          ? "My Slideshow"
                          : item.name}
                        {getCount(item.name)}
                      </a>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </nav>
      {width >= 1280 ? (
        <>
          <RightFilteredCategory currentPath={currentPath} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

let routeData = [
  {
    name: "all",
    path: "",
  },
  {
    name: "favorites",
    path: "favorites",
  },
  {
    name: "album",
    path: "album",
  },
  {
    name: "slideshow",
    path: "slideshow",
  },
];

export default LeftFilteredCategory;
