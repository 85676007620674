import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enableImageSelection } from "../../../../../store/slices/GalleryTabSlice";
import { updateSlideDetails } from "../../../../../store/slices/SlideShowDetailSlice";

export const useSlideshowCreateModal = ({ setSlideshowCreateModal }) => {
  const dispatch = useDispatch();
  const [slideName, setSlideName] = useState("");
  const slideshowSample = {
    id: 10101,
    name: "test",
    // user_id: 145,
    music_id: null,
    effect: null,
    duration: 1,
    created_at: "2023-02-14T09:41:44.000000Z",
    updated_at: "2023-02-14T09:41:44.000000Z",
    deleted_at: null,
  };

  const handleChangeName = (e) => {
    setSlideName(e.target.value);
  };

  //on submit open album list for selecting images
  //after click add -> call api for slideshow creation

  const handleSubmit = () => {
    // localStorage.setItem("albumView", "list");
    dispatch(enableImageSelection({ tab: "addslideshow" }));
    dispatch(
      updateSlideDetails({ details: { ...slideshowSample, name: slideName } })
    );
    setSlideshowCreateModal({ popup: false });
  };

  return {
    slideName,
    setSlideName,
    handleChangeName,
    handleSubmit,
  };
};
