
import Icons from "../../../../Layouts/Icons";
import CommonModal from "../../../../Modals/commonModal";
import Style from "./AddToAlbumModal.module.scss";
import loader from "../../../../../assets/images/loader/Spinner.svg";
import SelectedImageItem from "../../SelectedImageItem";
import { useAddToAlbumModal } from "./useAddToAlbumModal";
import { memo, useEffect } from "react";


const AddToAlbumModal = ({ addToAlbum, setAddToAlbum }) => {

    const { albumName, setAlbumName, ablumData, handleAlbumCreate, handleAddToAlbum, handleCloseModal } = useAddToAlbumModal({ setAddToAlbum })

    useEffect(() => {
        if (addToAlbum.popup == false) {
            setAlbumName("")
        }

    }, [addToAlbum])


    const AlbumList = memo(({ data }) => {
        return (
            <div className={Style.listContainer}>
                {data?.map((item, index) => {
                    return (
                        <div
                            className={Style.albumList}
                            key={index}
                            onClick={() => {
                                if (!item.locked) {
                                    handleAddToAlbum(item)
                                }
                            }}
                        >
                            <SelectedImageItem data={item} />
                            {/* {selectedAlbum == index && isAlbumEditing == "loading" ? (
                            <img src={loader} className={Style.spinner} alt="" />
                        ) : (
                            ""
                        )} */}
                        </div>
                    );
                })}
            </div>
        )
    })



    const CreateAlbumContent = () => {
        return (
            <>
                <div className={Style.AlbumCreateSection}>
                    <input
                        autoFocus={true}
                        value={albumName}
                        className={`${Style.AlbumCreateSection_input} form-control`}
                        onChange={(e) => setAlbumName(e.target.value)}
                        placeholder={"Enter Here"}
                    />
                    <div className={Style.AlbumCreateSection_actions}>
                        <div className="row mt-3">
                            <div className="col-6">
                                <button
                                    disabled={albumName?.length == 0}
                                    className="btn btn-sm btn-secondary w-100"
                                    onClick={handleAlbumCreate}
                                >
                                    Submit
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-sm btn-border-grey w-100"
                                    onClick={() => handleCloseModal()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const AddFromAlbum = () => {
        return (
            <>
                <AlbumList data={ablumData?.resultData?.data} />
                <div
                    className={Style.add_to_new_album_btn_wrap}
                    onClick={() => { setAddToAlbum({ ...addToAlbum, type: 'create' }) }}
                >
                    <button className={Style.add_to_album_btn}>
                        <Icons icon={"add"} size={17} />
                    </button>
                    <span className={Style.new_album_label}>New Album</span>
                    {/* {isAlbumCreating == "loading" ? (
                        <img src={loader} className={Style.spinner} alt="" />
                    ) : (
                        ""
                    )} */}
                </div>
            </>
        )
    }

    const AddToAlbumContent = () => {
        return (
            <>
                {addToAlbum.type == 'create' ?
                    <CreateAlbumContent /> : <AddFromAlbum />
                }
            </>

        );
    }


    return (
        <CommonModal
            showModal={addToAlbum.popup}
            hide={() => handleCloseModal()}
            className={Style.add_to_album}
            title={"Add to Album"}
            content={<AddToAlbumContent />}
        />
    )
}



export default AddToAlbumModal;


