import Style from "./ImageListMasonry.module.scss";
import ImageThumb from "../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import { DashboardLoader } from "../DashboardLoader";
import GalleryBlankPage from "../BlankGallery";
// import CommonModal from "../../Modals/commonModal";
// import { useKey, useSingleKey } from "../../../utils/KeyBoardShortCuts/index";
// import SlideShowFullViewTest from "../../Slideshow/SlideShowFullViewTest";
import FullscreenPreview from "../Gallery/FullscreenPreview";
// import Icons from "../../Layouts/Icons";
// import { useSocial } from "../../../Logic/useSocial";
// import ShareModal from "../../ShareModal";
// import { useGalleryTab } from "../../../Logic/GalleryHooks/useGalleryTab.js";
// import { useGalleryModalActions } from "../../../Logic/GalleryHooks/useGalleryModalActions";
// import { useGalleryFullViewOptions } from "../../../Logic/GalleryHooks/useGalleryFullViewOptions";
// import { useDimensions } from "../../../Logic/Dimensions";
import useImageListMasonry from "./useImageListMasonry";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import { useQuickSlideShowActions } from "../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import { useFullscreenPreviewActions } from "../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import FullscreenPreviewNew from "../Gallery/FullScreenPreviewNew";
import RightClickModal from "./RightClickModal";
import { useCustomClick } from "./useCustomClick";
import { openUploadModalAction } from "../../store/slices/Gallery/fileUploadSlce";
import { useDispatch } from "react-redux";
import AddToAlbumModal from "../Gallery/GalleryFilters/RightFilteredCategory/AddToAlbumModal";
import ImageDeleteModal from "../ImageDeleteModal";
import ShareModal from "../ShareModal";
import { useSocial } from "../../Logic/useSocial";
import { useLocation } from "react-router-dom";
import QuickSlideShow1 from "../Slideshow/QuickSlideShow1";

const ImageListMasonryAlbum = ({
  Images,
  loadMore,
  NodataContent,
  options,
  moveListItem,
  status,
  albumData,
  frames,
  path,
  openFullView
}) => {
  const dispatch = useDispatch();
  const location = useLocation();


  const { observer, imgRootRef, imgItemRef, breakpointColumnsObj } =
    useImageListMasonry({ loadMore, Images });

  const {
    show,
    points,
    selectedItem,
    handleClickContextMenu,
    addToAlbum,
    setAddToAlbum,
    DeleteModal,
    setDeleteModal,
  } = useCustomClick();
  const {
    secretToken,
    openShare,
    setOpenShare,
    handleShareItemOnCustomRightClick,
  } = useSocial();

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  

  return (
    <div ref={imgRootRef} data-masonry='{"percentPosition": true }'>
      {
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {Images?.map((image, i) => {
            const lastIndex = Images?.length - 20;
            return (
              <div
                id={i}
                key={i}
                ref={lastIndex == i ? imgItemRef : null}
                onMouseMove={(e) => {}}
                onContextMenu={(e) => {
                  handleClickContextMenu(e, image);
                }}
                className={`${Style.image_container} ${
                  options?.isRearrange ? Style.shake_active : ""
                }`}
              >
                <ImageThumb
                  handleOpenImageFullView={openFullView}
                  key={image.id}
                  image={image}
                  //   showSelect={showSelect}
                  i={i}
                  moveListItem={moveListItem}
                  //   slideshow={slideshow}
                  handleDeleteAction={{}}
                  hasTray={true}
                  options={options}
                  albumData={albumData}
                  frame={albumData?.frames?.image}
                />
              </div>
            );
          })}
        </Masonry>
      }

      <ImageDeleteModal
        DeleteModal={DeleteModal}
        setDeleteModal={setDeleteModal}
      />

      {Images?.length == 0 &&
        status == "succeeded" &&
        NodataContent?.title != undefined && (
          <GalleryBlankPage
            title={NodataContent?.title}
            description={NodataContent?.description}
            buttonText={NodataContent?.buttonText}
            handleClick={() =>
              dispatch(openUploadModalAction({ data: true, path: path }))
            }
          />
        )}
      {show && currentPath == "" && (
        <RightClickModal
          points={points}
          selectedItem={selectedItem}
          setAddToAlbum={setAddToAlbum}
          DeleteModal={DeleteModal}
          setDeleteModal={setDeleteModal}
          handleOpenShare={handleShareItemOnCustomRightClick}
        />
      )}

      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="zoomView"
        secretToken={secretToken}
      />

      {status == "loading" ? <DashboardLoader data={Images} /> : ""}
    </div>
  );
};

export default ImageListMasonryAlbum;
