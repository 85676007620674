import React from "react";
import Dashboard from "../../components/Dashboard";
import CommonLayout from "../../components/Layouts/CommonLayout";
import FileUploadProvider from "../../Context/fileUploadContext";
import { Uploady } from "@rpldy/uploady";


const QA_URL = process.env.REACT_APP_APIURL_QA
const TEST_URL = process.env.REACT_APP_APIURL;
const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

let api = process.env.REACT_APP_DEV_ENV == "PRODUCTION" ? BASE_URL : process.env.REACT_APP_DEV_ENV == "DEMO" ? TEST_URL : QA_URL

const DashboardPage = ({ languageOpen, setLanguageOpen }) => {
  const token = window.localStorage.getItem("USER_ACCESS_TOKEN");

  return (
    <CommonLayout className="dashboard-page">
      <Uploady
        multiple
        method="POST"
        destination={{
          url: `${api}upload-images`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          // filesParamName: "image",
        }}
      >
        <FileUploadProvider>
          <Dashboard
            languageOpen={languageOpen}
            setLanguageOpen={setLanguageOpen}
          />
        </FileUploadProvider>
      </Uploady>
    </CommonLayout>
  );
};

export default DashboardPage;
