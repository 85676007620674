import React, { useEffect, useState } from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import { useLogin } from "../../../Logic/useLogin";
import { useRegister } from "../../../Logic/useRegister";
import CorporateFooter from "./CorporateFooter";
import CorporatePreLoginMenu from "./CorporatePreLoginMenu";
import CorporatePreLoginMobileMenu from "./CorporatePreLoginMobileMenu";
import { useDispatch, useSelector } from "react-redux";

const CorporatePreLogin = ({ children, hasFooter }) => {
  const windowDimensions = useDimensions();
  const { showRegister, setShowRegister, showSignIn, setShowSignIn } =
    useRegister();
  const { showForgotPassword } = useLogin();

  return (
    <>
      {windowDimensions.width > 1280 ? (
        <CorporatePreLoginMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      ) : (
        <CorporatePreLoginMobileMenu
          setShowSignIn={setShowSignIn}
          setShowRegister={setShowRegister}
          showSignIn={showSignIn}
          showRegister={showRegister}
          showForgotPassword={showForgotPassword}
        />
      )}
      {children}

      {hasFooter && <CorporateFooter />}
    </>
  );
};

export default CorporatePreLogin;
