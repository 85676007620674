import Style from "./ImageGallery.module.scss";
import { useEffect, useLayoutEffect } from "react";
import GalleryImage from "./ImageThumb";
import Masonry from "react-masonry-css";
import useGallery from "../../../Logic/useGallery";
import { getMusicList } from "../../../store/slices/gallerySlice";
import { DashboardLoader } from "../../DashboardLoader";
import GalleryBlankPage from "../../BlankGallery";
import CommonModal from "../../Modals/commonModal";
import { useKey, useSingleKey } from "../../../utils/KeyBoardShortCuts/index";
import SlideShowFullViewTest from "../../Slideshow/SlideShowFullViewTest";
import FullscreenPreview from "../FullscreenPreview";
import Icons from "../../Layouts/Icons";
import { useSocial } from "../../../Logic/useSocial";
import ShareModal from "../../ShareModal";
import { useGalleryTab } from "../../../Logic/GalleryHooks/useGalleryTab.js";
import { useGalleryModalActions } from "../../../Logic/GalleryHooks/useGalleryModalActions";
import { useGalleryFullViewOptions } from "../../../Logic/GalleryHooks/useGalleryFullViewOptions";
import HeartIcon from "../../../utils/SvgIcons/heartIcon";
import ImageListMasonry from "../../ImageListMasonry";

const ImageGallery = () => {


  const { Images, loadMore, NodataContent, status } = useGalleryTab();

  return (
    <ImageListMasonry
      total={Images?.resultData?.total}
      Images={Images?.resultData?.data}
      loadMore={loadMore}
      NodataContent={NodataContent}
      status={status}
      path="all"
      mode="normal"
    />
  );
};

export default ImageGallery;
