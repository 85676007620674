import React from 'react'
import Details from './Details'
import { Offcanvas } from "react-bootstrap";
import Style from "../FullScreenPreviewNew.module.scss";
import Icons from "../../../Layouts/Icons";
import { useTags } from './useTags';
import { WithContext as ReactTags } from "react-tag-input";


const KeyCodes = {
    comma: 188,
    enter: 13,
};
const delimiters = [KeyCodes.enter];

const MoreInfo = ({ open, close, data, currentIndex, showFullView, albumIndex }) => {
    const { tags, handleDelete, handleAddition } = useTags({ data, currentIndex, albumIndex })


    const RemoveComponent = ({ className, onRemove }) => {
        return (
            <button onClick={onRemove} className={className}>
                <Icons icon={"icon-close"} size={8} />
            </button>
        );
    };

    return (
        <Offcanvas
            show={open}
            onHide={close}
            className={Style.info_bar}
            placement={"end"}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>More Info</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className={`${Style.info_tags} ${Style.info_sec}`}>
                    <h4
                        className={`${Style.info_tags_title} ${Style.info_sec_title}`}
                    >
                        Edit Tags
                    </h4>
                    <div
                        className={`${Style.tag_selection} ${Style.info_sec_content}`}
                    >
                        <ReactTags
                            tags={tags}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            inputFieldPosition="bottom"
                            autocomplete
                            // editable
                            removeComponent={RemoveComponent}
                            allowDeleteFromEmptyInput={false}
                        />
                    </div>
                </div>
                <Details
                    data={data}
                    index={currentIndex}
                />
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default MoreInfo