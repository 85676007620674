
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getGalleryImages, LoadmoreImages, updateAllPageStatus, getAllImagesTotalCount } from "../../../../store/slices/GalleryImagesSlice";

const useLeftData = () => {
    const dispatch = useDispatch();
    const selections = useSelector((state) => state.galleryTab)

    const getAllImages = async () => {
        let obj = {
            page: "1",
            slug: selections?.ImageAllTabSelection?.sort,
        };
        const resultAction = await dispatch(getAllImagesTotalCount(obj));
        if (getAllImagesTotalCount.fulfilled.match(resultAction)) {
              console.log(resultAction,"hey ress...");

        }
    }


    // const loadMore = async (Images) => {
    //     if (Images?.current_page < Images?.last_page) {
    //         let obj = {
    //             page: Images?.current_page + 1,
    //             slug: selections?.ImageAllTabSelection?.sort

    //         };
    //         const resultAction = await dispatch(LoadmoreImages(obj));
    //         if (LoadmoreImages.fulfilled.match(resultAction)) {
    //             loadMore(resultAction?.payload?.data?.result)
    //         }
    //     }
    //     else if (Images?.current_page === Images?.last_page) {
    //         dispatch(updateAllPageStatus("succeded"))
    //     }
    // }

    return {
        getAllImages
    }
}
export default useLeftData