import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/authSlice";
import gallerySlice from "./slices/gallerySlice";
import HomeSlice from "./slices/HomeSlice";
import familyTreeSlice from "./slices/familyTreeSlice";
import userprofileSlice from "./slices/userprofileSlice";
import userPreferenceSlice from "./slices/preferenceSlice";
import albumSlice from "./slices/albumSlice";
import userImageEditor from "./slices/imageEditSlice";
import searchSlice from "./slices/searchSlice";
import searchResultPageSlice from "./slices/searchResultPageSlice";
import emailUpdateSlice from "./slices/emailUpdateSlice";
import phoneUpdateSlice from "./slices/PhoneUpdateSlice";
import profileinfoUpdateSlice from "./slices/profileInfoUpdateSlice";
import payment from "./slices/checkoutSlice";
import contactFormSlice from "./slices/contactFormSlice";
import fileUploadSlce from "./slices/Gallery/fileUploadSlce";
import galleryImagesSlice from "./slices/GalleryImagesSlice";
import favoriteSlice from "./slices/favoriteSlice";
import galleryTabSlice from "./slices/GalleryTabSlice";
import quickSlideShowSlice from "./slices/slideshow/QuickSliseShowSlice"
import frameSlice from "./slices/frameSlice";
import slideshowSlice from "./slices/slideshow/slideshowSlice"
import archiveSlice from "./slices/Archive/archiveSlice";
import trashSlice from "./slices/Trash/trashSlice";
import SlideShowDetailSlice from "./slices/SlideShowDetailSlice";
import profileGallerySlice from "./slices/ProfileGallery/profileGallerySlice";
import albumDetails from "./slices/Album/AlbumDetails";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginData", "registerData"],
};

const authSliceSection = persistReducer(persistConfig, authSlice);

const appReducer = combineReducers({
  gallery: gallerySlice,
  fileUpload: fileUploadSlce,
  home: HomeSlice,
  auth: authSliceSection,
  familyTree: familyTreeSlice,
  userprofile: userprofileSlice,
  userpreference: userPreferenceSlice,
  album: albumSlice,
  image_editor: userImageEditor,
  search: searchSlice,
  searchResultPage: searchResultPageSlice,
  userEmailUpdate: emailUpdateSlice,
  phoneUpdateSlice: phoneUpdateSlice,
  profileUpdateInfo: profileinfoUpdateSlice,
  phoneUpdateSlice: phoneUpdateSlice,
  payment: payment,
  contactform: contactFormSlice,
  galleryAllTab: galleryImagesSlice,
  favorite: favoriteSlice,
  galleryTab: galleryTabSlice,
  quickSlideShow: quickSlideShowSlice,
  archive: archiveSlice,
  trash: trashSlice,
  frames: frameSlice,
  slideshow: slideshowSlice,
  slideShowDetail:SlideShowDetailSlice,
  profileGallery: profileGallerySlice,
  albumDetails: albumDetails
});

// const appReducer = (state, action) => {
//   if (action.type === "auth/logout") {
//     //console.log(action.type, "hey log/////");
//     //localStorage.removeItem("USER_ACCESS_TOKEN");
//     //storage.removeItem("persist:root");
//     //state = {};
//   }
//   return rootReducers(state, action);
// };

export default appReducer;
