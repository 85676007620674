import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import Style from "./MainSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMainSlider } from "./useMainSlider";
import SlideShowControl from "../SlideShowControl";
import slideShowPlaceholder from "../../../../assets/slideShowPlaceholder.png";
import convertHMS from "../../../../utils/utilityHooks/convertHMS";

const MainSlider = ({
  slides,
  swiperRef,
  effect,
  setEffect,
  slideChangeHandler,
  slideIndex,
  setSlideIndex,
  activeThumb,
  slideData,
  setPausePlay,
  pausePlay,
  time,
  setTime,
  loop,
  setLoop,
}) => {

  return (
    <div className={Style.slideshow_display} id="slideshow">
      {slides?.length > 0 ? (
        <Swiper
          loop={false}
          initialSlide={slideIndex}
          ref={swiperRef}
          spaceBetween={10}
          // speed={slideData?.duration * 100} //Duration of transition between slides (in ms)
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          className={`${Style.slideshow_swiper} mySwiper2`}
          thumbs={{ swiper: activeThumb }}
          onSlideChange={(e) => slideChangeHandler(e)}
          allowTouchMove={false}
          // onReachEnd={(e) => handleSlideShowEnd()}
          // onInit={(e) => handleInitialization(e)}
        >
          {slides?.map((imgItem, index) => {
            console.log(slideIndex,index, "slideshow 123...");
            return (
              <SwiperSlide
                key={imgItem.id}
                // className={"slide-in"}
                className={slideIndex == index ? slideData?.effect : "non"}
              >
                <figure className={Style.slideshow_image}>
                  <img src={imgItem?.src?.original} alt="" />
                </figure>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <div className={Style.noDataSection}>
          <img src={slideShowPlaceholder} alt="" />
        </div>
      )}
      <div className={Style.slideshow_controls}>
        <span className={Style.slideshow_duration}>
          {slides?.length >= 0
            ? convertHMS(time) +
              " " +
              "/" +
              " " +
              convertHMS(slideData?.duration * slides?.length)
            : "0:00 / 0:00"}
        </span>

        <div className={Style.slideshow_options}>
          <SlideShowControl
            swiperRef={swiperRef}
            setEffect={setEffect}
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
            effect={effect}
            slideData={slideData}
            slides={slides}
            setPausePlay={setPausePlay}
            pausePlay={pausePlay}
            setTime={setTime}
            loop={loop}
            setLoop={setLoop}
          />
        </div>
        <span className={Style.slideshow_slides_count}>
          {slides?.length} slides
        </span>
      </div>
    </div>
  );
};

export default MainSlider;
