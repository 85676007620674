import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
    status: "idle"
};


export const addTags = createAsyncThunk(
    "tags/addTags",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.post(`add-tag`, params);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeTags = createAsyncThunk(
    "tags/removeTags",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.post(`remove-tag`, params);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const tagSlice = createSlice({
    name: "tags",
    initialState,
    reducers: {},

    extraReducers: {
        [addTags.pending]: (state, action) => {
            state.status = "loading";
        },
        [addTags.fulfilled]: (state, action) => {
            state.status = "succeeded";

        },
        [addTags.rejected]: (state, action) => {
            state.status = "failed";
        },
    }

})

export default tagSlice.reducer;