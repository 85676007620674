import React, { useEffect, useState } from "react";
import Style from "./CorporateHomeLiveStatus.module.scss";
import { Dropdown, Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import { useHome } from "../../Logic/useHome";
import { useTranslation } from "react-i18next";
import Countries from "../../constants/countries";

const CorporateHomeLiveStatus = () => {
  const { t } = useTranslation(["common"]);

  const { liveStatus, liveStatusData } = useHome();

  useEffect(() => {
    liveStatus();
  }, []);

  const [country, setCountry] = useState("India");
  const [flag, setFlag] = useState(Assets.flag_india);

  const countrySwitch = (countryName, countryFlag) => {
    setCountry(countryName);
    setFlag(countryFlag);
  };

  return (
    <div className={Style.home_live_status}>
      <Container>
        
        <div className={Style.home_live_status_wrap}>
          <div className={Style.home_live_status_head}>


            <div className={`${Style.dropdown_wrap} anim load-anim`}>
              <Dropdown
                className={`${Style.home_live_status_country} ${
                  !liveStatusData && "data-loading"
                }`}
              >
                {liveStatusData ? (
                  <>
                    <Dropdown.Toggle>
                      <span className={Style.flag}>
                        <img src={flag} alt="flag_image" />
                      </span>
                      {liveStatusData && liveStatusData.country}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Countries.map((countryItem, i) => {
                        return (
                          <Dropdown.Item
                            onClick={() =>
                              countrySwitch(countryItem.country, countryItem.flag)
                            }
                            key={countryItem.id}
                          >
                            {countryItem.country}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </>
                ) : (
                  <span></span>
                )}
              </Dropdown>
            </div>


            <div
              className={`${
                Style.home_live_status_title
              } ${"section_title_block title_block_white"}`}
            >
              <h2 className="anim load-anim">{t("wish_title")}</h2>
              <p className="anim load-anim">{t("wish_desc")}</p>
            </div>
          </div>
          <div className={`${Style.home_live_status_status} anim load-anim pt-3 my-auto`}>
            <div
              className={`${Style.home_live_status_box} ${!liveStatusData && "data-loading"
                }`}
            >
              <h2 className={Style.status_count}>
                {liveStatusData?.image_count ? (
                  `${liveStatusData.image_count} +`
                ) : (
                  <span></span>
                )}
              </h2>
              <p className={Style.status_name}>TOTAL PHOTOS UPLOADED</p>
            </div>
            <div
              className={`${Style.home_live_status_box} ${!liveStatusData && "data-loading"
                }`}
            >
              <h2 className={Style.status_count}>
                {liveStatusData?.user_count ? (
                  `${liveStatusData.user_count} +`
                ) : (
                  <span></span>
                )}
              </h2>
              <p className={Style.status_name}>TOTAL USERS</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CorporateHomeLiveStatus;
