import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
  updateUserImage,
} from "../store/slices/authSlice";

import {
  callFunction,
  getProfileDetails,
} from "../store/slices/userprofileSlice";
import { profilePhotoUpload } from "../store/slices/profilePhotoUploadSlice";

import UploadImageCrop from "../Logic/useUploadImageCrop";
import { updateProfilePicture } from "../store/slices/familyTreeSlice";

var imageObj;

export const useProfileUpload = (newImg, setShowProfileUploadModal) => {
  const dispatch = useDispatch();
  const [IsProfilePhotoUploading, setIsProfilePhotoUploading] = useState(false);

  const UploadInputElement = useRef(null);
  const UploadInputElementForm = useRef(null);

  const imgRef = useRef(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [openEditProfileImage, setOpenEditProfileImage] = useState(false);
  const [aspect, SetAspect] = useState(1 / 1);
  const [cropselection, SetcropSelection] = useState(false);
  const [crop_apply_btn, SetCrop_apply_btn] = useState(false);
  const [crop_window, SetCrop_window] = useState(false);
  const [imageSrc, SetImageSrc] = useState(null);
  const [responseImage, setResponseImage] = useState("");

  const [crop, setCrop] = useState({
    unit: "10%",
    minWidth: 100,
    maxHeight: 100,
    x: 125,
    y: 425,
  });

  const closeUploadModal = () => {
    SetImageSrc("");
    setOpenEditProfileImage(false);
  };

  const OpenDropDownBox = () => {
    SetImageSrc("");
    setOpenEditProfileImage(true);
  };

  const onButtonClickTriggerFileSelect = () => {
    UploadInputElement.current.click();
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const fileName = "myFile.jpg";

    if (newImg) {
      fetch(newImg).then(async (response) => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], fileName, { contentType });
        // access file here

        var callbackUrl = (url) => {
          SetImageSrc(url);
        };
        imageObj = new UploadImageCrop(file, callbackUrl);
        imageObj.setCropRerImage(imgRef);
      });
    }
    if (e?.dataTransfer?.files?.length == 1) {
      var callbackUrl = (url) => {
        SetImageSrc(url);
      };
      imageObj = new UploadImageCrop(e.dataTransfer.files[0], callbackUrl);
      imageObj.setCropRerImage(imgRef);
    }

    if (e?.target?.files?.length == 1) {
      var callbackUrl = (url) => {
        SetImageSrc(url);
      };
      imageObj = new UploadImageCrop(e?.target?.files[0], callbackUrl);
      imageObj.setCropRerImage(imgRef);
    }
  };

  const RemoveProfile = () => {
    dispatch(profilePhotoUpload({ photo: "" }))
      .unwrap()
      .then((res) => {
        removeUserProfileImageAfterUpload();
        // dispatch(updateUserImage(""));
        SetImageSrc(null);
      });
  };

  const UploadProfile = () => {
    var allowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.avif|\.heic|\.webp)$/i;

    if (!allowedExtensions.exec(UploadInputElement.current.files[0].name)) {
      //alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
      UploadInputElementForm?.current?.reset();
      return false;
    } else {
      var callbackUrl = (url) => {
        SetImageSrc(url);
      };
      imageObj = new UploadImageCrop(
        UploadInputElement.current.files[0],
        callbackUrl
      );
      imageObj.setCropRerImage(imgRef);
    }
  };

  const saveImage = (type, url) => {
    console.log(type, url,"hey file save");
    setIsProfilePhotoUploading(true);

    // imageObj.getCroppedImage();
    // imageObj.getImageURL();

    if (type !== "tree") {
      dispatch(profilePhotoUpload({ photo: url }))
        .unwrap()
        .then((res) => {
          UploadInputElementForm?.current?.reset();
          // dispatch(updateUserImage(res?.data));
          updateUserProfileImageAfterUpload(res?.data);
          setIsProfilePhotoUploading(false);
          setOpenEditProfileImage(false);
          SetImageSrc(null);
        });
    } else {
      setIsUploading(true);
      dispatch(updateProfilePicture({ img: url }))
        .unwrap()
        .then((res) => {
          setResponseImage(res.data?.result);
          setIsProfilePhotoUploading(false);
          setShowProfileUploadModal(false);
          SetImageSrc("");
        });
    }
  };

  const updateUserProfileImageAfterUpload = (ImgData) => {
    const updateProfileAfterUpload = (state) => {
      state.userdata = {
        ...state.userdata,
        profile_image: ImgData.result,
      };
    };
    dispatch(callFunction(updateProfileAfterUpload));
  };

  const removeUserProfileImageAfterUpload = (ImgData) => {
    const removeProfileAfterUpload = (state) => {
      state.userdata = {
        ...state.userdata,
        profile_image: {},
      };
    };
    dispatch(callFunction(removeProfileAfterUpload));
  };

  useEffect(() => {
    if (openEditProfileImage === false) {
      UploadInputElementForm?.current?.reset();
    }
  }, [openEditProfileImage]);

  const CancelButton = () => {
    UploadInputElement.current.files[0].value = null;
    SetImageSrc(null);
    setOpenEditProfileImage(false);
  };

  useEffect(() => {
    if (imageSrc != null) {
      setOpenEditProfileImage(true);
    }
  }, [imageSrc]);

  return {
    UploadInputElement,
    UploadInputElementForm,
    onButtonClickTriggerFileSelect,
    UploadProfile,
    IsProfilePhotoUploading,
    setIsProfilePhotoUploading,
    imgRef,
    aspect,
    SetAspect,
    cropselection,
    SetcropSelection,
    crop_apply_btn,
    SetCrop_apply_btn,
    crop_window,
    SetCrop_window,
    crop,
    setCrop,
    imageObj,
    imageSrc,
    SetImageSrc,
    openEditProfileImage,
    setOpenEditProfileImage,
    CancelButton,
    saveImage,
    OpenDropDownBox,
    handleDragOver,
    handleDragOut,
    handleDrop,
    isDraggingOver,
    setIsDraggingOver,
    closeUploadModal,
    RemoveProfile,
    responseImage,
    setResponseImage,
    isUploading,
    setIsUploading,
  };
};
