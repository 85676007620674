import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  editStatus: "idle",
  error: null,
  resultData: {}
};

export const getAlbums = createAsyncThunk(
  "album/getAlbums",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-list?page=${params.page}&&sort=${params.slug}&per_page=40`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const createAlbum = createAsyncThunk(
  "album/createAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`create-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAlbum = createAsyncThunk(
  "album/editAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`update-album`, params.data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleLockAlbumApi = createAsyncThunk(
  "gallery/handleLockAlbumApi",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`lock-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAlbum = createAsyncThunk(
  "album/removeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-album`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rearrangeAlbum = createAsyncThunk(
  "album/rearrangeAlbum",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`album-rearrange`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const LoadmoreAlbums = createAsyncThunk(
  "album/LoadmoreAlbums",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `album-list?page=${params.page}&&sort=${params.slug}&per_page=40`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// export const frameList = createAsyncThunk(
//   "album/frameList",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(`frames-list`);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const albumList = createAsyncThunk(
//   "album/albumList",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(`album-list`);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );



// export const removeAlbum = createAsyncThunk(
//   "album/removeAlbum",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(`delete-album/${params}`);

//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const albumSlice = createSlice({
  name: "album",
  initialState,
  reducers: {
    updateUnlock: (state, action) => {
      if (state?.resultData?.data) {
        let data = current(state?.resultData?.data)
        let itemIndex = data.findIndex((obj) => obj.id == action?.payload?.albumId)
        let item = { ...data.at(itemIndex), locked: action?.payload?.action == 'lock' ? true : false };
        state.resultData.data[itemIndex] = item;
      }
    },

    updateAlbumList: (state, action) => {
      let data = [...state?.resultData?.data]
      state.resultData.data = data.filter((item, index) => item?.id !== action.payload)
      state.resultData.total = state.resultData.total - 1;
    },
    updateMultipleAlbumList: (state, action) => {
      let data = [...state?.resultData?.data]
      state.resultData.data = data.filter((item, index) => !action.payload.includes(item.id))
      state.resultData.total = state.resultData.total - action.payload?.length;
    },
    updateAlbumFavorite: (state, action) => {
      if (state?.resultData?.data) {
        state?.resultData?.data?.map((item, i) => {
          if (current(item)?.images?.length > 0) {
            let index = current(item).images.findIndex((obj) => obj.id === action?.payload?.id)
            if (index != -1) {
              state.resultData.data[i].images[index].favourite = action?.payload?.favorite;
            }
          }

        })

      }
    },

    rearrange: (state, action) => {

      let draggedIndex = state?.resultData?.data.findIndex(
        (item) => item.id == action.payload.draggedItem.id
      );

      let changedIndex = state?.resultData?.data.findIndex(
        (item) => item.id == action.payload.changedItem.id)


      state.resultData.data[draggedIndex].position =
        action.payload.changedItem.position;

      state.resultData.data[changedIndex].position =
        action.payload.draggedItem.position;

      let data1 = state?.resultData?.data[changedIndex];
      let data2 = state?.resultData?.data[draggedIndex];

      state.resultData.data[draggedIndex] = data1;
      state.resultData.data[changedIndex] = data2;

    }

  },
  extraReducers: {
    [getAlbums.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAlbums.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = { ...action?.payload?.data?.result }
    },
    [getAlbums.rejected]: (state, action) => {
      state.status = "failed";
    },
    [LoadmoreAlbums.pending]: (state, action) => {
      state.statusLoadMore = "loading";
    },
    [LoadmoreAlbums.fulfilled]: (state, action) => {
      state.statusLoadMore = "succeeded";
      state.resultData = {
        ...state.resultData,
        current_page: action?.payload?.data?.result?.current_page,
        last_page: action?.payload?.data?.result?.last_page,
        total: action?.payload?.data?.result?.total,
        data: [...state.resultData?.data, ...action?.payload?.data?.result?.data],
      };
    },
    [LoadmoreAlbums.rejected]: (state, action) => {
      state.statusLoadMore = "failed";
    },
    [createAlbum.fulfilled]: (state, action) => {
      let data = [{ ...action?.payload?.data?.result, locked: false }, ...state.resultData.data];
      state.resultData.data = data;
      state.resultData.total = state.resultData.total + 1;
    },

    [editAlbum.pending]: (state, action) => {
      state.editStatus = "loading";
    },

    [editAlbum.fulfilled]: (state, action) => {
      state.editStatus = "succeeded";
      let currentImages = [...current(state.resultData.data)];
      let albumIndex = currentImages.findIndex((obj) => obj.id == action?.payload?.data?.result.id)
      if (albumIndex > -1) {
        state.resultData.data[albumIndex] = action.payload.data.result;
      }
    },

    [editAlbum.rejected]: (state, action) => {
      state.editStatus = "failed";
    },

    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state?.resultData?.data && action.meta.arg.album_id) {
        let data = [...state?.resultData?.data]
        let albumIndex = data.findIndex((obj) => obj.id == action.meta.arg.album_id)

        let imgIndex = "";

        if (action.payload.data.result.id) {
          imgIndex = current(state?.resultData?.data[albumIndex])?.images?.findIndex((obj) => obj.id === action.payload.data.result.id)
        } else {
          imgIndex = state?.resultData?.data[albumIndex].images?.findIndex((obj) => obj.id === action.payload.data.result.image_id)

        }

        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags
          state.resultData.data[albumIndex].images[imgIndex].tags = tagData
        }
      }

    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      if (state?.resultData?.data && action.meta.arg.album_id) {

        let data = [...state?.resultData?.data]
        let albumIndex = data.findIndex((obj) => obj.id == action.meta.arg.album_id)

        let imgIndex = current(state?.resultData?.data[albumIndex])?.images.findIndex((obj) => obj.id === action.payload.data.result.id)
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result.tags
          state.resultData.data[albumIndex].images[imgIndex].tags = tagData
        }
      }

    },


    ["auth/logout"]: (state, action) => {
      state.status = "idle"
      state.resultData = {}
    }

  },
});



export const {
  rearrange,
  updateUnlock,
  updateAlbumList,
  updateMultipleAlbumList,
  updateAlbumFavorite
} = albumSlice.actions;

export default albumSlice.reducer;

