import React, { useState } from "react";
import Icons from "../Layouts/Icons";
import CommonModal from "../Modals/commonModal";
import Style from "./ShareModal.module.scss";

const ShareModal = ({ openShare, setOpenShare, from, secretToken }) => {
  const [isCopied, setIsCopied] = useState(false);

  const QA_SHARE_URL = process.env.REACT_APP_SHAREAPI_LIVE_QA;
  const BASE_SHARE_URL = process.env.REACT_APP_SHAREAPI_LIVE;

  const { REACT_APP_SHAREAPI_LIVE } = process.env;

  let MainURL =
    process.env.REACT_APP_DEV_ENV == "PRODUTION"
      ? BASE_SHARE_URL
      : QA_SHARE_URL;

  let url = `${MainURL}view-content/${secretToken}`;

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <CommonModal
      className={`${Style.share_modal}`}
      style={{ zIndex: "9999999" }}
      showModal={openShare}
      hide={() => setOpenShare(!openShare)}
      content={
        <>
          <h2 className={Style.share_modal_title}>Share to...</h2>
          <ul className={Style.share_modal_options}>
            <li>
              <button className={Style.share_modal_options_button}>
                <Icons icon={"share-whatsapp"} size={16} />
                <a
                  href={`https://api.whatsapp.com/send?text=${url}&type=custom_url&app_absent=0`}
                >
                  Share to Whatsapp
                </a>
              </button>
            </li>
            {/* <li>
              <button className={Style.share_modal_options_button}>
                <Icons icon={"share-email"} size={16} />
                <div>Share via Email</div>
              </button>
            </li> */}
            <li>
              <button
                className={Style.share_modal_options_button}
                onClick={() => handleCopyClick(url)}
              >
                <Icons icon={"share-copy-link"} size={16} />
                <div>Copy Link</div>
              </button>
              {isCopied && <span className={Style.clip_to_board}>copied</span>}
            </li>
          </ul>
        </>
      }
    />
  );
};

export default ShareModal;
