import Style from "./SearchLocation.module.scss";
import { Dropdown } from "react-bootstrap";

const SearchLocation = ({
  searchLocation,
  location,
  setLocation,
  locationsData,
  setSearchLocation,
}) => {
  return (
    <Dropdown style={{ width: "100%" }}>
      <Dropdown.Toggle>
        {searchLocation == "" ? "All" : searchLocation}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className={`${Style.search_results_filter_searchbar} form-group`}>
          <input
            type="text"
            className="form-control"
            placeholder="Location"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
        </div>
        <div className="scrollDiv">
          <Dropdown.Item onClick={() => setSearchLocation("")}>
            All
          </Dropdown.Item>

          {locationsData
            .filter((ele) => {
              if (location !== "") {
                return ele.indexOf(location) != -1;
              } else {
                return true;
              }
            })
            .map((element, index, array) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => setSearchLocation(element)}
                >
                  {element}
                </Dropdown.Item>
              );
            })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchLocation;
