import Style from "./UploadImageModal.module.scss";
import useUploadImageModal from "../../../../../Logic/useUploadImageModal";
import CommonModal from "../../../../Modals/commonModal";
import Assets from "../../../../Layouts/Assets";
import { Swiper, SwiperSlide } from "swiper/react";
import Icons from "../../../../Layouts/Icons";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper";
import useImageUpload from "./useImageUpload";
import Thumb from "./Thumb";
import { FixedSizeList, areEqual } from "react-window";
import { useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import memoize from "memoize-one";
import React, { memo } from "react";

const UploadImageModal = ({
  closeUploadModalWhenDone,
  openActionModal,
  handleImageChange,
  handleDragOver,
  handleDragOut,
  handleDrop,
  openFiles,
  closeModal,
  isDraggingOver,
  imagesLength,
  images,
  duplicateImages,
  invalidImages,
  uploadIndex,
  isUploading,
  allFiles,
  newFiles
}) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const sliderRef = useRef(null);

  const [isScrollLeft, setIsScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add("active");
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove("active");
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove("active");
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
    console.log(sliderRef.current);
    setIsScrollLeft(walk);
  };

  const ListItem = ({ data, index, style }) => {
    return (
      <div
        className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}
        style={style}
      >
        {data[index]?.duplicate ? (
          <Thumb duplicate={data[index]?.name} />
        ) : data[index]?.invalid ? (
          <Thumb invalid={data[index]?.name} />
        ) : (
          <Thumb image={data[index]} />
        )}
      </div>
    );
  };

  return (
    <CommonModal
      className={Style.file_upload_modal}
      showModal={openActionModal?.isOpen}
      hide={() => closeModal()}
      content={
        <div className={Style.file_upload_modal_form}>
          <div
            className={Style.file_upload_draggable_area}
            style={{
              backgroundColor: !isDraggingOver
                ? ""
                : "var(--file-upload-hover-bg)",
            }}
            // style={{ backgroundColor: !isDraggingOver ? "#fafffb" : "#eaf8ea" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragOut}
          >
            <figure className={Style.file_upload_image}>
              <img src={Assets.file_upload_image} alt="file_upload_image" />
            </figure>
            <h4 className={Style.file_upload_info}>
              Drag and drop photos, or{" "}
              <label
                htmlFor="file_upload_input"
                className={Style.file_upload_input_label}
              >
                Select/Browse
              </label>
              &nbsp;photos
            </h4>
            <p className={Style.file_upload_info_desc}>
              Upload your photos here to bring your stories to life.
            </p>
            <input
              onChange={handleImageChange}
              className={Style.file_upload_input}
              id="file_upload_input"
              multiple
              type="file"
            ></input>
          </div>
          <div className={Style.file_upload_preview}>
            <>
              <div className={`${Style.file_upload_status} me-3 ms-0`}>
                <div
                  className={`${Style.add_more_btn_contents}  ${
                    uploadIndex > 0 && "inprogress"
                  } `}
                >
                  <strong className="mb-sm-1">File Upload</strong>

                  <span>
                    {uploadIndex}/{allFiles?.length}
                  </span>

                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    variant="danger"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        <span>
                          Total - <strong>{allFiles?.length}</strong>
                        </span>
                        <span>
                          Uploaded - <strong>{uploadIndex}</strong>
                        </span>
                        <span>
                          Duplicate - <strong>{duplicateImages?.length}</strong>
                        </span>
                        <span>
                          Invalid - <strong>{invalidImages?.length}</strong>
                        </span>
                      </Tooltip>
                    }
                  >
                    <button
                      className={`btn btn-link ${Style.upload_image_info_btn}`}
                    >
                      <Icons
                        className="d-inline-block pt-1"
                        icon={"info"}
                        color={"#ffad50"}
                        size={18}
                      />
                    </button>
                  </OverlayTrigger>

                  {/* <span className="mb-sm-1">5/100</span>
                  <strong className="mb-0">File Upload</strong> */}

                  {/* <span className={Style.usage_limit_exeeded}>Usage Limit Exceeded</span> */}
                </div>
              </div>

              <div
                className={Style.file_upload_list_wrap_inneritem}
                ref={sliderRef}
                // onMouseDown={handleMouseDown}
                // onMouseLeave={handleMouseLeave}
                // onMouseUp={handleMouseUp}
                // onMouseMove={handleMouseMove}
              >
                {/* 
                <div
                  className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}

                >

                  <Thumb image={data[index]} />

                </div> */}

                <FixedSizeList
                  itemData={[...images.slice(uploadIndex-10>0?uploadIndex-10:0,uploadIndex).reverse(), ...duplicateImages, ...invalidImages]}
                  height={70}
                  itemCount={[...images.slice(uploadIndex-10>0?uploadIndex-10:0,uploadIndex), ...duplicateImages, ...invalidImages]
                    .length}
                  itemSize={124}
                  width={400}
                  direction="horizontal"
                  style={{ flex: "1 0", width: "100%" }}
                  className={Style.file_upload_list_wrap}
                >
                  {ListItem}
                </FixedSizeList>

                {/* {imgObj?.length == 0 && (
                  <>
                    <div
                      className={`${Style.file_upload_img_wrap}`}
                    >
                      {[...Array(4)].map((_, i) => (
                        <div
                          key={i}
                          className={`${Style.file_upload_preview_wrap} ${Style.blank_image}`}
                        >
                          <figure
                            className={Style.file_upload_preview_image}
                          ></figure>
                        </div>
                      ))}
                    </div>
                  </>
                )} */}
              </div>

              <button className={Style.add_more_btn} onClick={openFiles}>
                <div className={Style.add_more_btn_contents}>
                  <Icons icon={"add"} size={12} />
                  <span>Add more</span>
                </div>
              </button>
            </>
          </div>

          <div className={Style.file_upload_btn_wrap}>
            <button
              disabled={
                allFiles?.length > 0 && uploadIndex == allFiles?.length
                  ? false
                  : true
              }
              className="btn btn-secondary"
              onClick={() => closeUploadModalWhenDone()}
            >
              Done
            </button>
          </div>

          {/* <div>
            {errorMessage && (
              <p className={Style.errorMessage}>{errorMessage}</p>
            )}
          </div> */}
        </div>
      }
    />
  );
};

export default UploadImageModal;

const items = new Array(5000).fill().map((_, index) => `Item ${index}`);

// style for failed items
// ${failedImageIndexs?.includes(i) && Style.uploadfail}

let itemData = [
  {
    id: "batch-1.item-1",
    batchId: "batch-1",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: {
      name: "pexels-el-jusuf-14851859.jpg",
      lastModified: 1677958234903,
    },
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223719yQcsd.jpg",
  },
  {
    id: "batch-2.item-2",
    batchId: "batch-2",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677893957891, name: "pexels-ekateri…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223721hSQQF.jpg",
  },
  {
    id: "batch-3.item-3",
    batchId: "batch-3",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677883711178, name: "pexels-dids-37…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223722E1EVf.jpg",
  },
  {
    id: "batch-4.item-4",
    batchId: "batch-4",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677885817184, name: "pexels-deepu-b…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223723PxOpg.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  "pexels-churckyno-bryant-1546035.jpg",
  "pexels-cup-of-couple-7303979.jpg",
  "pexels-curioso-photography-288100.jpg",
  "pexels-anni-roenkae-2693208.jpg",
  "pexels-anni-roenkae-2693212.jpg",
  "pexels-artūras-kokorevas-14795846.jpg",
];
