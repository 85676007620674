import React from 'react';

const CancellationAndRefundPolicy = () => {

  return (
    <>
      <div className='faq-page inner-page'>
        <div className='container'>
          <h1 className='policies-title'>Cancellation & Refund Policy</h1>
          <div className="policies-date">Last updated on: 4th January 2023</div>
          <div className='admin-content-area'>
            <p>
            The terms hereof shall constitute Hamphotos Private Limited’s (hereinafter referred as ‘Company’) cancellation and refund policy, related to the solutions rendered on the web portal <a href="https://FamilyPhotosAlbum.com">FamilyPhotosAlbum.com</a>
            </p>
            <h2>A. Cancellation and Refunds by the Company</h2>
            <p>
            There may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason, without any claims or liability to pay finance charges or interest on the amount. Some situations that may result in your order being cancelled include but are not limited to inaccuracies or errors in Solutions or pricing information, technical or technological problems or problems identified in relation to credit / debit fraud. We may also require some additional verifications or information before accepting any order. We will contact you if your order is cancelled or if additional information is required to accept your order. If your order is cancelled by the Company after your credit / debit card has been charged, the said amount will be refunded to the respective credit / debit card account. 
            </p>
            <h2>B. Cancellation by you</h2>
            <p>
            You agree and acknowledge that you are subscribing to the solutions offered through the web portal FamilyPhotosAlbum.com, after going through and accepting the Guidelines, Terms of Service and also watching the demonstration videos. Still, if you decide to cancel the subscription, this can be done within 14 (fourteen) calendar days from the date of making the subscription payment. 
            </p>
            <p>
            Even if you have uploaded a few photographs or attempted to make your family tree, you will be refunded with the entire amount after deducting any bank charges and payment gateway commissions that might be applicable. In effect, we are offering you a trial period of 14 days. <u>Beyond 14 days, the request for cancelation with a refund shall not be entertained by the Company</u>.
            </p>
            <p>
            In addition to the use of cancel button, we request you to send us the cancellation request by e mail to <a href="mailto: info@familyphotosalbum.com.">info@familyphotosalbum.com.</a>
            </p>
            <p>This is to ensure our full attention to the matter.</p>
            <i>FPA/CRF Version 1.05 dated 4th January 2023</i>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancellationAndRefundPolicy;