import React from "react";
import { Navigate } from "react-router-dom";
import useAuthChecker from "../../../Logic/useAuthChecker";
import CorporateMenu from "../CorporateMenu";

const CommonLayout = ({ children, className }) => {
  const { isAuthCompleted } = useAuthChecker();

  return isAuthCompleted ? (
    <div className={className}>
      <CorporateMenu />
      {children}
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default CommonLayout;
