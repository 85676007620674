import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../Logic/Dimensions";
import Assets from "../components/Layouts/Assets";
import { Link, useParams } from "react-router-dom";

import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  logOut,
} from "../store/slices/authSlice";

import { triggerSearchAPI } from "../store/slices/searchSlice";
import { debounce } from "../utils/utilityHooks/debounce";

var loadCount = 1;

export const useSearch = () => {
  const windowDimensions = useDimensions();
  const [isSuggestOpen, setIsSuggestOpen] = useState(false);
  const [isSerachOnFocus, setIsSerachOnFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isRecentSearch, setIsRecentSearch] = useState(true);
  const [customSearchData, setCustomSearchData] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [searchCategory, setSearchCategory] = useState("Photos");
  const search = useSelector((state) => state.search);
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { query, option } = useParams();

  const handleSearchOpen = (e) => {
    let val = e.target.value;
    console.log(val, "qry1..");

    if (val != " ") {
      setSearchQuery(val);
    }
  };


  const getOptionLabel = function (option) {
    if (option != "") {
      option = option?.toLowerCase();
      switch (option) {
        case "photos":
          return `Image`;
          break;
        case "albums":
          return `Album`;
          break;
        default:
          return "";
      }
    }
  };

  const getOptionLabelReverse = function (option) {
    if (option != "") {
      option = option?.toLowerCase();
      switch (option) {
        case "photos":
          return `Photos`;
          break;
        case "albums":
          return `Albums`;
          break;
        default:
          return "";
      }
    }
  };

  const useDebounce = function (value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  };

  const debouncedSearchTerm = useDebounce(searchQuery, 700);

  useEffect(() => {
    if (
      (debouncedSearchTerm != " " &&
        search.searchQuery !== debouncedSearchTerm) ||
      search.searchQuery !== option
    ) {
      if (debouncedSearchTerm != "") {
        let apicall = callSearchAPI(debouncedSearchTerm);

        apicall.unwrap().then((res) => {
          const { status } = res?.data;
          if (status && status !== undefined) {
            if (
              window.location.pathname.indexOf("/dashboard/searchresult") !== -1
            ) {
              if (loadCount == 1) {
                loadCount++;
              } else {
                setIsSuggestOpen(true);
                loadCount++;
              }
            } else {
              loadCount = 1;
              setIsSuggestOpen(true);
            }
          }
        });
      }
    } else if (
      search.searchQuery === debouncedSearchTerm &&
      searchInput.current === document.activeElement &&
      debouncedSearchTerm != ""
    ) {
      if (loadCount !== 1) {
        setIsSuggestOpen(true);
      }
    }
    {
      if (
        searchInput === document.activeElement &&
        loadCount !== 1 &&
        debouncedSearchTerm != ""
      ) {
        setIsSuggestOpen(true);
      }
    }
  }, [debouncedSearchTerm, searchOption]);

  useEffect(
    function () {
      searchInput.current.focus();
    },
    [searchOption]
  );

  useEffect(function () {}, [loadCount]);

  useEffect(
    function () {
      if (query !== "" && query !== undefined && query !== null) {
        setSearchQuery(query);
      }

      if (option !== "" && option !== undefined && option !== null) {
        setSearchOption(option);
        setSearchCategory(getOptionLabelReverse(option));
      }
    },
    [query, option]
  );

  const callSearchAPI = (query) => {
    return dispatch(
      triggerSearchAPI({
        query: query,
        option: searchOption.toLowerCase(),
        per_page: windowDimensions.width < 576 ? 10 : 5,
      })
    );
  };

  const capitalizeFirstLetter = function (str) {
    // converting first letter to uppercase
    if (str != "" && str != undefined && str != null) {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
    } else {
      return;
    }
  };

  const _handleKeyDown = function (e) {
    if (e.key === "Enter" && searchQuery.trim().toLowerCase() !== "") {
      if (window.location.pathname.indexOf("/dashboard/searchresult") !== -1) {
        loadCount = 1;
      }
      let query = searchQuery.split("/").join("-");
      console.log(
        searchQuery,
        searchQuery.split("/").join("-"),
        "search chewck...."
      );
      navigate(
        `/dashboard/searchresult/${query.trim()}/${searchCategory.toLowerCase()}`
      );
      setTimeout(() => {
        setIsSuggestOpen(false);
      }, 100);
    }
  };

  return {
    capitalizeFirstLetter,
    debouncedSearchTerm,
    callSearchAPI,
    handleSearchOpen,
    isSuggestOpen,
    setIsSuggestOpen,
    searchQuery,
    setSearchQuery,
    searchOption,
    setSearchOption,
    isRecentSearch,
    setIsRecentSearch,
    customSearchData,
    setCustomSearchData,
    getOptionLabel,
    searchCategory,
    setSearchCategory,
    isSerachOnFocus,
    setIsSerachOnFocus,
    loadCount,
    searchInput,
    _handleKeyDown,
  };
};
