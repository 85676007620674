import React, { useEffect, useState } from "react";
import Style from "./FileUploadMinimizedBox.module.scss";
import Icons from "../Layouts/Icons";
import { useDispatch } from "react-redux";
import { openMiniUploadBox } from "../../store/slices/Gallery/fileUploadSlce";
import { useRef } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { useFileUploadMini } from "./useFileUploadMini";
import ImageThumb from "./ImageThumb";
import { FixedSizeList } from "react-window";

const Progress = ({ strokeWidth, percentage }) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "#23CC2B",
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg
      className={"CircularProgressbar"}
      viewBox="0 0 100 100"
      width={23}
      height={23}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{
          stroke: "#D7DAE4",
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />
    </svg>
  );
};

const FileUploadMinimizedBox = ({
  images,
  duplicateImages,
  invalidImages,
  uploadIndex,
  allFiles,
  close,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();

  const nameRef = useRef();
  const windowDimensions = useDimensions();
  const [charLength, setCharLength] = useState("");

  useEffect(() => {
    setCharLength(nameRef?.current?.offsetWidth / 6);
  }, [windowDimensions.width]);

  // const handleClickCloseModal = () => {
  //   dispatch(openMiniUploadBox(false));
  // };

  const DuplicateIcon = () => {
    return (
      <div className={Style.duplicatedIcon}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          color="#fff"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 5H7V3H21V17H19V5Z" fill="currentColor" />
          <path
            d="M9 13V11H11V13H13V15H11V17H9V15H7V13H9Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 7H17V21H3V7ZM5 9H15V19H5V9Z"
            fill="currentColor"
          />
        </svg>
      </div>
    );
  };

  function ListItem({ data, index, style }) {
    return (
      <div className={Style.fu_body_list} style={style}>
        {data[index]?.duplicate ? (
          <ImageThumb
            image={data?.[index]}
            name={data?.[index]?.name}
            isDupicate
          />
        ) : data[index]?.invalid ? (
          <ImageThumb name={data?.[index]?.name} />
        ) : (
          <ImageThumb
            image={data[index]}
            name={data?.[index]?.file?.name}
            isImageSuccess
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${Style.file_upload_minimized} ${
        isCollapsed && Style.file_upload_collapsed
      }`}
    >
      <div className={Style.file_upload_minimized_head}>
        <h5 className={Style.fu_head_title}>
          {allFiles?.length > 0 && allFiles?.length == uploadIndex
            ? `Uploaded ${allFiles?.length} Photos`
            : `Uploading ${allFiles?.length - uploadIndex} Photos`}
        </h5>
        <div className={Style.fu_head_actions}>
          <button
            className={Style.collapse_upload}
            onClick={() =>
              isCollapsed ? setIsCollapsed(false) : setIsCollapsed(true)
            }
          >
            <Icons icon={"next-arrow"} size={14} />
          </button>
          <button className={Style.cancel_upload} onClick={() => close()}>
            <Icons icon={"icon-close"} size={10} />
          </button>
        </div>
      </div>
      <div className={Style.file_upload_minimized_body}>
        <FixedSizeList
          itemData={[...images, ...duplicateImages, ...invalidImages]}
          height={isCollapsed ? 0 : 270}
          itemCount={[...images, ...duplicateImages, ...invalidImages].length}
          itemSize={70}
          width={400}
          direction="vertical"
          style={{ flex: "1 0", width: "100%" }}
        >
          {ListItem}
        </FixedSizeList>

        {/* <ul className={Style.fu_body_list}>
          {images.map((item, index) => {
            return (
              <ImageThumb image={item} name={item?.file?.name} isImageSuccess />

            );
          })}
          {duplicateImages?.map((item, index) => {
            return (
              <ImageThumb image={item} name={item} isDupicate />
            );
          })}
          {invalidImages?.map((item, index) => {
            return (
              <ImageThumb image={item} />
            );
          })}
        </ul> */}
      </div>
    </div>
  );
};

export default FileUploadMinimizedBox;

let itemData = [
  {
    id: "batch-1.item-1",
    batchId: "batch-1",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: {
      name: "pexels-el-jusuf-14851859.jpg",
      lastModified: 1677958234903,
    },
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223719yQcsd.jpg",
  },
  {
    id: "batch-2.item-2",
    batchId: "batch-2",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677893957891, name: "pexels-ekateri…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223721hSQQF.jpg",
  },
  {
    id: "batch-3.item-3",
    batchId: "batch-3",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677883711178, name: "pexels-dids-37…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223722E1EVf.jpg",
  },
  {
    id: "batch-4.item-4",
    batchId: "batch-4",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677885817184, name: "pexels-deepu-b…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223723PxOpg.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  {
    id: "batch-5.item-5",
    batchId: "batch-5",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677877342459, name: "pexels-dastan-…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223725WpZfN.jpg",
  },
  {
    id: "batch-6.item-6",
    batchId: "batch-6",
    state: "added",
    uploadStatus: 0,
    completed: 0,
    loaded: 0,
    previousBatch: null,
    file: '{lastModified: 1677884385646, name: "pexels-broderi…}',
    thumbnail:
      "https://api-familyphotos.webc.in/storage/thumbnail/thumbnail20230307223726d6jw4.jpg",
  },
  "pexels-churckyno-bryant-1546035.jpg",
  "pexels-cup-of-couple-7303979.jpg",
  "pexels-curioso-photography-288100.jpg",
  "pexels-anni-roenkae-2693208.jpg",
  "pexels-anni-roenkae-2693212.jpg",
  "pexels-artūras-kokorevas-14795846.jpg",
];
