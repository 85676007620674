import DarkThemeToggler from "../../components/DarkThemeToggler";
import ChoosePlan from "../../components/ChoosePlan";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";
import CorporatePreLogin from "../../components/Layouts/CorporatePreLogin";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CheckoutForm from "../../components/Payment/CheckoutForm";
import CardSection from "../../components/Payment/CardSection";
import CommonModal from "../../components/Modals/commonModal";
import Styles from "./choosePlans.module.scss";
import CheckOutComponent from "../../components/Payment/CheckOutComponent";
import { useSelector } from "react-redux";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import { useHome } from "../../Logic/useHome";

const ChoosePlanPage = () => {
  const { theme, themeToggler } = useDarkmode();
  const location = useLocation();

  useEffect(() => {
    location.pathname === "/choose-plan" &&
      document.body.classList.add("small-header");
    return () => {
      document.body.classList.remove("small-header");
    };
  }, [location.pathname]);

  const { data, isOpen, } = useSelector((state) => state.payment);
  const { plans, plansData } = useHome();

  const { closePaymentModal } = usePlans();


  return (
    <>
      <CorporatePreLogin hasFooter={false}>
        <ChoosePlan />
        <></>
      </CorporatePreLogin>
      <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
      <CommonModal
        showModal={isOpen}
        hide={closePaymentModal}
        className={Styles.payment_modal}
        content={<CheckOutComponent />}
      />

    </>
  );
};

export default ChoosePlanPage;
