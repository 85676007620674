import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DarkThemeToggler from "../../components/DarkThemeToggler";
import GetStarted from "../../components/GetStarted";
import CorporatePreLogin from "../../components/Layouts/CorporatePreLogin";
import useDarkmode from "../../utils/utilityHooks/useDarkmode";

const GetStartedPage = () => {
  const { theme, themeToggler } = useDarkmode();
  const location = useLocation();

  useEffect(() => {
    location.pathname === "/get-started" && document.body.classList.add("small-header");
    return () => {
      document.body.classList.remove("small-header");
    };
  }, [location.pathname]);

  return (
    <>
      <CorporatePreLogin hasFooter={false}>
        <GetStarted />
      </CorporatePreLogin>
      <DarkThemeToggler theme={theme} toggleTheme={themeToggler} />
    </>
  );
};

export default GetStartedPage;
