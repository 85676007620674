import React from "react";
import { useSelector } from "react-redux";
import Style from "./FamilyUsersPrice.module.scss";
import moment from "moment";

const FamilyUsersPrice = () => {
  const { userdata } = useSelector((state) => state.userprofile);
  return (
    <div className={`${Style.family_users} amily-users-ad`}>
      <div className={Style.family_users_title}><span>{userdata?.mail}</span></div>

      <p className={Style.family_users_expiry}>
        Usage {userdata?.percentage ? userdata?.percentage : 0}%
      </p>
      <p className={Style.family_users_expiry}>
        Expires on{" "}
        {moment(userdata?.activated_plan?.[0]?.expires_at).format(
          "DD MMM YYYY"
        )}
      </p>
    </div>
  );
};

export default FamilyUsersPrice;
