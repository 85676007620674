import React, { useCallback } from "react";
import Icons from "../../Layouts/Icons";
import Style from "./StaredImages.module.scss";
import Masonry from "react-masonry-css";
import AlbumImageContainer from "../../Gallery/ImageGallery/AlbumImageContainer";
import GalleryImage from "../../Gallery/ImageGallery/ImageThumb";
import { useRef } from "react";
import useGallery from "../../../Logic/useGallery";
import { useEffect } from "react";
import Favorites from "../../Favorites";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { currentDashboardPage } from "../../../store/slices/gallerySlice";
import StaredFavorite from "../../StaredFavorite";
import StaredFavImageContainer from "../../Gallery/ImageGallery/StaredFavImageContainer";
import StarredGalleryGrid from "../../StarredGalleryGrid";
import { useStared } from "./useStared";
import StaredFilter from "./StaredFilter";

const breakpointColumnsObj = {
  default: 4,
  1599: 3,
  991: 3,
  575: 2,
  374: 1,
};

const StaredImages = ({ openStarredImageModal, setOpenStarredImageModal }) => {
  const imgRootRef = useRef(null);
  const dispatch = useDispatch();

  const {
    selections,
    profileGallery,
    openFavSection,
    isOpenStared,
    closeFavSection,
  } = useStared();

  const Right = () => {
    return (
      <div className={Style.right_filtered_category}>
        <>
          <button
            onClick={() => setOpenStarredImageModal(!openStarredImageModal)}
            className={`${Style.add_btn} ${
              Style.dashboard_btn
            } ${"btn btn-border-secondary"}`}
          >
            Close
          </button>
        </>
      </div>
    );
  };

  const Left = () => {
    return (
      <div className={Style.right_filtered_category}>
        *Add a maximum of 50 photos as your favourite photos to showcase in your
        profile.
      </div>
    );
  };

  return (
    <div>
      <div className={Style.starredImage_wrap}>
        <div
          className={`${Style.gallery_filters} ${
            selections?.ImageProfileTab?.openStared ? Style.favourites_view : ""
          }`}
        >
          {selections?.ImageProfileTab?.openStared ? (
            <StaredFilter closeFavSection={closeFavSection} />
          ) : (
            <>
              <Left />
              <Right />
            </>
          )}
        </div>
        {selections?.ImageProfileTab?.openStared ? (
          <StaredFavorite />
        ) : (
          <div
            ref={imgRootRef}
            className={Style.ImageGallery}
            data-masonry='{"percentPosition": true }'
          >
            <StarredGalleryGrid
              data={profileGallery?.resultData?.data}
              openFavSection={openFavSection}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaredImages;
