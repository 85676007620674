import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  ImageAllTabSelection: {
    selction: false,
    selectedImage: [],
    sort: "",
  },
  ImageFavoriteTab: {
    selction: false,
    selectedImage: [],
    sort: "",
  },
  ImageAlbumTab: {
    grid: false,
    sort: "",
    selction: false,
    selectedImage: [],
    isRearrange: false,
  },
  ImageSlideshowTab: {
    selction: false,
    albumImageSelection: false,
    selectedAlbumImage: [],
    slideShowName: "",
    selectedImage: [],
    sort: "",
  },
  ImageArchiveTab: {
    selction: false,
    selectedImage: [],
    sort: "",
  },
  ImageTrashTab: {
    selction: false,
    selectedImage: [],
    sort: "",
  },
  AddSlideShowSelection: {
    selction: false,
    selectedImage: [],
    selectedImageDetail: [],
    emptySlide: false,
  },
  ImageProfileTab: {
    openStared: false,
    selction: false,
    selectedImage: [],
    sort: "",
  },
  ImageAlbumDetailsTab: {
    currentAlbumId: "",
    sort: "",
    selction: false,
    isRearrange: false,
    selectedImage: [],
  },
  ImageAlbumAddToTab: {
    selction: false,
    selectedImage: [],
  },
};

// export const getGalleryImages = createAsyncThunk(
//   "gallery/getGalleryImages",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance(); //FIXME (use this function if needed)
//     try {
//       const response = await api.get(
//         `${params.url}?page=${params.page}&&sort=${params.slug}&per_page=40`
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const getStateVariable = (tab, state) => {
  let selectedVar = [];
  if (tab == "") {
    selectedVar = state?.ImageAllTabSelection;
  } else if (tab == "slideshow") {
    selectedVar = state?.ImageSlideshowTab;
  } else if (tab == "archive") {
    selectedVar = state?.ImageArchiveTab;
  } else if (tab == "trash") {
    selectedVar = state.ImageTrashTab;
  } else if (tab == "album") {
    selectedVar = state.ImageAlbumTab;
  } else if (tab == "addslideshow") {
    selectedVar = state.AddSlideShowSelection;
  } else if (tab == "favorites") {
    selectedVar = state.ImageFavoriteTab;
  } else if (tab == "user-profile") {
    selectedVar = state.ImageProfileTab;
  } else if (tab == `album${state.ImageAlbumDetailsTab.currentAlbumId}`) {
    selectedVar = state.ImageAlbumDetailsTab;
  } else if (tab == "addToAlbumImage") {
    selectedVar = state.ImageAlbumAddToTab;
  }
  return selectedVar;
};

const galleryTabSlice = createSlice({
  name: "GalleryTab",
  initialState,
  reducers: {
    enableImageSelection: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.selction = true;
      selectedArray.selectedImage = [];
    },
    disableImageSelection: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.selction = false;
      selectedArray.selectedImage = [];
    },
    SelectedImage: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);

      if (!selectedArray?.selectedImage.includes(action.payload.imageId)) {
        selectedArray.selectedImage = [
          ...selectedArray?.selectedImage,
          action.payload.imageId,
        ];
      } else {
        let selected = [...selectedArray?.selectedImage];
        let objWithIdIndex = selected.indexOf(action?.payload?.imageId);
        if (objWithIdIndex > -1) {
          selected.splice(objWithIdIndex, 1);
          selectedArray.selectedImage = selected;
        }
      }
    },
    SelectMultImage: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.selectedImage = [...action.payload.ImageIds];
    },
    DeSelectImage: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.selectedImage = [];
      selectedArray.selectedImageDetail = [];
    },
    currentSortOption: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.sort = action.payload.sortOption;
    },
    swtichGridOption: (state, action) => {
      state.ImageAlbumTab.grid = action.payload;
    },
    enableAlbumImageSelection: (state, action) => {
      if (action.payload.tab == "addslideshow") {
        if (
          !state.AddSlideShowSelection?.selectedImage.includes(
            action.payload.image.id
          )
        ) {
          state.AddSlideShowSelection.selectedImage = [
            ...state.AddSlideShowSelection?.selectedImage,
            action.payload.image.id,
          ];

          let objSlideIndex =
            state.AddSlideShowSelection.selectedImageDetail.findIndex(
              (obj) => obj.id == action?.payload?.image.id
            );

          if (objSlideIndex > -1) {
            state.AddSlideShowSelection.selectedImageDetail = [
              action.payload.image,
            ];
          } else {
            state.AddSlideShowSelection.selectedImageDetail = [
              ...state.AddSlideShowSelection.selectedImageDetail,
              action.payload.image,
            ];
          }
        } else {
          let selected = [...state.AddSlideShowSelection?.selectedImage];
          let selectedImg = [
            ...state.AddSlideShowSelection?.selectedImageDetail,
          ];
          let objWithIdIndex = selected.indexOf(action?.payload?.image.id);
          if (objWithIdIndex > -1) {
            selected.splice(objWithIdIndex, 1);
            state.AddSlideShowSelection.selectedImage = selected;
          }
          let objWithIdIndex2 = selectedImg.findIndex(
            (obj) => obj.id == action?.payload?.image.id
          );
          if (objWithIdIndex2 > -1) {
            selectedImg.splice(objWithIdIndex2, 1);
            state.AddSlideShowSelection.selectedImageDetail = selectedImg;
          }
        }
      }
    },

    disableAlbumImageSelection: (state, action) => {
      if (action.payload.tab == "slideshow") {
        state.ImageSlideshowTab.albumImageSelection = false;
        state.ImageSlideshowTab.slideShowName = "";
      }
    },

    openAlbumRearrange: (state, action) => {
      state.ImageAlbumTab.isAlbumRearrange = true;
    },

    closeAlbumRearrange: (state, action) => {
      state.ImageAlbumTab.isAlbumRearrange = false;
    },

    openRearrange: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.isRearrange = true;
    },
    closeRearrange: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.isRearrange = false;
    },

    selectMultAlumSelection: (state, action) => {
      if (action.payload.tab == "addslideshow") {
        if (action.payload.selectedImage && action.payload.image) {
          state.AddSlideShowSelection.selction = true;
          state.AddSlideShowSelection.selectedImage = [
            ...action.payload.selectedImage,
          ];

          state.AddSlideShowSelection.selectedImageDetail = [
            ...action.payload.image,
          ];
        }
        state.AddSlideShowSelection.emptySlide = true;
      }
    },
    resetAlbumSelection: (state, action) => {
      if (action.payload.tab == "addslideshow") {
        state.AddSlideShowSelection.selction = false;
        state.AddSlideShowSelection.selectedImage = [];
        state.AddSlideShowSelection.selectedImageDetail = [];
      }
    },
    currentAlbumId: (state, action) => {
      state.ImageAlbumDetailsTab.currentAlbumId = action.payload;
    },

    SelectMultAddToAlbumImage: (state, action) => {
      state.ImageAlbumAddToTab.selectedImage = [...action.payload.ImageIds];
    },
    DeSelectAddToAlbumImage: (state, action) => {
      state.ImageAlbumAddToTab.selectedImage = [];
    },

    clearImageAlbumState: (state, action) => {
      state.ImageAlbumDetailsTab.selectedImage = [];
    },

    endTabSession: (state, action) => {
      let selectedArray = getStateVariable(action.payload.tab, state);
      selectedArray.sort = "";
      selectedArray.selction = false;
      selectedArray.selectedImage = [];
    },

    clearTabState: (state, action) => {
      state.ImageAlbumDetailsTab = {
        currentAlbumId: "",
        sort: "",
        selction: false,
        isRearrange: false,
        selectedImage: [],
      };

      state.ImageAlbumAddToTab = {
        selction: false,
        selectedImage: [],
      };
    },


    callFunction: (state, action) => {
      action.payload(state, action);
    },
  },
  extraReducers: {
    // [frameList.pending]: (state, action) => {
    //   state.frameListStatus = "loading";
    //   state.message = null;
    // },
    // [frameList.fulfilled]: (state, action) => {
    //   state.frameListStatus = "succeeded";
    //   state.frames = action.payload.data.result;
    // },
    // [frameList.rejected]: (state, action) => {
    //   state.frameListStatus = "failed";
    // },
    ["archive/addtoArchive/fulfilled"]: (state, action) => {
      state.ImageAllTabSelection.selction = false;
      state.ImageAllTabSelection.selectedImage = [];
    },
    ["archive/removeArchive/fulfilled"]: (state, action) => {
      state.ImageArchiveTab.selction = false;
      state.ImageArchiveTab.selectedImage = [];
    },
    ["album/removeAlbum/fulfilled"]: (state, action) => {
      state.ImageAlbumTab.selction = false;
      state.ImageAlbumTab.selectedImage = [];
    },

    ["trash/removeTrash/fulfilled"]: (state, action) => {
      state.ImageTrashTab.selction = false;
      state.ImageTrashTab.selectedImage = [];
    },

    ["profileGallery/addToStared/fulfilled"]: (state, action) => {
      state.ImageFavoriteTab.selction = false;
      state.ImageFavoriteTab.selectedImage = [];
      state.ImageProfileTab.openStared = false;
      state.ImageProfileTab.selectedImage = [];
    },

    ["albumDetails/removeAlbumImagesFromState"]: (state, action) => {
      state.ImageAlbumDetailsTab.selction = false;
      state.ImageAlbumDetailsTab.selectedImage = [];
    },

    ["albumDetails/imageAddToAlbum/fulfilled"]: (state, action) => {
      state.ImageAlbumAddToTab.selction = false;
      state.ImageAlbumAddToTab.selectedImage = [];
    },

    ["slideshow/deleteSlideShowItems/fulfilled"]: (state, action) => {
      state.ImageSlideshowTab.selction = false;
      state.ImageSlideshowTab.selectedImage = [];
    },
  },
});

export const {
  enableImageSelection,
  disableImageSelection,
  SelectedImage,
  SelectMultImage,
  DeSelectImage,
  currentSortOption,
  swtichGridOption,
  enableAlbumImageSelection,
  disableAlbumImageSelection,
  selectMultAlumSelection,
  resetAlbumSelection,
  callFunction,
  currentAlbumId,
  openRearrange,
  closeRearrange,
  SelectMultAddToAlbumImage,
  DeSelectAddToAlbumImage,
  endTabSession,
  clearTabState,
  clearImageAlbumState,
} = galleryTabSlice.actions;

export default galleryTabSlice.reducer;
