import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSlideShowFullview } from "../store/slices/gallerySlice";
import { Routes, Route, useParams } from "react-router-dom";
import useGallery from "./useGallery";

const useFullscreenPreview = ({ setShowFullView, showFullView, data }) => {
  const dispatch = useDispatch();
  const { addToTags, removeTags, loadMore } = useGallery();

  const [slideCount, setSlideCount] = useState(null);
  const [fullViewThumbs, setFullViewThumbs] = useState(null);
  const [fullView, setFullView] = useState(null);
  const [fullViewThumbIndex, setFullViewThumbIndex] = useState(
    showFullView.index
  );
  const [isZoom, setIsZoom] = useState(false);
  const [showFullViewForZoom, setShowFullViewForZoom] = useState(false)
  const [ZoomSrc, setZoomSrc] = useState({});
  const [tags, setTags] = useState([]);
  const [zoomCount, setZoomCount] = useState(1);
  const [deletePhotoModal, setDeletePhotoModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");

  const [zoom, setZoom] = useState(0);
  const [navAreaLeftHover, setNavAreaLeftHover] = useState(false);
  const [navAreaRightHover, setNavAreaRightHover] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);
  const [show, setShow] = useState(false);


  const swiperRef = useRef(null);
  const zoomRef = useRef(null);

  // console.log(fullViewThumbIndex, showFullView.index, "hey indexxxxxxxx");

  useEffect(() => {
    setTags(data?.[fullViewThumbIndex]?.tags);
  }, [fullViewThumbIndex]);

  // tags

  const handleDelete = (i) => {
    removeTags(data[fullViewThumbIndex]?.id, tags[i]?.text);
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    addToTags(data[fullViewThumbIndex]?.id, tag.text);
    setTags([...tags, tag]);
  };

  const closeButtonHandle = () => {
    if (isZoom) {
      setFullViewThumbs(false);
      setIsZoom(!isZoom);
    } else {
      setFullViewThumbs(true);
      setShowFullView({
        isOpen: false,
        index: fullViewThumbIndex,
      });
    }
    document.removeEventListener("fullscreenchange", revert);
  };
  const handleClick = (e) => {
    const currentDataValue = e?.target?.getAttribute("data-index");
  };

  useLayoutEffect(() => {
    if (isZoom) {
      setZoomSrc(data[fullViewThumbIndex]?.src);
      setSlideCount(fullViewThumbIndex);
      setShowFullView({
        ...showFullView,
        index: fullViewThumbIndex,
      });
    }
    return () => {
      setZoomSrc({});
    };
  }, [isZoom])



  const handleClickSlideShow = () => {
    let data = {
      isOpen: true,
      type: showFullView.type,
      mode: "all",
    };
    dispatch(openSlideShowFullview(data));
  };

  const handleZoom = () => {
    setIsZoom(!isZoom);
  };

  
  const revert = () => {
    if (!document.fullscreenElement) {
      if (isZoom) {
        setFullViewThumbs(false);
        setIsZoom(!isZoom);
      } else {
        setFullViewThumbs(true);
        setShowFullView({
          isOpen: false,
          index: fullViewThumbIndex,
        });
      }
      document.removeEventListener("fullscreenchange", revert);
    }
  };

  useEffect(() => {
    if (isZoom) {
      let elem = zoomRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      }
    }
    return () => {
      document.removeEventListener("fullscreenchange", revert);
    };
  }, [isZoom]);


  const slideChangeHandler = (e) => {
    if (e.activeIndex == data?.length - 1) {
      loadMore("all");
    }
  };

  const previousHandler = () => {
    if (slideCount >= 1) {
      setSlideCount(slideCount - 1);
    }

    swiperRef.current?.swiper?.slidePrev();
  };

  const nextHandler = () => {
    if (slideCount < data?.length - 1) {
      setSlideCount(slideCount + 1);
    }
    swiperRef.current?.swiper?.slideNext();
  };

  return {
    slideCount,
    setSlideCount,
    fullViewThumbs,
    setFullViewThumbs,
    fullView,
    setFullView,
    fullViewThumbIndex,
    setFullViewThumbIndex,
    isZoom,
    setIsZoom,
    tags,
    setTags,
    zoomCount,
    setZoomCount,
    deletePhotoModal,
    setDeletePhotoModal,
    itemToDelete,
    setItemToDelete,
    zoom,
    setZoom,
    navAreaLeftHover,
    setNavAreaLeftHover,
    navAreaRightHover,
    setNavAreaRightHover,
    show,
    setShow,
    menuVisible,
    setMenuVisible,
    swiperRef,
    zoomRef,
    handleClick,
    handleDelete,
    handleAddition,
    handleZoom,
    handleClickSlideShow,
    slideChangeHandler,
    previousHandler,
    nextHandler,
    closeButtonHandle,
    showFullViewForZoom
  };
};

export default useFullscreenPreview;
