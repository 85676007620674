import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import Icons from "../../Layouts/Icons";
import Style from "./NewAlbumListView.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import GalleryImage from "../../Gallery/ImageGallery/ImageThumb";
import Masonry from "react-masonry-css";
import useGallery from "../../../Logic/useGallery";
import {
  addAlbumListitemsHandler,
  currentSelectedAlbum,
  getMusicList,
} from "../../../store/slices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import useAlbum from "../../../Logic/useAlbum";
import CommonModal from "../../Modals/commonModal";
import { useAlbumImageViewer } from "../../../Logic/AlbumHooks/useAlbumImageViewer";
import FullscreenPreview from "../../Gallery/FullscreenPreview";
import SlideShowFullViewTest from "../../Slideshow/SlideShowFullViewTest";
import Assets from "../../Layouts/Assets";
import loader from "../../../assets/images/loader/Spinner.svg";
import { DashboardLoader } from "../../DashboardLoader";
import GalleryBlankPage from "../../BlankGallery";
import ImageListMasonry from "../../ImageListMasonry";
import { useAlbumListView } from "./useAlbumListView";
import { handleSelectedAlbum } from "../../../store/slices/Album/AlbumDetails";
import { currentAlbumId } from "../../../store/slices/GalleryTabSlice";
import ImageListMasonryAlbum from "../../ImageListMasonryForAlbum";
import { useFullscreenPreviewActions } from "../../Gallery/FullScreenPreviewNew/useFullScreenPreviewActions";
import { useQuickSlideShowActions } from "../../Slideshow/QuickSlideShow/useQuickSlideShowActions";
import FullscreenPreviewNew from "../../Gallery/FullScreenPreviewNew";
import QuickSlideShow1 from "../../Slideshow/QuickSlideShow1";
import { useStopWindowScroll } from "../../../Logic/useStopWindowScroll";

const NewAlbumListView = ({
  data,
  handleOpenEditModal,
  handleOpenDelete,
  handleOpenSlideShow,
  handleUnLockAlbum,
  setOpenShowAlbum,
  setIsPassVisible,
  handleLock,
  selectedAlbumId,
}) => {
  const {
    collapseList,
    handleCollapse,
    selections,
    handleClickSelection,
    default_album_id,
    albumItemRef,
  } = useAlbumListView(data);
  const location = useLocation();

  const { showFullView, setShowFullView, openPreview } =
    useFullscreenPreviewActions();

  const {
    quickSlideShow,
    selectedImageForSlideShow,
    openQuickSlideShow,
    closeQuickSlideShow,
  } = useQuickSlideShowActions(showFullView, setShowFullView);

  const CustomToggle = ({ children, eventKey, className }) => {
    return (
      <button
        type="button"
        onClick={() => handleCollapse(eventKey)}
        className={className}
      >
        {children}
      </button>
    );
  };

  const stopScroll = useStopWindowScroll();
  useEffect(() => {
    stopScroll(showFullView.isOpen);
  }, [showFullView.isOpen]);

  //old code
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    sections,
    currentTab,
    quickSlideShowActions,
    musicList,
    isLocking,
    albumListStatus,
  } = useSelector((state) => state.gallery);

  useLayoutEffect(() => {
    if (musicList?.length == 0) {
      dispatch(getMusicList());
    }
  }, []);

  const handleClickAlbumItem = (data) => {
    if (!selections?.ImageAlbumTab?.selction && !data?.locked) {
      dispatch(handleSelectedAlbum(data?.id));
      dispatch(currentAlbumId(data?.id));
      navigate(`${data?.id}`);
    }
  };

  let slideData = {
    images:
      quickSlideShow?.slideActions.type == "all"
        ? showFullView?.imgArry
        : selectedImageForSlideShow,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };

  let previewData = {
    images: data?.[showFullView?.album_index]?.images,
    pathFrom: "gallery",
    section: "all",
  };

  const DropdownMenu = ({ AlbumIndex, item }) => {
    return (
      <>
        <CustomToggle
          eventKey={`${AlbumIndex}`}
          className={`${Style.list_album_card_collapse} list-album-card-collapse`}
        >
          <Icons icon="icon-dropdown-arrow" size={12} />
        </CustomToggle>
        <Dropdown className={Style.list_album_card_options}>
          <Dropdown.Toggle className={Style.list_album_card_options_btn}>
            <Icons icon="icon-option-dots" size={12} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                handleOpenEditModal(item);
              }}
            >
              Edit album
            </Dropdown.Item>
            {item?.images?.length > 0 ? (
              <>
                <Dropdown.Item
                  onClick={() => {
                    handleOpenSlideShow(item);
                  }}
                >
                  Play slideshow
                </Dropdown.Item>
              </>
            ) : (
              ""
            )}

            <Dropdown.Item onClick={() => handleUnLockAlbum(item, AlbumIndex)}>
              {item?.locked ? "Unlock Album" : "Lock Album"}
            </Dropdown.Item>

            {/* <Dropdown.Item>Download</Dropdown.Item> */}
            {/* <Dropdown.Item>Share</Dropdown.Item> */}
            {item?.id != default_album_id ? (
              <Dropdown.Item onClick={() => handleOpenDelete(item?.id)}>
                Delete
              </Dropdown.Item>
            ) : (
              ""
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const CollapseHeadSideMenu = (i, item) => {
    return selections?.ImageAlbumTab?.selction ? (
      <>
        {item?.id != default_album_id &&
          !item?.locked &&
          (selections?.ImageAlbumTab.selectedImage.includes(item.id) != true ? (
            <div className={Style.selection} />
          ) : (
            <figure className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={15} />
            </figure>
          ))}
      </>
    ) : (
      <>
        <DropdownMenu AlbumIndex={i} item={item} />
      </>
    );
  };

  return (
    //FIXME lock screen fix
    <div className={Style.list_album}>
      <Accordion
        defaultActiveKey={collapseList}
        activeKey={collapseList}
        flush
        alwaysOpen
      >
        {data?.map((item, i) => {
          const lastIndex = data?.length - 1;
          return (
            <div
              className={Style.list_album_item}
              key={i}
              ref={lastIndex == i ? albumItemRef : null}
              onClick={() => handleClickSelection(item)}
            >
              <Accordion.Collapse
                eventKey={`${i}`}
                className={`${
                  item?.images?.length === 0 || item?.images?.length == null
                    ? "no-images"
                    : ""
                }
                    ${
                      selections?.ImageAlbumTab?.selction &&
                      selections?.ImageAlbumTab.selectedImage.includes(
                        item.id
                      ) != true &&
                      item?.id != default_album_id &&
                      `${Style.preselection}`
                    }
                    
                    `}
              >
                {/* ${Style.preselection} */}

                <div className={Style.list_album_images}>
                  <ImageListMasonryAlbum
                    Images={item?.images}
                    loadMore={{}}
                    openFullView={(a, b) => openPreview(a, b, item, i)}
                    albumData={item}
                  />
                </div>
              </Accordion.Collapse>

              <div
                onClick={() =>
                  item?.locked ? handleUnLockAlbum(item?.id, i) : null
                }
                // ${Style.preselection} ${Style.selection_mode}
                className={`${Style.list_album_card} 
                  ${
                    selections?.ImageAlbumTab?.selction &&
                    selections?.ImageAlbumTab.selectedImage.includes(item.id) !=
                      true &&
                    item?.id != default_album_id &&
                    `${Style.preselection}`
                  }
                  ${item?.locked ? Style.disabled : ""} 
                  ${
                    selections?.AddSlideShowSelection?.selction &&
                    location.pathname == "/dashboard/slideshow"
                      ? Style.disableTab
                      : ""
                  }
                    list-album-card  `}
              >
                <CustomToggle
                  eventKey={`${i}`}
                  className={`${Style.accordion_toggle_overlay} ${"d-none"}`}
                />
                <div
                  className={Style.list_album_card_data}
                  onClick={() => handleClickAlbumItem(item)}
                >
                  <h4 className={Style.list_album_card_title}>
                    {item?.title?.length == 1
                      ? item?.title.toUpperCase()
                      : item?.title}

                    <span>
                      {`${item?.images?.length ? item?.images?.length : 0}`}
                    </span>
                  </h4>
                </div>
                <div className={Style.list_album_card_menu}>
                  {item?.locked ? (
                    <div className={Style.lock_btn}>
                      <Icons icon={"lock"} size={18} />
                    </div>
                  ) : item?.id == selectedAlbumId && isLocking == "loading" ? (
                    <img src={loader} className={Style.spinner} alt="" />
                  ) : (
                    ""
                  )}

                  {CollapseHeadSideMenu(i, item)}
                </div>
              </div>
            </div>
          );
        })}
      </Accordion>

      {showFullView.isOpen && (
        <FullscreenPreviewNew
          total={previewData?.images?.length}
          previewObj={previewData}
          openSlideShow={openQuickSlideShow}
          showFullView={showFullView}
          setShowFullView={setShowFullView}
        />
      )}

      {quickSlideShow?.slideActions?.isOpen && (
        <QuickSlideShow1
          slideObj={slideData}
          close={closeQuickSlideShow}
          initialIndex={showFullView?.index}
        />
        // <QuickSlideShow slideObj={slideData} close={closeQuickSlideShow} />
      )}
    </div>
  );
};

export default NewAlbumListView;
