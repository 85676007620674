import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { Col, Row, Form } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import GalleryBlankPage from "../BlankGallery";
import Icons from "../Layouts/Icons";
import Style from "./Album.module.scss";
// import useAlbum from "../../Logic/useAlbum";
import CommonModal from "../Modals/commonModal";
import FrameItem from "./FrameItem";
import FitToFrame from "./FitToFrame/FitToFrame";
import useGallery from "../../Logic/useGallery";
import NewAlbumListView from "../NewAlbum/NewAlbumListView";
import NewAlbumGridView from "../NewAlbum/NewAlbumGridView";
import CreateSection from "./Modals/CreateSection";
import SlideShowFullViewTest from "../Slideshow/SlideShowFullViewTest";
import { useDispatch, useSelector } from "react-redux";
import { useKey, useSingleKey } from "../../utils/KeyBoardShortCuts";
import { useAlbumQuickViewer } from "../../Logic/AlbumHooks/useAlbumQuickViewer";
import { useAlbumLock } from "../../Logic/AlbumHooks/useAlbumLock";
import { useAlbum } from "./useAlbum";
import AlbumEditModal from "./AlbumEditModal";
import AlubmUnlockModal from "./AlbumUnlockModal";
import AlbumDeleteModal from "./AlbumDeleteModal";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import QuickSlideShow1 from "../Slideshow/QuickSlideShow1";
import { useLocation } from "react-router-dom";
import { DashboardLoader } from "../DashboardLoader";

const Album = () => {
  const location = useLocation();

  const {
    albums,
    selections,
    albumEditModal,
    setAlbumEditModal,
    handleClickEdit,
    unlockModal,
    setUnlockModal,
    handleAlbumLock,
    albumDeleteModal,
    setAlbumDeleteModal,
    handleClickDelete,
    moveAlbumItems,
    loadMore,
  } = useAlbum();

  const {
    openShowAlbum,
    isPassVisible,
    handleOpenLock,
    ele_password,
    handlePasswordChange,
    setOpenShowAlbum,
    setIsPassVisible,
    handleUnLockAlbum,
    ShowPassword,
    handleComparePassword,
    isValid,
    password,
    handleBlur,
    isBlur,
    handleLock,
    selectedAlbumId,
    message,
  } = useAlbumLock();

  const {
    quickSlideShow,
    currentSlideData,
    setCurrentSlideData,
    handleOpenSlideShowModal,
    closeQuickSlideShow,
    openSlideShow,
  } = useAlbumQuickViewer();

  const [frameData, setFrameData] = useState({});
  const [activeId, setActiveId] = useState(null);

  const { quickSlideShowActions, gridView, musicList, isLocking } = useSelector(
    (state) => state.gallery
  );

  useEffect(() => {
    setActiveId(frameData?.frame_id);
  }, [frameData]);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  // useEffect(() => {
  //   if (albumItemRef.current) {
  //     const interceptConfig = {
  //       root: albumItemRef.current,
  //       rootMargin: "0px",
  //       threshold: 0.1,
  //     };
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries.every((entry) => entry.isIntersecting)) {
  //         loadMore("album");
  //       }
  //     });
  //     observer.current.observe(albumItemRef.current);
  //     return () => {
  //       observer.current.disconnect();
  //     };
  //   }
  // }, [albumData]);

  // let firstImageIdForCreateMode = galleryState?.selectedImages?.[0];
  // let imageForCreateMode = sections["all"]?.data?.filter(
  //   (item) => item.id == firstImageIdForCreateMode
  // );

  // const handleOpenPreSelect = (e) => {
  //   handleAlbumPreSelection();
  // };

  // const handleSelectAllImages = () => {
  //   selectAllAlbumListHandler();
  // };

  // const handleClosePreSelect = () => {
  //   unSelectAllAlbumListHandler();
  // };

  // const handleEscape = () => {
  //   closeAlbumListActions();
  // };

  // useKey("KeyS", handleOpenPreSelect);
  // useKey("KeyX", handleClosePreSelect);
  // useKey("KeyA", handleSelectAllImages);
  // useSingleKey("Escape", handleEscape);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (galleryState?.currentActions?.openAlbumModal) {
  //       document.querySelectorAll(".modal input")[0].focus();
  //     }
  //   }, 500);
  // }, [galleryState?.currentActions?.openAlbumModal]);

  // const closeEditModal = () => {
  //   setActiveId(null);
  //   handleOpenAlbumClose();
  // };

  let slideData = {
    images: currentSlideData,
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };


  return (
    <div className={Style.album}>
      <div className={Style.album_container}>
        {location.pathname == "/dashboard/album" && (
          <>
            {!selections?.ImageAlbumTab?.grid ? (
              <NewAlbumListView
                data={albums?.resultData?.data}
                handleOpenEditModal={handleClickEdit}
                handleOpenSlideShow={handleOpenSlideShowModal}
                handleUnLockAlbum={handleAlbumLock}
                handleLock={handleLock}
                selectedAlbumId={selectedAlbumId}
                handleOpenDelete={handleClickDelete}
              />
            ) : (
              <NewAlbumGridView
                albumData={albums?.resultData}
                data={albums?.resultData?.data}
                handleOpenEditModal={handleClickEdit}
                handleOpenSlideShow={handleOpenSlideShowModal}
                handleUnLockAlbum={handleAlbumLock}
                handleLock={handleLock}
                handleOpenDelete={handleClickDelete}
                moveAlbumItems={moveAlbumItems}
                options={selections?.ImageAlbumTab}
                loadMore={loadMore}
              />
            )}
          </>
        )}

        {location.pathname == "/dashboard/slideshow" ? (
          <NewAlbumListView
            data={albums?.resultData?.data}
            handleOpenEditModal={handleClickEdit}
            handleOpenSlideShow={handleOpenSlideShowModal}
            handleUnLockAlbum={handleAlbumLock}
            handleLock={handleLock}
            selectedAlbumId={selectedAlbumId}
            handleOpenDelete={handleClickDelete}
          />
        ) : (
          ""
        )}
      </div>
      
      {albums?.status == "loading" ? (
        <DashboardLoader data={albums?.resultData?.data} />
      ) : (
        ""
      )}
      <AlbumEditModal
        albumEditModal={albumEditModal}
        setAlbumEditModal={setAlbumEditModal}
      />
      <AlubmUnlockModal
        unlockModal={unlockModal}
        setUnlockModal={setUnlockModal}
      />
      <AlbumDeleteModal
        albumDeleteModal={albumDeleteModal}
        setAlbumDeleteModal={setAlbumDeleteModal}
      />

      {openSlideShow.open && (
        <QuickSlideShow1 slideObj={slideData} close={closeQuickSlideShow} />
      )}
    </div>
  );
};

export default Album;
