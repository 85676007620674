import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMusicList,
  saveSlideShow,
  getSlideShow,
  callFunction,
  deleteImageFromSlideshow,
  changeSlideShowEditState,
} from "../store/slices/gallerySlice";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

const useSlideShowEdit = () => {
  let musicData;
  let totalImages;
  let filteredImages = [];
  let albumImageData = [];
  const swiperRef = useRef(null);
  const swiperThumbRef = useRef(null);
  const inputFocusRef = useRef();
  let { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const {
    sections,
    currentTab,
    musicList,
    isSlideShowSaving,
    isSlideShowEditing,
  } = useSelector((state) => state.gallery);

  const [activeThumb, setActiveThumb] = useState(null);
  const [effects, setEffects] = useState("fade-effect");
  const [audioIndex, setAudioIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showSlideShow, setShowSlideShow] = useState(false);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [inputWidth, setInputWidth] = useState(1.5);
  const [slideDuration, setSlideDuration] = useState(5);
  const [isInitialized, setIsInitialized] = useState(false);
  const [time, setTime] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [slides, setSlides] = useState([]);
  const [slideData, setSlideData] = useState({
    isEditing: false,
  });

  const [slideShowAlertModal, setSlideShowAlertModal] = useState(false);

  const getSlideShowsData = (id) => {
    dispatch(getSlideShow(id));
  };

  const currentSelected = (id) => {
    //track current viewed slideshow id
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShowID: id,
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  useEffect(() => {
    if (isInitialized) {
      swiperRef.current.swiper.params.autoplay.delay = slideDuration * 1000;
    }
  }, [slideDuration, isInitialized]);

  useEffect(() => {
    if (slideData.effect) {
      setEffects(slideData.effect);
    }
    if (slideData.duration) {
      setSlideDuration(slideData.duration);
    }
    if (slideData.music_id) {
      setAudioIndex(
        musicList.findIndex((item) => item.id == slideData.music_id)
      );
    }
  }, [slideData]);

  useEffect(() => {
    setSlideData({
      ...slideData,
      duration: slideDuration,
    });
  }, [slideDuration]);

  useEffect(() => {
    //fetch call if the slideshow is already visited if not call api
    let slideShowState = sections[currentTab];
    let currentId = slideShowState?.currentSlideShowID;
    if (id && slideShowState?.currentSlideShow?.[currentId] == undefined) {
      currentSelected(id);
      getSlideShowsData(id);
    }

    //setting id to slideshow data
    setSlideData({
      ...slideData,
      slideshow_id: id,
    });
  }, [id]);

  useEffect(() => {
    const imageIds = sections[currentTab]?.selectedImages;
    if (imageIds) {
      sections["album"]?.data?.map((item, index) => {
        if (item?.images?.length > 0) {
          albumImageData = [...albumImageData, ...item?.images];
        }
      });

      let newFilteredItems = albumImageData?.filter((d) => {
        return imageIds?.includes(d?.id);
      });

      const arrayUniqueByKey = (array, key) => {
        return [
          ...new Map(
            newFilteredItems?.map((item) => [item[key], item])
          ).values(),
        ];
      };

      setSlides(arrayUniqueByKey(newFilteredItems, "id"));
    }
  }, []);

  useEffect(() => {
    // check if the slide show is already visited, then load it from state.
    let currentId = sections[currentTab]?.currentSlideShowID;

    if (sections[currentTab]?.currentSlideShow?.[currentId]?.images) {
      let slideArry = sections[currentTab]?.currentSlideShow?.[
        currentId
      ]?.images?.map((item) => item.id);

      setSlides(sections[currentTab]?.currentSlideShow?.[currentId]?.images);
      setSlideData({
        ...slideData,
        add: slideArry,
        slideshow_id:
          sections[currentTab]?.currentSlideShow?.[currentId]?.slideshow?.id,
        ...sections[currentTab]?.currentSlideShow?.[currentId]?.slideshow,
      });
    }
  }, [sections[currentTab]?.currentSlideShow]);

  const handleSave = async (data) => {
    const resultAction = await dispatch(saveSlideShow(data));
    if (saveSlideShow.fulfilled.match(resultAction)) {
      setSlideData({
        ...slideData,
        isEditing: false,
      });
      dispatch(changeSlideShowEditState(false));

      toast.success("Slideshow saved!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const slideshowGalleryAfterSave = (state) => {
        state.sections.slideshow = {
          ...state.sections.slideshow,
          currentActions: {},
          selectedImages: [],
        };
      };
      dispatch(callFunction(slideshowGalleryAfterSave));
    } else {
      toast.error("slideshow action failed", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const addMoreImages = (items, slug) => {
    // add more images from all image section,
    // show images that are preSeleted in this list
    // and load it to selectedImages

    let prevItems = items?.map((item) => item.id);
    const slideshowGalleryAction = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentActions: {
          ...state.sections.slideshow.currentActions,
          imageGalleryOpen: true,
          preselect: true,
          mode: slug ? "update" : "create",
          slideInfo: slug,
          loadMoreType: "others",
        },
        selectedImages: prevItems,
      };
    };
    dispatch(callFunction(slideshowGalleryAction));
  };

  const deleteImageFromState = (id) => {
    let currentId = sections[currentTab]?.currentSlideShowID;
    let data = {
      currentId: currentId,
      slideIndex: id,
    };
    dispatch(deleteImageFromSlideshow(data));
  };

  const handleGoto = () => {
    const handleClearActions = (state) => {
      state.sections.slideshow = {
        ...state.sections.slideshow,
        currentSlideShowID: "",
        currentSlideShow: {},
        currentActions: {},
        selectedImages: [],
      };
    };
    dispatch(callFunction(handleClearActions));
  };

  ////////////////

  useEffect(() => {
    let slideShowState = sections[currentTab];
    let selectedItems = sections[currentTab]?.selectedImages;
    let currentId = slideShowState?.currentSlideShowID;

    if (slideShowState?.currentSlideShow?.[currentId] && currentId == id) {
      let newfilteredImages = albumImageData.filter((d) => {
        return selectedItems?.includes(d?.id);
      });

      const arrayUniqueByKey = (array, key) => {
        return [...new Map(array.map((item) => [item[key], item])).values()];
      };

      let images =
        selectedItems?.length == 0
          ? slideShowState?.currentSlideShow?.[currentId]?.images
          : arrayUniqueByKey(newfilteredImages, "id");
      let slideArry = slideShowState?.currentSlideShow?.[
        currentId
      ]?.images?.map((item) => item.id);

      setSlides(images);
      setSlideData({
        ...slideData,
        name: slideShowState?.currentSlideShow?.[currentId]?.slideshow?.name,
        slideshow_id:
          slideShowState?.currentSlideShow?.[currentId]?.slideshow?.id,
        add: selectedItems?.length == 0 ? slideArry : selectedItems,
      });
    } else if (selectedItems?.length > 0) {
      setSlideData({
        ...slideData,
        name: slideShowState?.name,
        add: [...selectedItems],
      });
      // dispatch(changeSlideShowEditState(true));
    }
  }, [id]);

  const slideChangeHandler = (e) => {
    setActiveIndex(e.realIndex);
  };


  const handleClickAddImages = (data, id) => {
    const albumViewAction = (state) => {
      state.gridView =true;
    };
    dispatch(callFunction(albumViewAction));
    dispatch(changeSlideShowEditState(true));
    addMoreImages(data, id);
    navigate("/dashboard/slideshow");
  };

  const handleClickGoBack = () => {
    if (isSlideShowEditing) {
      setSlideShowAlertModal(true);
    } else {
      navigate("/dashboard/slideshow");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSlideData({
      ...slideData,
      [name]: value,
    });
    dispatch(changeSlideShowEditState(true));
  };

  const handleEffectClick = (effectName) => {
    setEffects(effectName);
    setSlideData({
      ...slideData,
      effect: effectName,
    });
    dispatch(changeSlideShowEditState(true));
  };

  const enableEditing = () => {
    setIsEditable(true);
    let inputValue = inputFocusRef.current.value;
    inputFocusRef.current.value = "";
    inputFocusRef.current.value = inputValue;
    setTimeout(() => {
      inputFocusRef.current.focus();
    }, 10);
  };

  const changeHandler = (e) => {
    setSlideDuration(e.target.value);
    setInputWidth(e.target.value.length);
    dispatch(changeSlideShowEditState(true));
  };

  const handleIncrement = () => {
    let inputVal = slideDuration;
    inputVal += 1;
    setSlideDuration(inputVal);

    dispatch(changeSlideShowEditState(true));
  };

  const handleDecrement = () => {
    let inputVal = slideDuration;
    if (inputVal > 1) {
      inputVal -= 1;
      setSlideDuration(inputVal);
      dispatch(changeSlideShowEditState(true));
    }
  };

  const handleClickedBack = () => {
    handleGoto();
    navigate(-1);
  };

  return {
    musicData,
    slides,
    musicList,
    // selectedItems: sections[currentTab]?.selectedImages,
    // slideShowState: sections[currentTab],
    // handleSave,
    // getSlideShowsData,
    // addMoreImages,
    setSlides,
    // slideData,
    // setSlideData,
    // currentSelected,
    isSlideShowSaving,
    // handleGoto,
    sections,
    deleteImageFromState,
    // activeThumb,
    setActiveThumb,
    // effects,
    // setEffects,
    // audioIndex,
    // setAudioIndex,
    // activeIndex,
    // setActiveIndex,
    showSlideShow,
    // setShowSlideShow,
    showPlayPause,
    setShowPlayPause,
    // inputWidth,
    // setInputWidth,
    slideDuration,
    // setSlideDuration,
    // isInitialized,
    // setIsInitialized,
    time,
    setTime,
    swiperRef,
    inputFocusRef,
    swiperThumbRef,

    // slideChangeHandler,
    handleClickAddImages,
    // handleClickGoBack,
    // handleChange,
    // handleEffectClick,

    // enableEditing,
    // changeHandler,
    // handleIncrement,
    // handleDecrement,

    // isEditable,
    // setIsEditable,

    slideShowAlertModal,
    setSlideShowAlertModal,

    isSlideShowEditing,
    handleClickedBack,
  };
};

export default useSlideShowEdit;
