import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import useAlbum from "../../../Logic/useAlbum";
import { LoadmoreAlbums } from "../../../store/slices/albumSlice";
import GalleryBlankPage from "../../BlankGallery";
import { DashboardLoader } from "../../DashboardLoader";
import CommonModal from "../../Modals/commonModal";
import { AlbumCardItem } from "./AlbumCardItem";
import Style from "./NewAlbumGridView.module.scss";
import { useAlbumGridView } from "./useAlbumGridView";
import useGridList from "./useGridList";

const NewAlbumGridView = ({
  albumData,
  data,
  handleOpenEditModal,
  handleOpenSlideShow,
  handleLock,
  handleUnLockAlbum,
  selectedAlbumId,
  handleOpenDelete,
  moveAlbumItems,
  options,
}) => {


  const { observer, imgRootRef, imgItemRef, breakpointColumnsObj } =
    useGridList();





  return (
    <div className={`${Style.grid_album} `} ref={imgRootRef}>
      <div className={`${Style.grid_album_row}`}>
        {data?.map((item, i) => {
          const lastIndex = data?.length - 1;
          return (
            <AlbumCardItem
              key={i}
              i={i}
              item={item}
              handleOpenEditModal={handleOpenEditModal}
              handleOpenDelete={handleOpenDelete}
              handleOpenSlideShow={() => handleOpenSlideShow(item)}
              moveListItem={moveAlbumItems}
              handleLock={handleLock}
              handleUnLockAlbum={handleUnLockAlbum}
              selectedAlbumId={selectedAlbumId}
              lastIndex={lastIndex}
              dataItems={data}
              options={options}
              albumItemRef={imgItemRef}
            />
          );
        })}

        {/* {albumListStatus == "loading" ? (
          <DashboardLoader data={sections[currentTab]?.data} />
        ) : (
          ""
        )}

        {data?.length == 0 && albumListStatus == "succeeded" ? (
          <GalleryBlankPage
            title={`You haven’t added any Albums`}
            description={`Create Albums now to bring your family story to life.`}
          />
        ) : (
          ""
        )} */}


      </div>
    </div>
  );
};

export default NewAlbumGridView;
