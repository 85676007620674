import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Style from "./country.module.scss";
import { useTranslation } from "react-i18next";
import countryWithFlag from "../country-code.json";
import Select from "react-select";

import countryData from "../../utils/countryData/index";
import ReactCountryFlag from "react-country-flag";

const CountryCodeInput = ({
  onNumChange,
  touched,
  errors,
  onBlur,
  values,
  placeholder,
  handleChangeHandler,
  handleChangeCode,
  handleChangeCountryCode,
  setFieldValue,
  currentAuthData,
}) => {
  const { i18n, t } = useTranslation(["authentication"]);
  const [countryDialCode, setCountryDialCode] = useState("+91");
  const [countryCode, setCountryCode] = useState("IN");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneChanged, setIsphoneChanged] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    label: "+91",
    value: "+91",
  });

  useEffect(() => {
    setPhoneNumber(values?.phone_number);
  }, [values]);

  useEffect(() => {
    handleChangeCode(countryDialCode);
    handleChangeCountryCode(countryCode);
  }, [countryDialCode]);

  useEffect(() => {
    if (currentAuthData?.phone_number && currentAuthData?.country_dial_code) {
      let data = countryData?.filter(
        (item) => item?.value == currentAuthData?.country_dial_code
      );

      setCurrentCountry(data[0]);
      setFieldValue("phone_number", currentAuthData?.phone_number);
    }
  }, [currentAuthData]);

  const [isSelectFocus, setIsSelectFocus] = useState(false);
  const [isInputFoucs, setIsInputFoucs] = useState(false);

  const handleChangeSelect = (e) => {
    let data = countryData?.filter((item) => item?.value == e.value);
    setCountryDialCode(e.value);
    setCurrentCountry(data[0]);
    setCountryCode(e.code);
  };


  return (
    <div
      className={`${errors && touched ? "form-group error" : "form-group"} ${
        isInputFoucs || isSelectFocus ? Style.countryCode_focused : ""
      }`}
    >
      <label htmlFor="signin_input_mobile">{`${t("register_phone")} *`}</label>
      <div className={`${Style.mobile_input} phone-number-input-wrap`}>
        <input
          type="number"
          name="phone_number"
          placeholder={placeholder}
          id="phone_number"
          value={phoneNumber}
          className="form-control"
          onChange={(e) => {
            handleChangeHandler(e);
            // setIsphoneChanged(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setIsInputFoucs(false);
          }}
          onFocus={() => setIsInputFoucs(true)}
        />
        <div className={Style.select_wrap}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={countryData[0]}
            value={currentCountry}
            isSearchable={true}
            options={countryData}
            onChange={handleChangeSelect}
            onFocus={() => setIsSelectFocus(true)}
            onBlur={() => setIsSelectFocus(false)}
            formatOptionLabel={(country) => (
              <div className="country-option">
                <ReactCountryFlag countryCode={country.code} svg />
                <span className={"country_code_name"}>{country.name}</span>
                <span>{country.value}</span>
              </div>
            )}
          />
        </div>
      </div>
      {errors && touched ? (
        <span className="field_error_message">{errors}</span>
      ) : null}
    </div>
  );
};

export default CountryCodeInput;
