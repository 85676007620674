import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import Style from "./HomeChooseAPlan.module.scss";
import { Container } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import { useDimensions } from "../../Logic/Dimensions";
import { useHome } from "../../Logic/useHome";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import { authModalHandler, changeScreen } from "../../store/slices/authSlice";
import useAuthChecker from "../../Logic/useAuthChecker";
import { useDispatch } from "react-redux";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import { paymentModalAction } from "../../store/slices/checkoutSlice";
import { useSelector } from "react-redux";
const HomeChooseAPlan = () => {
  const navigate = useNavigate();
  const { userdata } = useSelector((state) => state.userprofile);
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const dispatch = useDispatch();
  const { plans, plansData } = useHome();
  const { isAuthCompleted } = useAuthChecker();

  useEffect(() => {
    plans();
  }, []);

  const { handleInitialtePayment, status, selectedId, country } = usePlans();

  const handleClickLogin = () => {
    dispatch(authModalHandler(true));
    dispatch(changeScreen("login"));
  };

  const handleSubscribe = (item) => {
    if (item?.plans_prices[0]?.countries?.country_dial_code == "91") {
      window.location = `/payment/${item.id}/${item?.plans_prices[0]?.countries?.id}/${item?.price}`
    }
    else if (isAuthCompleted) {
      let data = {
        isOpen: true,
        item: item,
      };
      dispatch(paymentModalAction(data));
      //  handleInitialtePayment(item);
    } else {
      handleClickLogin();
    }
  };

  const handleGotoLogin = () => {
    // console.log("hii..");
    handleClickLogin();
  };

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location.pathname]);

  const { planData, isPlanActive } = usePurchasedChecker();

  return (
    <div
      className={`${location.pathname !== "/"
        ? `${Style.home_choose_plans} ${Style.home_choose_plans_get_started}`
        : Style.home_choose_plans
        } `}
    >
      <div className={Style.anim_elements}>
        {/* <figure
        className={`${Style.anim_elem} ${Style.circle_green} anim_element anim`}
      >
        <img src={Assets.plan_circle_green} alt="circle_yellow" />
      </figure> */}
        <figure
          className={`${Style.anim_elem} ${Style.donut_yellow} anim_element anim`}
        >
          <img src={Assets.plan_donut_yellow} alt="circle_yellow" />
        </figure>
      </div>
      <Container>
        <div className={Style.main_wrapper}>
          <div className={`row`}>
            <div className={`col-12 col-lg-6 col-xl-7`}>
              <div className={Style.text_inner}>
                <div className={Style.image_wrapper}>
                  <div className={Style.image_container}>
                    <img
                      src={Assets.familyPhotos}
                      alt={`${t("choose_plan")}`}
                    />
                  </div>
                </div>

                <div className={Style.text_wrapper}>
                  <h2 className="anim load-anim">{t("choose_plan")}</h2>
                  <p className="anim load-anim">{t("choose_plan_desc")}</p>
                </div>
              </div>
            </div>
            <div
              className={`col-12 col-lg-6 col-xl-5 ${isPlanActive ? Style.purchased_comments : ""
                }`}
            >
              <div
                className={`${Style.home_choose_plans_cards} ${Style.singleOffer} anim load-anim`}
              >
                <>
                  {plansData
                    ? plansData.map((item, index) => {
                      // console.log(item, "hye plan check...");
                      return (
                        <div
                          className={`${Style.home_choose_plans_card_wrap}`}
                          key={item.id}
                        >
                          <Link to="">
                            <div className={Style.home_choose_plans_card}>
                              <div className={Style.card_top}>
                                <div
                                  className={`${Style.plan_duration} ${Style.plan_monthly}`}
                                >
                                  {item.name}
                                  {item.is_recommended ? (
                                    <span className={Style.recommended_label}>
                                      Recommended
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h4 className={Style.plan_amount}>
                                  <span className={Style.plan_amount_value}>
                                    {item?.plans_prices?.[0]?.symbol}
                                    {item.price}
                                  </span>{" "}
                                  / {item.plan_interval}
                                </h4>
                                <p className={Style.plan_description}>
                                  {item.description}
                                </p>
                                <div className={Style.plan_features}>
                                  <>{parse(item.features)}</>
                                </div>
                              </div>
                              <div
                                className={
                                  Style.home_choose_plans_card_button
                                }
                              >
                                {!isPlanActive ? (
                                  <button
                                    className="btn btn-border-primary"
                                    onClick={() => handleSubscribe(item)}
                                  >
                                    {selectedId == item?.id &&
                                      status == "loading" ? (
                                      <InfiniteDotLoader />
                                    ) : (
                                      <>{t("subscribe")}</>
                                    )}
                                  </button>
                                ) : isAuthCompleted && isPlanActive ? (
                                  <button className="btn btn-border-primary">
                                    {t("already_purchased")}🎉
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-border-primary"
                                    onClick={() => handleGotoLogin()}
                                  >
                                    {t("subscribe")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                    : [1, 2].map((item, index) => {
                      return (
                        <div
                          className={`${Style.home_choose_plans_card_wrap} data-loading`}
                          key={index}
                        >
                          <div className={Style.home_choose_plans_card}>
                            <div className={Style.card_top}>
                              <div
                                className={`${Style.plan_duration} ${Style.plan_monthly}`}
                              ></div>
                              <h4 className={Style.plan_amount}>
                                <span
                                  className={Style.plan_amount_value}
                                ></span>{" "}
                              </h4>
                              <p className={Style.plan_description}>
                                <span className={Style.lines}></span>
                                <span className={Style.lines}></span>
                                <span className={Style.lines}></span>
                              </p>
                              <ul className={Style.plan_features}>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                            <div
                              className={Style.home_choose_plans_card_button}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                </>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>

  );
};

export default HomeChooseAPlan;


