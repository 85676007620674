import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { removeArchive } from "../../../../store/slices/Archive/archiveSlice";
import {
  addToFavorites,
  removeFromFavorites,
} from "../../../../store/slices/favoriteSlice";
import { updateFavorite } from "../../../../store/slices/GalleryImagesSlice";
import {
  currentAlbumId,
  enableAlbumImageSelection,
  SelectedImage,
} from "../../../../store/slices/GalleryTabSlice";
import { toast } from "react-toastify";
import { removeTrash } from "../../../../store/slices/Trash/trashSlice";
import {
  getAlbums,
  updateAlbumFavorite,
} from "../../../../store/slices/albumSlice";
import { updateProfileGallery } from "../../../../store/slices/ProfileGallery/profileGallerySlice";
import { getAlbumItem } from "../../../../store/slices/Album/AlbumDetails";

const useGalleryImage = ({ handleOpenImageFullView, albumData }) => {
  const { albumId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toBeDragged, setToBeDragged] = useState(false);
  const [DeleteModal, setDeleteModal] = useState({
    open: false,
    imageId: null,
  });
  const [deleteAlbumImageModal, setDeleteAlbumImageModal] = useState({
    popup: false,
  });
  const selections = useSelector((state) => state.galleryTab);

  const Images = useSelector((state) => state.galleryAllTab.resultData);
  const Favorites = useSelector((state) => state.favorite.resultData);
  const Album = useSelector((state) => state.album.resultData);
  const Slideshow = useSelector((state) => state.slideshow.resultData);
  const Archive = useSelector((state) => state.archive.resultData);
  const Trash = useSelector((state) => state.trash.resultData);

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  const selectedArray = () => {
    switch (location.pathname) {
      case "/dashboard":
        return selections?.ImageAllTabSelection;
      case "/dashboard/slideshow":
        return selections?.AddSlideShowSelection;
      case "/dashboard/favorites":
        return selections?.ImageFavoriteTab;
      case "/dashboard/album":
        return {};
      case "/dashboard/archive":
        return selections?.ImageArchiveTab;
      case "/dashboard/trash":
        return selections?.ImageTrashTab;
      case `/dashboard/album/${albumId}`:
        return getAlbumStateData();
    }
  };

  const getAlbumStateData = () => {
    return selections?.ImageAlbumAddToTab?.selction
      ? selections?.ImageAlbumAddToTab
      : selections?.ImageAlbumDetailsTab;
  };

  const handleMouseDown = () => {
    setToBeDragged(true);
  };

  const handleMouseUp = () => {
    setToBeDragged(false);
  };

  const handleClickFavorite = async (Image) => {
    const resultAction = await dispatch(addToFavorites({ Image: Image }));
    if (addToFavorites.fulfilled.match(resultAction)) {
      dispatch(updateFavorite({ id: Image.id, favorite: 1 }));
      dispatch(updateAlbumFavorite({ id: Image.id, favorite: 1 }));
    }
  };

  const handleRemoveFromFavorites = async (id) => {
    const resultAction = await dispatch(removeFromFavorites({ id: id }));
    if (removeFromFavorites.fulfilled.match(resultAction)) {
      dispatch(updateFavorite({ id: id, favorite: 0 }));
      dispatch(updateProfileGallery(id));
      dispatch(updateAlbumFavorite({ id: id, favorite: 0 }));
    }
  };

  const handleDeleteImage = (imageId) => {
    if (location.pathname == "/dashboard/album") {
      setDeleteAlbumImageModal({ popup: true });
      dispatch(getAlbumItem({ page: "1", id: albumData.id }));
      dispatch(currentAlbumId(albumData.id));
      dispatch(
        SelectedImage({ tab: "album" + albumData.id, imageId: [imageId] })
      );
    } else {
      setDeleteModal({ open: true, imageId: [imageId] });
    }
  };
  // addSelectedImage
  const handleImageSelect = (image, index) => {
    if (!(location.pathname == "/dashboard/slideshow" && albumData?.default)) {
      if (selectedArray()?.selction == true) {
        if (currentPath == "slideshow") {
          dispatch(enableAlbumImageSelection({ tab: "addslideshow", image }));
        } else if (selections?.ImageAlbumAddToTab?.selction) {
          dispatch(
            SelectedImage({ tab: "addToAlbumImage", imageId: image.id })
          );
        } else {
          dispatch(SelectedImage({ tab: currentPath, imageId: image.id }));
        }
      } else handleOpenImageFullView(index, image.id);
    }
  };

  const handleUnArchiveImage = async (imageId) => {
    let image_id = [imageId];
    let AllImages = Archive?.data?.filter((item) => image_id.includes(item.id));

    const resultAction = await dispatch(
      removeArchive({ image_id, data: AllImages })
    );
    if (removeArchive.fulfilled.match(resultAction)) {
      toast.success("unarchive success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleRestoreImage = async (imageId) => {
    let image_id = [imageId];
    let AllImages = Trash?.data?.filter((item) => image_id.includes(item.id));

    const resultAction = await dispatch(
      removeTrash({ image_id, data: AllImages })
    );
    if (removeTrash.fulfilled.match(resultAction)) {
      toast.success("restore success !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    toBeDragged,
    setToBeDragged,
    handleMouseDown,
    handleMouseUp,
    handleClickFavorite,
    handleRemoveFromFavorites,
    DeleteModal,
    setDeleteModal,
    handleDeleteImage,
    location,
    selections,
    handleImageSelect,
    handleUnArchiveImage,
    handleRestoreImage,
    deleteAlbumImageModal,
    setDeleteAlbumImageModal,
  };
};

export default useGalleryImage;
