import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Style from "./Dashboard.module.scss";
import { useDimensions } from "../../Logic/Dimensions";
import useOnlineStatus from "../../utils/utilityHooks/onlineStatus";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import useAuthChecker from "../../Logic/useAuthChecker";
import FileUploadMinimizedBox from "../FileUploadMinimizedBox";
import { currentDashboardPage } from "../../store/slices/gallerySlice";
import UploadImageModal from "../Gallery/GalleryFilters/RightFilteredCategory/UploadImageModal";
import { usePurchasedChecker } from "../../Logic/AuthChecker/usePurchaseChecker";
import Sidebar from "./Sidebar";

import { FileUploadContext } from "../../Context/fileUploadContext";
import { useContext } from "react";
import useImageUpload from "../Gallery/GalleryFilters/RightFilteredCategory/UploadImageModal/useImageUpload";
import { openMiniUploadBox } from "../../store/slices/Gallery/fileUploadSlce";

const Dashboard = ({ languageOpen, setLanguageOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();


  let online = useOnlineStatus();
  const windowDimensions = useDimensions();
  const { isAuthCompleted } = useAuthChecker();
  const [activeElement, setActiveElement] = useState("");
  const [collapseMenu, setCollapseMenu] = useState(false);
  const { default_view } = useSelector((state) => state.userpreference);
  const selections = useSelector((state) => state.galleryTab);

  const navigate = useNavigate();

  const { openMiniBox, openUploadModal } = useSelector(
    (state) => state.fileUpload
  );

  const { planData, isPlanActive } = usePurchasedChecker();

  const {
    handleClickCloseMiniModalModal,
    closeUploadModalWhenDone,
    handleImageChange,
    handleDragOver,
    handleDragOut,
    handleDrop,
    openFiles,
    closeModal,
    isDraggingOver,
    fileUploadState,
    imagesLength,
    images,
    duplicateImages,
    invalidImages,
    isUploading,
    uploadIndex,
    allFiles,
    newFiles
  } = useImageUpload({ mode: openUploadModal.mode });

  useEffect(() => {
    if (
      default_view == "album" &&
      !selections?.AddSlideShowSelection?.emptySlide
    ) {
      navigate(`album`);
    }
  }, [default_view]);

  useEffect(() => {
    const mainTabHeadItem = document.querySelectorAll(".sidebar-menu ul li");
    mainTabHeadItem.forEach((tabItem) => {
      tabItem.addEventListener("click", () => {
        for (let item of mainTabHeadItem) {
          item.classList.remove("dashboard-tab-active");
        }
        tabItem.classList.add("dashboard-tab-active");
      });
    });
  }, []);

  useEffect(() => {
    if (location.pathname?.split("/")[2]) {
      dispatch(currentDashboardPage(location.pathname?.split("/")[2]));
      setActiveElement(location.pathname?.split("/")[2]);
    } else {
      dispatch(currentDashboardPage("all"));
    }
  }, [location]);

  return isAuthCompleted && isPlanActive ? (
    <div
      className={`${Style.dashboard} ${collapseMenu
          ? Style.dashboard_sidebar_collapse
          : Style.dashboard_sidebar_expand
        } ${collapseMenu ? "dashboard_sidebar_collapse" : ""} dashboard-main`}
    >
      {windowDimensions.width >= 1280 ? (
        <Sidebar
          collapseMenu={collapseMenu}
          setCollapseMenu={setCollapseMenu}
        />
      ) : (
        <></>
      )}
      <div
        className={`${Style.dashboard_outlet} ${languageOpen && "language-open"
          }`}
      >
        <Outlet />
      </div>
      <UploadImageModal
        openActionModal={openUploadModal}
        closeUploadModalWhenDone={closeUploadModalWhenDone}
        handleImageChange={handleImageChange}
        handleDragOver={handleDragOver}
        handleDragOut={handleDragOut}
        handleDrop={handleDrop}
        openFiles={openFiles}
        closeModal={closeModal}
        isDraggingOver={isDraggingOver}
        fileUploadState={fileUploadState}
        imagesLength={imagesLength}
        images={images}
        duplicateImages={duplicateImages}
        invalidImages={invalidImages}
        uploadIndex={uploadIndex}
        isUploading={isUploading}
        allFiles={allFiles}
        newFiles={newFiles}
      />

      {openMiniBox && (
        <FileUploadMinimizedBox
          images={images}
          duplicateImages={duplicateImages}
          invalidImages={invalidImages}
          uploadIndex={uploadIndex}
          allFiles={allFiles}
          close={handleClickCloseMiniModalModal}
        />
      )}

      <span className={Style.onlineStatus}>{online}</span>
    </div>
  ) : isAuthCompleted && !isPlanActive ? (
    <Navigate to="/choose-plan" />
  ) : (
    <Navigate to="/" />
  );
};

export default Dashboard;
