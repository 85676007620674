import React, { useEffect } from "react";
// import HomeAdvertisements from "../HomeAdvertisements";
import CorporateHomeBanner from "../CorporateHomeBanner";
import CorporateHomeBrowsePhotos from "../CorporateHomeBrowsePhotos";
import HomeExternalLinks from "../HomeExternalLinks";
import HomeChooseAPlan from "../HomeChooseAPlan";
import HomeCreateAndExplore from "../HomeCreateAndExplore";
import CorporateHomeLiveStatus from "../CorporateHomeLiveStatus";
import CorporateHomeMemories from "../CorporateHomeMemories";
import CorporateHomeTestimonials from "../CorporateHomeTestimonials";
import { useLocation } from "react-router-dom";
import CheckOutComponent from "../Payment/CheckOutComponent";
import { usePlans } from "../../Logic/ChoosePlans/usePlans";
import { useSelector } from "react-redux";
import Styles from "./CorporateHome.module.scss";
import CommonModal from "../Modals/commonModal";

const CorporateHome = () => {
  const location = useLocation();

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("visible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [location?.pathname]);

  const { data, isOpen } = useSelector((state) => state.payment);
  const { closePaymentModal } = usePlans();

  return (
    <div className="home-page">
      <CorporateHomeBanner />
      <CorporateHomeBrowsePhotos />
      {/* <HomeAdvertisements /> */}
      <HomeExternalLinks />
      <HomeCreateAndExplore />

      <CorporateHomeTestimonials />
      <HomeChooseAPlan />

      <CorporateHomeMemories />
      
      

      <CorporateHomeLiveStatus />
      <CommonModal
        showModal={isOpen}
        hide={closePaymentModal}
        className={Styles.payment_modal}
        content={<CheckOutComponent />}
      />
    </div>
  );
};

export default CorporateHome;
