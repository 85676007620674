import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CorporatePreLogin from "../../components/Layouts/CorporatePreLogin";
import StyleGuide from "../../components/StyleGuide";

const StyleGuidePage = () => {
  const location = useLocation();

  useEffect(() => {
      (location.pathname === "/style-guide") && document.body.classList.add('small-header');
      return () => {
          document.body.classList.remove('small-header');
      }
  }, [location.pathname])

  return (
    <CorporatePreLogin hasFooter={true}>
      <StyleGuide />
    </CorporatePreLogin>
  );
};

export default StyleGuidePage;
