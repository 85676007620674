import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay, Lazy, EffectFade } from "swiper";

import Style from "./QuickSlideShow.module.scss";
import Icons from "../../Layouts/Icons";
import { useEffect } from "react";
import useAudioPlayer from "../../../Logic/useAudioPlayer";
import { useSingleKey } from "../../../utils/KeyBoardShortCuts";
import { ControlTab } from "./ControlTab";
import { useFullScreen } from "./useFullScreen";
import { useControls } from "./useControls";

const QuickSlideShow = ({ slideObj, close, }) => { //FIXME quickslideshow slider-delay issue pending
    let musicData = slideObj?.music?.length > 0 ? slideObj?.music : [{
        file_name: "https://api.familyphotosalbum.com/storage/music/20221230_63ae637e1d21d.mpeg",
        id: 1,
        name: "Music 1",
        path: "20221230_63ae637e1d21d.mpeg"
    }]
    const swiperRef = useRef(null);
    const { fullScreenRef, isFullscreen, fullScreenMode, controlsVisible } = useFullScreen()
    const { trackIndex, playTrack, handleMute, mute } = useAudioPlayer(musicData);
    const { currentIndex, showPlayPause, setShowPlayPause, slideChangeHandler, playToggle, pauseToggle, previousHandler, nextHandler, handleInitialization, } = useControls({ swiperRef, trackIndex, playTrack })

    useEffect(() => {
        setShowPlayPause(true);
        playTrack(trackIndex);
    }, []);

    useEffect(() => {
        if (!isFullscreen && fullScreenMode) {
            close()
        }
    }, [isFullscreen]);

    useSingleKey("ArrowLeft", previousHandler);
    useSingleKey("ArrowRight", nextHandler);

    return ReactDOM.createPortal(
        <div className={Style.slideshow_fullscreen} ref={fullScreenRef}>
            <div className={Style.back_btn} onClick={close}>
                <Icons icon={"back-arrow"} size={20} color="#ffff" />
            </div>
            <Swiper
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                ref={swiperRef}
                loop={true}
                slidesPerView={1}
               // spaceBetween={10}
                navigation={true}
                speed={2000}
                lazy={true}
                EffectFade={true}
                effect={"fade"}
                modules={[FreeMode, Navigation, Thumbs, Autoplay, Lazy, EffectFade]}
                // className="mySwiper2"
                className={`mySwiper2 ${slideObj?.effect === "zoom-out" ? 'swiper-zoom-out' : ''}`}
                onSlideChange={slideChangeHandler}
                onInit={(e) => handleInitialization(e)}
                allowTouchMove={false}

            >
                {slideObj?.images?.map((imgItem, index) => {
                    return (
                        <SwiperSlide
                            className={currentIndex == index ? slideObj?.effect : "non"}
                            key={imgItem.id}
                        >
                            <figure className={Style.slideshow_fullscreen_image}>
                            <img data-src={imgItem?.src?.original} className="swiper-lazy" alt="" />
                            </figure>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <ControlTab
                onPreviousHandler={previousHandler}
                onNextHandler={nextHandler}
                onPauseToggle={pauseToggle}
                onPlayToggle={playToggle}
                onHandleMute={handleMute}
                onClose={close}
                isVisible={controlsVisible}
                showPlayPause={showPlayPause}
                isMute={mute}
            />
        </div>,
        document.getElementById("slideshow-root")
    );
};

export default QuickSlideShow;
