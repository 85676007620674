import React from "react";
import ImageGallery from "../../Gallery/ImageGallery";
import ImageListMasonry from "../../ImageListMasonry";
import Style from "./AlbumDetailView.module.scss";
import { useAlbumView } from "./useAlbumView";

const AlbumDetailView = () => {
  const { data, selections, rearrangeItems, albumAddToSelection, frames, loadMore } =
    useAlbumView();

  return (
    <div>
      {albumAddToSelection?.selction ? (
        <ImageGallery />
      ) : (
        <>
          <div className={Style.albumDetails}>
            <div className={Style.info}>
              <h2>{data?.album?.title}
                <span >
                  {`${data?.images?.length ? data?.images?.length : 0}`}
                </span>
              </h2>
              <p>{data?.album?.description} </p>
            </div>
          </div>
          <div style={{ padding: "10px" }}>
            <ImageListMasonry
              total={data?.images?.length}
              Images={data?.images}
              loadMore={loadMore}
              NodataContent={{}}
              options={selections}
              moveListItem={rearrangeItems}
              albumData={data?.album}
              frames={frames}
              mode="album"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AlbumDetailView;
