import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  message: null,
  error: null,
  resultData: {},
};

export const getFavoriteImages = createAsyncThunk(
  "favorite/getFavoriteImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-favourite?page=${params.page}&&sort=${params.slug}&per_page=40`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoadmoreImages = createAsyncThunk(
  "favorite/LoadmoreImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `list-favourite?page=${params.page}&&sort=${params.slug}&per_page=40`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToFavorites = createAsyncThunk(
  "favorite/addToFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`add-favourite`, {
        image_id: params.Image.id,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeFromFavorites = createAsyncThunk(
  "favorite/removeFromFavorites",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`remove-favourite`, {
        image_id: params.id,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//FIXME

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    removeImageFavorite: (state, action) => {
      // if (state?.resultData?.data) {
      //   action?.payload?.image_id.forEach((value, key) => {
      //     let images = current(state?.resultData?.data);
      //     console.log(images, action?.payload?.image_id, "hey immer..");
      //     let objWithIdIndex = images.findIndex((obj) => obj.id == value);
      //     if (objWithIdIndex > -1) {
      //       images.splice(objWithIdIndex, 1);
      //       state.resultData.data = images;
      //     }
      //   });

      // }

      if (state?.resultData?.data) {
        let images = current(state.resultData.data);
        state.resultData = {
          ...state.resultData,
          data: images.filter(
            (item, index) => !action?.payload?.image_id.includes(item.id)
          ),
          total: state.resultData.total - action?.payload?.image_id?.length,
        };
      }
    },
  },
  extraReducers: {
    [getFavoriteImages.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFavoriteImages.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = { ...action?.payload?.data?.result };
    },
    [getFavoriteImages.rejected]: (state, action) => {
      state.status = "failed";
    },
    [LoadmoreImages.fulfilled]: (state, action) => {
      state.resultData = {
        data: [
          ...state.resultData?.data,
          ...action?.payload?.data?.result?.data,
        ],
        current_page: action?.payload?.data?.result?.current_page,
        last_page: action?.payload?.data?.result?.last_page,
        total: action?.payload?.data?.result?.total,
        sorting_options: [...state.resultData?.sorting_options],
      };
    },
    [addToFavorites.fulfilled]: (state, action) => {
      let favObjIndex = state.resultData.data.findIndex(
        (obj) => obj.id == action?.meta?.arg?.Image?.id
      );

      if (favObjIndex == -1) {
        state.resultData = {
          data: [
            { ...action?.meta?.arg?.Image, favourite: 1 },
            ...state.resultData?.data,
          ],
          current_page: 1,
          last_page: 1,
          total: state.resultData?.total + 1,
          sorting_options: [...state.resultData?.sorting_options],
        };
      }
    },
    [removeFromFavorites.fulfilled]: (state, action) => {
      if (state?.resultData?.data) {
        let favorite = [...current(state?.resultData?.data)];
        let objWithIdIndex = favorite.findIndex(
          (obj) => obj.id == action?.meta?.arg?.id
        );
        if (objWithIdIndex > -1) {
          favorite.splice(objWithIdIndex, 1);
          state.resultData.data = favorite;
          state.resultData.total = state.resultData.total - 1;
        }
      }
    },

    ["album/editAlbum/fulfilled"]: (state, action) => {
      let images = current(state.resultData.data);
      if (images?.length > 0 && action?.meta?.arg?.albumData?.default) {
        state.resultData = {
          ...state.resultData,
          data: images.filter(
            (item, index) => !action.meta.arg.imageIds.includes(item.id)
          ),
          total: state.resultData.total - 1,
        };
      }
    },
    ["tags/addTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = "";
        if (action.payload.data.result.id) {
          imgIndex = current(state?.resultData?.data).findIndex((obj) => obj.id === action.payload.data.result.id)

        } else {
          imgIndex = current(state?.resultData?.data).findIndex((obj) => obj.id === action.payload.data.result.image_id)

        }
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result?.tags
          state.resultData.data[imgIndex].tags = tagData
        }

      }
    },

    ["tags/removeTags/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        let imgIndex = state?.resultData?.data.findIndex((obj) => obj.id === action.payload.data.result.id)
        if (imgIndex !== -1) {
          let tagData = action.payload.data.result.tags
          state.resultData.data[imgIndex].tags = tagData
        }
      }
    },

    ["trash/removeTrash/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        state.resultData = {
          ...state.resultData,
          data: [...action?.meta?.arg?.data, ...state.resultData.data],
          total: state.resultData.total + action?.meta?.arg?.data?.length
        }
      }
    },


    ["auth/logout"]: (state, action) => {
      state.status = "idle"
      state.resultData = {}
    }

  },
});

export const { removeImageFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
