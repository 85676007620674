import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAlbumImages,
  removeAlbumImagesFromState,
} from "../../../../../store/slices/Album/AlbumDetails";
import { getAlbums } from "../../../../../store/slices/albumSlice";
import { removeImageFromState } from "../../../../../store/slices/GalleryImagesSlice";
import { clearImageAlbumState } from "../../../../../store/slices/GalleryTabSlice";

export const useDeleteAlbumImageModal = ({ setDeleteModal, albumId }) => {
  const dispatch = useDispatch();
  const selectedAlbumImage = useSelector(
    (state) => state.galleryTab.ImageAlbumDetailsTab
  );
  const albumList = useSelector(
    (state) => state?.album?.resultData?.data
  );
  const { isDeletingAlbum, isDeletingAll } = useSelector((state) => state?.albumDetails);

  let albumDetailsIndex = albumList?.findIndex((obj) => obj.id == albumId)

  let albumDetails = albumDetailsIndex ? albumList[albumDetailsIndex] : [];


  const handleDelete = async (type) => {
    let image_id = selectedAlbumImage?.selectedImage;
    let album_id = albumId;

    const resultAction = await dispatch(
      deleteAlbumImages({ image_id, album_id, type })
    );
    if (deleteAlbumImages.fulfilled.match(resultAction)) {
      dispatch(removeAlbumImagesFromState(image_id));

      if (type == 'permanent') {
        dispatch(removeImageFromState({ image_id }))
      }
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getAlbums(obj));

      toast.success("Images deleted !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setDeleteModal({ popup: false });
    }
  };

  const handleClose = () => {
    setDeleteModal({ popup: false })
    dispatch(clearImageAlbumState())
  }

  return {
    selectedAlbumImage,
    handleDelete,
    albumDetails,
    handleClose,
    isDeletingAlbum,
    isDeletingAll
  };
};
