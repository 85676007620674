import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

var allowedExtensions = new RegExp(
  /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/
);

const initialState = {
  openMiniBox: false,
  openAlbumUploadModal: {
    isOpen: false,
  },
  openUploadModal: {
    isOpen: false,
    mode: "",
  },
  // openUploadModal: false,
  allUploadedImagesStatus: "idle",
  allUploadedImages: [],
  files: [],
  images: [],
  duplicateImages: [],
  invalidImages: [],
  isUploading: false,
};

export const getAllImages = createAsyncThunk(
  "fileUpload/getAllImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("list-uploaded-images", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    openMiniUploadBox: (state, action) => {
      state.openMiniBox = action.payload;
    },
    openUploadModalAction: (state, action) => {
      // state.openUploadModal = action.payload;
      state.openUploadModal = {
        isOpen: action.payload.data,
        mode: action.payload.path,
      };
    },
    openAlbumUploadModalAction: (state, action) => {
      state.openAlbumUploadModal = {
        isOpen: action.payload
      };
    },
    updateAllUploadedImages: (state, action) => {
      state.allUploadedImages = [
        ...state.allUploadedImages,
        {
          id: action.payload?.uploadResponse?.data?.result?.id,
          name: action.payload?.uploadResponse?.data?.result?.original_name,
          height: action.payload?.uploadResponse?.data?.result?.height,
          width: action.payload?.uploadResponse?.data?.result?.width,
        },
      ];
    },

    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getAllImages.pending]: (state) => {
      state.allUploadedImagesStatus = "pending";
    },
    [getAllImages.fulfilled]: (state, action) => {
      state.allUploadedImages = action.payload.data.result;
      state.allUploadedImagesStatus = "success";
    },
    [getAllImages.rejected]: (state) => {
      state.allUploadedImagesStatus = "failed";
    },
  },
});

export const {
  openMiniUploadBox,
  openUploadModalAction,
  setImages,
  setFiles,
  addDuplicate,
  addCompletedImages,
  setDuplicatesImages,
  setInvalidImages,
  updateConfig,
  updateAllUploadedImages,
  openAlbumUploadModalAction
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
