import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  albumList,
  addAlbum,
  createAlbum,
  removeAlbum,
  editAlbum,
  removeAlbumFromState,
  clearAlbumState,
  saveAlbum,
  frameList,
  getAlbumItem,
  addImages,
  deleteImageAction,
  deleteMultipleImages,
  callFunction,
  addAlbumImageHandler,
  deleteAlbumImages,
  removeAlbumImagesFromState,
  deleteMultipleAlbumsListItemsfromState,
  reArrangeAlbum,
  reArrangeAlbumImage,
  reArrangeAlbumApi,
  reArrangedAlbumItems,
  albumFilterItem,
  selectAllAlbumImages,
  unSelectAllAlbumImages,
  openAlbumCreateModalAction,
  isAlbumCreateAction,
  reArrangeAlbumItemImages,
  reArrangeAlbumItemsState,
  customFetchAlbumImages,
} from "../store/slices/gallerySlice";
import { toast } from "react-toastify";
import { Routes, Route, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Icons from "../components/Layouts/Icons";

const useAlbum = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [show, setShow] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [albumNameAtAll, setAlbumNameAtAll] = useState("");
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [albumCreateView, setAlbumCreateView] = useState(false);
  // const [isAlbumCreating, setIsAlbumCreating] = useState(false);

  const [albumOptionDetails, setAlbumOptionDetails] = useState({
    text: "Select All",
    icon: <Icons icon={"action-select"} size={17} />,
  });

  const [albumOptionForSingleItem, setAlbumOptionForSingleItem] = useState({
    text: "Select All",
    icon: <Icons icon={"action-select"} size={17} />,
  });

  let navigate = useNavigate();

  const dispatch = useDispatch();
  let { albumId } = useParams();
  const {
    sections,
    currentTab,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    isAlbumEditing,
    frameListStatus,
    frames,
    selectedAlbum,
  } = useSelector((state) => state.gallery);

  const { default_album_id } = useSelector(
    (state) => state.userprofile.userdata
  );

  const textChangeHandlerForAlbum = () => {
    setAlbumOptionDetails((prevState) => {
      if (prevState.text == "Select All") {
        selectAllAlbumListHandler();
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      } else {
        unSelectAllAlbumListHandler();
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      }
    });
  };

  useEffect(() => {
    // let allImgIds = sections[currentTab]?.data?.map((item, index) => item?.id);
    let albumIds = sections[currentTab]?.data
      ?.filter((item, index) => item.id !== default_album_id)
      ?.map((item) => item.id);
    if (
      sections[currentTab]?.selectedAlbumListItems?.length > 0 &&
      sections[currentTab]?.selectedAlbumListItems?.length < albumIds?.length
    ) {
      setAlbumOptionDetails((prevState) => {
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    } else if (
      sections[currentTab]?.selectedAlbumListItems?.length > 0 &&
      sections[currentTab]?.selectedAlbumListItems?.length == albumIds?.length
    ) {
      setAlbumOptionDetails((prevState) => {
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    }
  }, [sections[currentTab]?.selectedAlbumListItems]);

  const albumtextChangeHandler = () => {
    setAlbumOptionForSingleItem((prevState) => {
      if (prevState.text == "Select All") {
        selectAllAlbumImageHandler();
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      } else {
        unSelectAllAlbumImagesHandler();
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      }
    });
  };

  useEffect(() => {
    let allImgIds = sections?.album?.list?.[albumId]?.images?.map(
      (item, index) => item.id
    );

    if (
      sections?.album?.selectedAlbumImages?.length > 0 &&
      sections?.album?.selectedAlbumImages?.length < allImgIds?.length
    ) {
      setAlbumOptionForSingleItem((prevState) => {
        return {
          text: "Select All",
          icon: <Icons icon={"action-select"} size={17} />,
        };
      });
    } else if (
      sections?.album?.selectedAlbumImages?.length > 0 &&
      sections?.album?.selectedAlbumImages?.length == allImgIds?.length
    ) {
      setAlbumOptionForSingleItem((prevState) => {
        return {
          text: "Deselect All",
          icon: <Icons icon={"action-unselect"} size={17} />,
        };
      });
    }
  }, [sections?.album?.selectedAlbumImages]);

  const getFrameList = () => {
    if (frames?.length == 0) {
      dispatch(frameList());
    }
  };

  const getAlbumsList = () => {
    let obj = {
      page: "1",
      slug: "",
    };

    // if (sections["album"]?.data == undefined) {
    dispatch(albumList(obj));
    // }
  };

  const addNewAlbum = () => {
    dispatch(createAlbum({ image_id: sections[currentTab].selectedImages }));
  };

  const openSingleAlbumDeleteModal = () => {
    const openSingleAlbumDelete = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openSingleAlbumModal: true,
        },
      };
    };
    dispatch(callFunction(openSingleAlbumDelete));
  };

  const closeSingleAlbumDeleteModal = () => {
    const clsoeSingleAlbumDelete = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openSingleAlbumModal: false,
        },
      };
    };
    dispatch(callFunction(clsoeSingleAlbumDelete));
  };

  const handleRemoveAlbum = async (id) => {
    let album_id = [id];
    const resultAction = await dispatch(removeAlbum({ album_id }));
    if (removeAlbum.fulfilled.match(resultAction)) {
      toast.success("Album deleted !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(deleteImageAction(id));
      closeSingleAlbumDeleteModal();
    }
  };

  const handleDeleteAlbumListItem = async () => {
    let album_id = sections[currentTab].selectedAlbumListItems;
    const resultAction = await dispatch(removeAlbum({ album_id }));
    if (removeAlbum.fulfilled.match(resultAction)) {
      toast.success("Album deleted !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(deleteMultipleAlbumsListItemsfromState());
      closeSingleAlbumDeleteModal();
    }
  };

  const multiDeleteAlbumImages = async (type) => {
    let image_id = sections[currentTab].selectedAlbumImages;
    let album_id = albumId;

    const resultAction = await dispatch(
      deleteAlbumImages({ image_id, album_id, type })
    );
    if (deleteAlbumImages.fulfilled.match(resultAction)) {
      dispatch(removeAlbumImagesFromState());

      toast.success("Images deleted !", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const closeAlbumModalAfterSuccess = (state) => {
        state.sections.album = {
          ...state.sections.album,
          currentActions: {
            ...state.sections.album.currentActions,
            albumImageDeleteModal: false,
            preSlectionAlbumItem: false,
          },
          selectedAlbumImages: [],
        };
      };
      dispatch(callFunction(closeAlbumModalAfterSuccess));
    }
  };

  const unSelectAllAlbumListHandler = () => {
    const closeAlbumModalAfterSuccess = (state) => {
      state.sections.album = {
        ...state.sections.album,
        selectedAlbumListItems: [...new Array(0).keys()],
      };
    };
    dispatch(callFunction(closeAlbumModalAfterSuccess));
  };

  const selectAllAlbumListHandler = () => {
    let albumIds = sections[currentTab]?.data
      ?.filter((item, index) => item.id !== default_album_id)
      ?.map((item) => item.id);
    const closeAlbumModalAfterSuccess = (state) => {
      state.sections.album = {
        ...state.sections.album,
        selectedAlbumListItems: albumIds,
      };
    };
    dispatch(callFunction(closeAlbumModalAfterSuccess));
  };

  const handleEditAlbum = async (item, albumItem) => {

    let data = {
      album_id: item?.id,
      image_id: albumItem.updatedImages,
    };

    let albumImages = sections[currentTab]?.data
      ?.filter((item, index) => albumItem.duplicatedItems.includes(item.id))

    if (albumItem?.duplicatedItems?.length == 1 && sections[currentTab]?.selectedImages?.length == 1) {
      toast.warning(`${albumImages[0]?.original_name} is already exists`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const resultAction = await dispatch(editAlbum(data));
      if (editAlbum.fulfilled.match(resultAction)) {
        if (albumItem?.duplicatedItems?.length >= 1) {
          toast.warning(`removed ${albumItem?.duplicatedItems?.length} duplicated images`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.success("Photos added to album!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setOpenAlbumModal(false);
        setAlbumCreateView(false);
        dispatch(openAlbumCreateModalAction(false));
        dispatch(isAlbumCreateAction(false));
        setAlbumNameAtAll("");
        navigate(`album`);
      } else {
        // console.log(resultAction.payload, "fail...");
      }
    }

  };

  const handleClearAlbumState = () => {
    dispatch(clearAlbumState());
  };

  const handleSaveActions = async (e, data, coverForCreate) => {
    e.preventDefault();
    let newData = {
      title: data?.title,
      description: data?.description,
      frame_id: data?.frame_id,
      cover_photo: data?.src?.thumbnail ? data?.src?.thumbnail : "",
      frameClass: data?.frameClass,
      cropValue: data?.crop_value ? data.crop_value : 0,
      album_id: data?.id,
    };

    const resultAction = await dispatch(editAlbum(newData));

    if (editAlbum.fulfilled.match(resultAction)) {
      handleOpenAlbumClose();
    } else {
      // console.log(resultAction.payload, "fail...");
    }
  };

  const getAlbum = (id) => {
    let obj = {
      page: "1",
      slug: "",
      id: id,
    };
    dispatch(getAlbumItem(obj));
  };

  const loadMoreAlbumItem = () => {
    // console.log(
    //   sections.album.list[selectedAlbum]?.current_page <
    //   sections.album.list[selectedAlbum]?.last_page,
    //   "load more.....album item"
    // );
    if (
      sections.album.list[selectedAlbum]?.current_page <
      sections.album.list[selectedAlbum]?.last_page
    ) {
      let obj = {
        id: albumId,
        page: sections.album.list[selectedAlbum]?.current_page + 1,
        slug: sections.album.list[selectedAlbum]?.currentActions?.filterOption
          ? sections.album.list[selectedAlbum]?.currentActions?.filterOption
          : "",
      };
      dispatch(customFetchAlbumImages(obj));
    }
  };


  const handleFilterAlbum = (slug) => {
    // console.log("image fiter album...");
    let obj = {
      url: "album-view",
      page: 1,
      slug: slug,
      id: albumId,
    };
    dispatch(albumFilterItem(obj));
  };


  const selectAlbumItems = (id) => {
    dispatch(addImages(id));
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChangeAlbumName = (e) => {
    setAlbumName(e.target.value);
  };

  const handleChangeAlbumAllName = (e) => {
    setAlbumNameAtAll(e.target.value);
  };

  const handleCreateAlbumWithNameAndImage = async (e) => {
    e.preventDefault();
    let newData = {
      title: albumNameAtAll,
      image_id: sections[currentTab].selectedImages,
    };

    const resultAction = await dispatch(createAlbum(newData));
    if (createAlbum.fulfilled.match(resultAction)) {
      toast.success("Album Created!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpenAlbumModal(false);
      setAlbumCreateView(false);
      dispatch(openAlbumCreateModalAction(false));
      dispatch(isAlbumCreateAction(false));
      setAlbumNameAtAll("");
      navigate(`album`);
    } else {
      // console.log(resultAction.payload, "fail...");
    }
  };

  const handleCreateAlbumWithName = async (e) => {
    e.preventDefault();
    let newData = {
      title: albumName,
    };
    const resultAction = await dispatch(createAlbum(newData));
    if (createAlbum.fulfilled.match(resultAction)) {
      toast.success("Album Created!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseAddNewAlbum();
    } else {
      // console.log(resultAction.payload, "fail...");
    }
  };

  const handleOpenGallery = () => {
    const albumGalleryAction = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          imageGalleryOpen: true,
          preselect: true,
        },
      };
    };
    dispatch(callFunction(albumGalleryAction));
  };

  const handleOpenAlbumModal = () => {
    const albumOpenAction = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openAlbumModal: true,
        },
      };
    };
    dispatch(callFunction(albumOpenAction));
  };
  const handleOpenAlbumClose = () => {
    const albumCloseAction = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openAlbumModal: false,
        },
      };
    };
    dispatch(callFunction(albumCloseAction));
  };

  const handleAddNewAlbum = () => {
    const addNewAlbumModal = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state?.sections?.album?.currentActions,
          openAddNewAlbumModal: true,
        },
      };
    };
    dispatch(callFunction(addNewAlbumModal));
  };

  const handleCloseAddNewAlbum = () => {
    const closeAddNewAlbumModal = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openAddNewAlbumModal: false,
        },
      };
    };
    dispatch(callFunction(closeAddNewAlbumModal));
  };

  let filteredImages = [];
  const addImageToAlbumState = async () => {
    let selectedImages = sections[currentTab].selectedImages;
    let data = {
      album_id: albumId,
      image_id: sections[currentTab].selectedImages,
    };



    filteredImages = sections["all"]?.data?.filter((d) => {
      return selectedImages?.includes(d?.id);
    });

    // console.log(filteredImages?.length);
    if (filteredImages?.length > 0) {
      const addImageToAlbum = (state) => {
        state.sections.album = {
          ...state.sections.album,
          ...(state.sections.album.list[selectedAlbum] = {
            ...state.sections.album.list[selectedAlbum],
            images: filteredImages,
            total: filteredImages?.length

          }),
        };
      };

      dispatch(callFunction(addImageToAlbum));

      dispatch(editAlbum(data))
        .unwrap()
        .then((res) => {
          toast.success("Image Added!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          const albumCloseGalleryAction = (state) => {
            state.sections.album = {
              ...state.sections.album,
              currentActions: {
                ...state.sections.album.currentActions,
                imageGalleryOpen: false,
                preselect: false,
              },
            };
          };
          dispatch(callFunction(albumCloseGalleryAction));
        });
    }
  };

  const handleDeleteAlbumImageAction = () => {
    // console.log("handle delete...");
  };

  const addImageHandlerForAlbum = (imgId) => {
    dispatch(addAlbumImageHandler(imgId));
  };

  const selectAllAlbumImageHandler = () => {
    let imgIds = sections[currentTab].list[albumId]?.images?.map(
      (item, index) => item.id
    );
    dispatch(selectAllAlbumImages(imgIds));
  };

  const unSelectAllAlbumImagesHandler = () => {
    dispatch(unSelectAllAlbumImages());
  };

  const handleReArrangeAlbum = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
        album_id: albumId,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
        album_id: albumId,
      },
    ];

    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrangeAlbum({ data: obj }));
    }

    dispatch(
      reArrangeAlbumImage({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const handleReArrangeAlbumItems = async (data, draggedItem, changedItem) => {
    let obj = [
      {
        album_id: draggedItem?.id,
        position: changedItem?.position,
      },
      {
        album_id: changedItem?.id,
        position: draggedItem?.position,
      },
    ];

    if (changedItem?.position !== draggedItem?.position) {
      await dispatch(reArrangeAlbumApi({ data: obj }));
    }

    dispatch(
      reArrangeAlbumItemsState({
      data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const handlePreSelectionAlbumItem = () => {
    const preSelectionAlbumItem = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          preSlectionAlbumItem: true,
        },
      };
    };
    dispatch(callFunction(preSelectionAlbumItem));
  };

  const handleAlbumPreSelection = () => {
    const openPreSelectionAlbumItem = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          openAlbumItemPreSelection: true,
        },
      };
    };
    dispatch(callFunction(openPreSelectionAlbumItem));
  };

  const closeAlbumListActions = () => {
    const closeAlbumList = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {},
        selectedAlbumListItems: [],
      };
    };
    dispatch(callFunction(closeAlbumList));
  };



  const handleOpenImportImages = () => {
    let prevItemIds = sections.album.list[albumId]?.images?.map(
      (item) => item?.id
    );
    const addImageToAlbumAction = (state) => {
      state.sections.album = {
        ...state.sections.album,
        currentActions: {
          ...state.sections.album.currentActions,
          imageGalleryOpen: true,
          preselect: true,
        },
        selectedImages: prevItemIds,
      };
    };
    dispatch(callFunction(addImageToAlbumAction));
  };

  return {
    openAlbumModal,
    setOpenAlbumModal,
    albumNameAtAll,
    setAlbumNameAtAll,
    albumCreateView,
    setAlbumCreateView,
    addNewAlbum,
    getAlbumsList,
    albumListStatus,
    isAlbumCreating,
    isAddtoToAlbum,
    isAlbumEditing,
    handleRemoveAlbum,
    sections,
    currentTab,
    albumData: sections["album"]?.data,
    handleEditAlbum,
    handleClearAlbumState,
    openEditModal,
    setOpenEditModal,
    getAlbum,
    frames,
    selectAlbumItems,
    getFrameList,
    handleShow,
    show,
    handleClose,
    handleChangeAlbumName,
    handleCreateAlbumWithName,
    handleOpenGallery,
    albumName,
    galleryState: sections[currentTab],
    handleOpenAlbumModal,
    handleOpenAlbumClose,
    handleSaveActions,
    handleAddNewAlbum,
    handleCloseAddNewAlbum,
    addImageToAlbumState,
    handleDeleteAlbumImageAction,
    multiDeleteAlbumImages,
    handleDeleteAlbumListItem,
    openSingleAlbumDeleteModal,
    closeSingleAlbumDeleteModal,
    unSelectAllAlbumListHandler,
    selectAllAlbumListHandler,
    handleReArrangeAlbum,
    handlePreSelectionAlbumItem,
    handleAlbumPreSelection,
    closeAlbumListActions,
    handleFilterAlbum,
    addImageHandlerForAlbum,
    selectAllAlbumImageHandler,
    unSelectAllAlbumImagesHandler,
    handleCreateAlbumWithNameAndImage,
    handleChangeAlbumAllName,
    textChangeHandlerForAlbum,
    albumOptionDetails,
    setAlbumOptionDetails,
    albumtextChangeHandler,
    albumOptionForSingleItem,
    setAlbumOptionForSingleItem,
    handleOpenImportImages,
    handleReArrangeAlbumItems,
    loadMoreAlbumItem,
  };
};

export default useAlbum;
