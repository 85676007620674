import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Style from "./RightFilteredCategory.module.scss";
import Icons from "../../../Layouts/Icons";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import "swiper/css";
import {
  openMiniUploadBox,
  openUploadModalAction,
} from "../../../../store/slices/Gallery/fileUploadSlce";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useRightFilteredCategory from "./useRightFilteredCategory";
import AddToAlbumModal from "./AddToAlbumModal";
import DeleteImageModal from "./DeleteImageModal";
import CreateSlideShowModal from "./CreateSlideShowModal";
import DeleteAlbumModal from "./DeleteAlbumModal";
import DeleteAlbumImageModal from "./DeleteAlbumImageModal";
import DeleteSlideShowModal from "./DeleteSlideShowModal";
import { useDimensions } from "../../../../Logic/Dimensions";
import DeleteTrashImageModal from "./DeleteTrashImageModal";

const RightFilteredCategory = ({ currentPath }) => {
  const dispatch = useDispatch();
  let { albumId } = useParams();

  //FIXME

  const {
    sections,
    currentTab,
    openUploadModal,
    gridView,
    openAlbumCreateModal,
    isCreateAlbumModal,
    isAlbumImageDeleting,
  } = useSelector((state) => state.gallery);
  const windowDimensions = useDimensions();
  const Images = useSelector((state) => state.galleryAllTab);
  const Trash = useSelector((state) => state.trash);

  // const Image

  const [isDesktop, setIsDesktop] = useState(windowDimensions.width >= 1420);

  const {
    filterlebel,
    sortOptions,
    sectionData,

    selections,
    selectAll,

    handleSelectClick,
    handleCloseSelection,
    handleSelectAll,
    handleClickFilter,
    handleSwtichGridView,
    addToAlbum,
    setAddToAlbum,
    deleteModal,
    setDeleteModal,
    slideshowCreateModal,
    setSlideshowCreateModal,
    handleSlideShowImageSelection,
    handleOpenQuickSlideShow,
    handleAddToArchive,
    handleUnArchive,
    handleRestore,
    addSlideShowHandler,
    deleteAlbumModal,
    setDeleteAlbumModal,
    handleClickAddToProfileGallery,
    handleClickProfileGallery,
    deleteAlbumImageModal,
    setDeleteAlbumImageModal,
    handleOpenReArrange,
    handleCloseReArrange,
    handleOpenAddPhotosToAlbum,
    handleSelectAllAddToAlbum,
    handleCloseAddPhotosToAlbum,
    saveAlbumImages,
    deleteSlideShowModal,
    setDeleteSlideShowModal,
    currentTabData,
    getSelectedData,
    trashDeleteModal,
    setTrashDeleteModal,
  } = useRightFilteredCategory(currentPath);

  const CreateSlideShowBtn = () => {
    return (
      <button
        onClick={() => setSlideshowCreateModal({ popup: true })}
        className={`a-02 ${Style.dashboard_btn} ${Style.add_new_btn
          } ${"btn btn-border-secondary"}`}
      >
        <Icons icon={"add"} size={12} />
        {isDesktop ? "Create slideshow" : "Slideshow"}
      </button>
    );
  };

  const GridViewBtn = () => {
    return (
      <button
        className={`${Style.dashboard_btn}  ${"btn btn-border-grey"}`}
        type="button"
        onClick={() => handleSwtichGridView(selections?.ImageAlbumTab?.grid)}
      >
        {selections?.ImageAlbumTab?.grid ? (
          <>
            <Icons
              className={windowDimensions.width < 1500 ? "me-0" : ""}
              icon={"icon-expand-view"}
              size={17}
            />
            {windowDimensions.width >= 1500 && "Expand"}
            {windowDimensions.width >= 1800 && " View"}
          </>
        ) : (
          <>
            <Icons icon={"icon-grid-view"} size={17} />
            Grid View
          </>
        )}
      </button>
    );
  };

  const ProfileGallerySelectionBtn = () => {
    return (
      <button
        onClick={() => handleClickProfileGallery()}
        className={`a-01 ${Style.dashboard_btn} ${"btn btn-border-secondary"}`}
      >
        <Icons
          className={!isDesktop ? "me-0 top-1" : ""}
          icon={"star-empty"}
          size={16}
        />
        {isDesktop && "Add to Profile Gallery"}
      </button>
    );
  };

  const AddToProfileGalleryBtn = () => {
    return (
      <>
        <button
          onClick={() => handleClickAddToProfileGallery()}
          className={`a-01 ${Style.dashboard_btn} ${"btn btn-primary"}`}
        >
          Continue
        </button>
        <CloseBtn />
      </>
    );
  };

  const SortMenuBtn = ({ data, label }) => {
    //FIXME add option of position (re-arrange)
    return (
      <>
        {/* <Dropdown
        className={`a01 ${Style.dashboard_dropdown} ${Style.dashboard_btn}`}
      >
        <Dropdown.Toggle
          className={`a02 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        >
          <div className={Style.dashboard_dropdown_label}>{label}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {data?.map((item, i) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  handleClickFilter(item);
                }}
                key={i}
              >
                {item?.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown> */}









        <div className="dropdown">
          <button className={`btn btn-border-grey ${Style.Dropdown_toggle_btn}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {label} <Icons
              icon={"icon-dropdown-arrow"}
              color={'currentColor'}
              size={10}
            />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {data?.map((item, i) => {
              return (
                <li key={i}>
                  <a
                    className="dropdown-item"
                    href="#"
                    // key={i}
                    onClick={() => {
                      handleClickFilter(item);
                    }}
                  >{item?.name}</a>
                </li>
              )
            })}
          </ul>
        </div>



      </>
    );
  };

  const AddImageSectionBtn = ({ path }) => {
    return (
      <>
        <button
          className={`a04 ${Style.add_btn} ${Style.dashboard_btn
            } ${"btn btn-border-secondary"}`}
          onClick={() => {
            dispatch(openUploadModalAction({ data: true, path: path }));
          }}
        >
          <Icons className={!isDesktop ? "me-0" : ""} icon={"add"} size={12} />
          {isDesktop && "Upload photos"}
        </button>
      </>
    );
  };

  const SelectBtn = () => {
    // ${ Style.selection_btn_loader}
    const getStatusTab = (path) => {
      let isActive = false
      if (path == "") {
        isActive = true
      } else if (path == "trash") {
        isActive = true
      }

      return isActive;
    }


    const getStatus = (path) => {
      let status = "loading"
      if (path == "") {
        status = Images?.allPageStatus
      } else if (path == "trash") {
        status = Trash?.trashPageStatus
      }
      return status
    }


    return (
      <>
        {getStatusTab(currentPath) ? (
          <button
            disabled={sectionData?.current_page !== sectionData?.last_page}
            className={`a03 ${Style.dashboard_btn} ${"btn btn-border-grey"} 
              ${getStatus(currentPath) == "loading" ? Style.selection_btn_loader : ""}
             `}
            style={{ width: "100px" }}
            onClick={() => handleSelectClick()}
          >
            {sectionData?.current_page == sectionData?.last_page &&
              <>
                <Icons
                  className={!isDesktop ? "me-0" : ""}
                  icon={"icon-square"}
                  size={17}
                />
                {isDesktop && t("select")}
              </>
            }
          </button>
        ) : (
          <button
            className={`a03 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
            onClick={() => handleSelectClick()}
          >
            <Icons
              className={!isDesktop ? "me-0" : ""}
              icon={"icon-square"}
              size={17}
            />
            {isDesktop && t("select")}
          </button>
        )}
      </>
    );
  };

  const CloseBtn = () => {
    return (
      <button
        onClick={handleCloseSelection}
        className={Style.dashboard_actions_close}
      >
        <Icons icon={"icon-close"} size={14} />
      </button>
    );
  };

  const SelectAllBtn = () => {
    return (
      <button
        className={`a-05 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        type="button"
        onClick={() => handleSelectAll()}
      >
        {getSelectedData(currentPath)?.length == currentTabData?.length ? (
          <>
            <Icons icon={"action-unselect"} size={17} className={windowDimensions.width < 576 ? "me-0" : ""} />
            {windowDimensions.width >= 1800
              ? "Deselect All"
              : windowDimensions.width >= 576
                ? "All"
                : ""}
          </>
        ) : (
          <>
            <Icons icon={"action-select"} size={17} className={windowDimensions.width < 576 ? "me-0" : ""} />
            {windowDimensions.width >= 1800
              ? "Select All"
              : windowDimensions.width >= 576
                ? "All"
                : ""}
          </>
        )}
      </button>
    );
  };

  const CreateAlbumBtn = ({ type }) => {
    return (
      <button
        className={`${Style.dashboard_btn} ${Style.add_new_btn
          } ${"btn btn-border-secondary"}`}
        type="button"
        onClick={() => setAddToAlbum({ popup: true, type })}
      >
        {type == "create" ? (
          <>
            <Icons
              className={windowDimensions.width < 576 ? "me-0" : ""}
              icon={"add"}
              size={12}
            />
            {windowDimensions.width >= 1800
              ? "Create Album"
              : windowDimensions.width >= 576
                ? "Album"
                : ""}
          </>
        ) : (
          <>
            <Icons 
              icon={"add-to-album"}
              className={windowDimensions.width < 576 ? "me-0" : ""}
              size={18} />
            
            {windowDimensions.width >= 1800
              ? "Add to Albums"
              : windowDimensions.width >= 576
                ? "Add"
                : ""}
          </>
        )}
      </button>
    );
  };

  const SelectAllAlbum = ({ data }) => {
    return (
      <>
        <SelectAllBtn />
        {data?.length > 0 && <DeleteAlbumBtn />}

        <CloseBtn />
      </>
    );
  };

  const SelectAllContainer = () => {
    return (
      <>
        <SelectAllBtn />

        <Dropdown className={Style.dashboard_actions}>
          <Dropdown.Toggle className={Style.dashboard_actions_btn}>
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={Style.dashboard_select_dropdown}>
            <Dropdown.Item
            // onClick={() => handleClickArchive()}
            >
              {/* FIXME currentTab is old code*/}
              {currentTab == "archive" ? "Unarchive" : "Archive"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <CloseBtn />
      </>
    );
  };

  const SelectAllSlideShowContainer = () => {
    return (
      <>
        <SelectAllBtn />
        <CloseBtn />
      </>
    );
  };

  const OthersAllContainer = () => {
    return (
      <>
        <SelectAllBtn />
        <CloseBtn />
      </>
    );
  };

  const SelectAllArchive = () => {
    return (
      <>
        <SelectAllBtn />
        <button
          onClick={() => handleUnArchive()}
          className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
            } ${"btn btn-primary"}`}
          type="button"
        >
          Unarchive
        </button>
        <CloseBtn />
      </>
    );
  };

  const SelectAllTrash = () => {
    return (
      <>
        <SelectAllBtn />
        <button
          onClick={() => handleRestore()}
          className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
            } ${"btn btn-primary"}`}
          type="button"
        >
          Restore
        </button>
        <button
          onClick={() =>
            setTrashDeleteModal({ popup: !trashDeleteModal.popup })
          }
          className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
            } ${"btn btn-primary"}`}
          type="button"
        >
          Delete
        </button>
        <CloseBtn />
      </>
    );
  };

  const DeleteAlbumImageBtn = () => {
    return (
      <button
        onClick={() =>
          setDeleteAlbumImageModal({ popup: !deleteAlbumImageModal.popup })
        }
        className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
          } ${"btn btn-primary"}`}
        type="button"
      >
        Delete
      </button>
    );
  };

  const DeleteBtn = () => {
    return (
      <button
        onClick={() => setDeleteModal({ popup: !deleteModal.popup })}
        className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
          } ${"btn btn-primary"}`}
        type="button"
      >
        Delete
      </button>
    );
  };

  const DeleteAlbumBtn = () => {
    return (
      <button
        onClick={() => setDeleteAlbumModal({ popup: !deleteAlbumModal.popup })}
        className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
          } ${"btn btn-primary"}`}
        type="button"
      >
        Delete
      </button>
    );
  };

  const SelectAllActionBtn = () => {
    return (
      <>
        <button
          // disabled={sections[currentTab]?.selectedImages?.length === 0}
          onClick={() => handleOpenQuickSlideShow()}
          className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        >
          <Icons icon={"slideshow"} size={18} className={windowDimensions.width < 576 ? "me-0" : ""}/>
          {windowDimensions.width >= 1800
            ? "Slideshow"
            : windowDimensions.width >= 576
              ? "Slideshow"
              : ""}
        </button>
        <CreateAlbumBtn type="add" />
        <SelectAllBtn />
        <DeleteBtn />

        <Dropdown className={Style.dashboard_actions}>
          <Dropdown.Toggle className={Style.dashboard_actions_btn}>
            <div className={Style.dashboard_actions_btn_label}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={Style.dashboard_select_dropdown}>
            <Dropdown.Item onClick={() => handleAddToArchive()}>
              {"Archive"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <CloseBtn />
      </>
    );
  };

  const SelectAllSlideShowOptions = () => {
    return (
      <>
        <SelectAllBtn />
        <button
          onClick={() =>
            setDeleteSlideShowModal({ popup: !deleteSlideShowModal.popup })
          }
          className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
            } ${"btn btn-primary"}`}
          type="button"
        >
          Delete
        </button>
        <CloseBtn />
      </>
    );
  };

  const SlideshowImageSelection = () => {
    return (
      <>
        <button
          disabled={
            selections?.AddSlideShowSelection?.selectedImage?.length == 0
          }
          onClick={() => addSlideShowHandler()}
          // onClick={() => setDeleteModal({ popup: !deleteModal.popup })}
          className={`a-04 ${Style.dashboard_btn} ${Style.rearrange_cancel_btn
            } ${"btn btn-primary"}`}
          type="button"
        >
          Add
        </button>
        <button
          onClick={handleSlideShowImageSelection}
          className={Style.dashboard_actions_close}
        >
          <Icons icon={"icon-close"} size={14} />
        </button>
      </>
    );
  };

  const ReArrangeBtn = ({ path }) => {
    return (
      <button
        className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        onClick={() => handleOpenReArrange(path)}
      >
        <Icons
          className={windowDimensions.width < 1500 ? "me-0" : ""}
          icon={"icon-grid"}
          size={17}
        />
        {windowDimensions.width >= 1500 && "Rearrange"}
      </button>
    );
  };

  const RearrangeDoneBtn = ({ path }) => {
    return (
      <button
        className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        onClick={() => handleCloseReArrange(path)}
      >
        Close
      </button>
    );
  };

  const AddPhotosBtn = () => {
    return (
      <button
        className={`${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        onClick={() => handleOpenAddPhotosToAlbum()}
      >
        Add Photos
      </button>
    );
  };

  const SelectAllAddToAlbumBtn = () => {
    let ImageIds = Images?.resultData?.data?.map((obj) => obj.id);

    return (
      <button
        className={`a-05 ${Style.dashboard_btn} ${"btn btn-border-grey"}`}
        type="button"
        onClick={() => handleSelectAllAddToAlbum()}
      >
        {selections.ImageAlbumAddToTab.selectedImage?.length ==
          ImageIds?.length ? (
          <>
            <Icons icon={"action-unselect"} size={17} className={windowDimensions.width < 576 ? "me-0" : ""} />
            {windowDimensions.width >= 1800
              ? "Deselect All"
              : windowDimensions.width >= 576
                ? "Deselect"
                : ""}
          </>
        ) : (
          <>
            <Icons icon={"action-select"} size={17} className={windowDimensions.width < 576 ? "me-0" : ""}/>
            {windowDimensions.width >= 1800
              ? "Select All"
              : windowDimensions.width >= 576
                ? "All"
                : ""}
          </>
        )}
      </button>
    );
  };

  const AlbumFilters = () => {
    return (
      <>
        {selections?.ImageAlbumTab?.selction ? (
          <SelectAllAlbum data={selections?.ImageAlbumTab?.selectedImage} />
        ) : selections?.ImageAlbumTab?.isRearrange ? (
          <RearrangeDoneBtn path={"album"} />
        ) : (
          <>
            <CreateAlbumBtn type="create" />
            <SortMenuBtn data={sortOptions} label={filterlebel} />
            {selections?.ImageAlbumTab?.grid && <ReArrangeBtn path={"album"} />}
            <GridViewBtn />
            <SelectBtn />
          </>
        )}
      </>
    );
  };

  const SaveAlbumImageBtn = () => {
    return (
      <>
        <button
          onClick={() => saveAlbumImages()}
          className={`a-01 ${Style.dashboard_btn} ${"btn btn-primary"}`}
        >
          Continue
        </button>
      </>
    );
  };

  const AlbumAddToFilters = () => {
    return (
      <>
        <SelectAllAddToAlbumBtn />
        {selections?.ImageAlbumAddToTab?.selectedImage?.length > 0 && (
          <SaveAlbumImageBtn />
        )}
        <CloseAddToAlbumBtn />
      </>
    );
  };

  const CloseAddToAlbumBtn = () => {
    return (
      <button
        onClick={handleCloseAddPhotosToAlbum}
        className={Style.dashboard_actions_close}
      >
        <Icons icon={"icon-close"} size={14} />
      </button>
    );
  };

  const AlbumDetailsFilter = () => {
    return (
      <>
        {selections?.ImageAlbumAddToTab?.selction ? (
          <AlbumAddToFilters />
        ) : selections?.ImageAlbumDetailsTab?.isRearrange ? (
          <RearrangeDoneBtn path={`album${albumId}`} />
        ) : selections?.ImageAlbumDetailsTab?.selction ? (
          <>
            <SelectAllBtn />
            {selections?.ImageAlbumDetailsTab?.selectedImage?.length > 0 && (
              <DeleteAlbumImageBtn />
            )}
            <CloseBtn />
          </>
        ) : (
          <>
            <AddImageSectionBtn path={"album"} />
            <AddPhotosBtn />
            <ReArrangeBtn path={`album${albumId}`} />
            <SelectBtn />
            <SortMenuBtn data={sortOptions} label={filterlebel} />
          </>
        )}
      </>
    );
  };

  const listMenu = () => {
    switch (currentPath) {
      case "":
        return (
          <>
            {selections?.ImageAllTabSelection?.selction == true ? (
              selections?.ImageAllTabSelection?.selectedImage?.length > 0 ? (
                <SelectAllActionBtn />
              ) : (
                <SelectAllContainer />
              )
            ) : (
              currentTabData?.length > 0 && (
                <>
                  <AddImageSectionBtn path={"all"} />
                  <SortMenuBtn data={sortOptions} label={filterlebel} />
                  <SelectBtn />
                </>
              )
            )}
          </>
        );
      case "favorites":
        return (
          <>
            {selections?.ImageFavoriteTab?.selction != true ? (
              <>
                {" "}
                {currentTabData?.length > 0 && (
                  <>
                    <ProfileGallerySelectionBtn />
                    <SortMenuBtn data={sortOptions} label={filterlebel} />
                  </>
                )}
              </>
            ) : (
              <AddToProfileGalleryBtn />
            )}
          </>
        );
      case "album":
        return <AlbumFilters />;
      case "slideshow":
        return (
          <>
            {selections?.AddSlideShowSelection?.selction == true ? (
              <SlideshowImageSelection />
            ) : selections?.ImageSlideshowTab?.selction == true ? (
              selections?.ImageSlideshowTab?.selectedImage?.length > 0 ? (
                <SelectAllSlideShowOptions />
              ) : (
                <SelectAllSlideShowContainer />
              )
            ) : (
              <>
                <CreateSlideShowBtn />
                {currentTabData?.length > 0 && (
                  <>
                    <SortMenuBtn data={sortOptions} label={filterlebel} />
                    <SelectBtn />
                  </>
                )}
              </>
            )}
          </>
        );
      case "archive":
        return (
          <>
            {selections?.ImageArchiveTab?.selction == true ? (
              selections?.ImageArchiveTab?.selectedImage?.length > 0 ? (
                <SelectAllArchive />
              ) : (
                <>
                  <OthersAllContainer />
                </>
              )
            ) : (
              currentTabData?.length > 0 && (
                <>
                  <SortMenuBtn data={sortOptions} label={filterlebel} />
                  <SelectBtn />
                </>
              )
            )}
          </>
        );
      case "trash":
        return (
          <>
            {selections?.ImageTrashTab?.selction == true ? (
              selections?.ImageTrashTab?.selectedImage?.length > 0 ? (
                <SelectAllTrash />
              ) : (
                <>
                  <OthersAllContainer />
                </>
              )
            ) : (
              currentTabData?.length > 0 && (
                <>
                  <SortMenuBtn data={sortOptions} label={filterlebel} />
                  <SelectBtn />
                </>
              )
            )}
          </>
        );
      case `album${albumId}`:
        return (
          <>
            <AlbumDetailsFilter />
          </>
        );
    }
  };

  return (
    <>
      <div className={Style.right_filtered_category}>
        <div className={Style.right_filtered_category_options}>
          {listMenu()}
        </div>
      </div>
      <CreateSlideShowModal
        slideshowCreateModal={slideshowCreateModal}
        setSlideshowCreateModal={setSlideshowCreateModal}
      />
      <AddToAlbumModal addToAlbum={addToAlbum} setAddToAlbum={setAddToAlbum} />
      <DeleteImageModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        path={currentPath}
      />

      <DeleteSlideShowModal
        deleteModal={deleteSlideShowModal}
        setDeleteModal={setDeleteSlideShowModal}
        path={currentPath}
      />
      <DeleteAlbumModal
        deleteModal={deleteAlbumModal}
        setDeleteModal={setDeleteAlbumModal}
        path={currentPath}
      />

      <DeleteAlbumImageModal
        deleteModal={deleteAlbumImageModal}
        setDeleteModal={setDeleteAlbumImageModal}
        path={currentPath}
        albumId={albumId}
      />
      <DeleteTrashImageModal
        trashDeleteModal={trashDeleteModal}
        setTrashDeleteModal={setTrashDeleteModal}
        path={currentPath}
      />
    </>
  );

};

export default RightFilteredCategory;
