import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getFavoriteImages,
  LoadmoreImages,
} from "../../store/slices/favoriteSlice";
import { endTabSession } from "../../store/slices/GalleryTabSlice";
import { getStaredList } from "../../store/slices/ProfileGallery/profileGallerySlice";

export const useFavorites = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const Images = useSelector((state) => state?.favorite);
  const selections = useSelector((state) => state.galleryTab);
  const ProfileGallery = useSelector((state) => state.profileGallery);

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  useEffect(() => {
    if (Images?.status == "idle") {
      let obj = {
        page: "1",
        slug: "",
      };
      dispatch(getFavoriteImages(obj));
    }
    if (ProfileGallery?.status == "idle") {
      dispatch(getStaredList());
    }

    return () => {
      dispatch(endTabSession({ tab: currentPath }))
    }
  }, []);

  const loadMore = (slug, type) => {
    if (Images?.resultData?.current_page < Images?.resultData?.last_page) {
      let obj = {
        page: Images?.resultData?.current_page + 1,
        slug: selections?.ImageFavoriteTab?.sort,
      };
      dispatch(LoadmoreImages(obj));
    }
  };

  const NodataContent = {
    title: `Your favorites are empty`,
    description: `The favorites collections you create are shown here`,
    buttonText: ``,
  };

  return {
    Images,
    loadMore,
    NodataContent,
    status: Images.status
  };
};
