import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../api";

const initialState = {
  status: "idle",
  isOpen: false,
  paymentCancelStatus: "idle",
  selectedPlan: {},
  data: {
    isOpen: false,
    details: {},
  },
  hdfc_payment: {},
  hdfc_status: "idel",
};

export const inititatePayment = createAsyncThunk(
  "payment/InititatePayment",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/stripe`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const paymentCompletion = createAsyncThunk(
  "payment/paymentCompletion",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/stripe-payment", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const paymentCancellation = createAsyncThunk(
  "payment/paymentCancellation",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/stripe-payment-refund", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const hdfcPayment = createAsyncThunk(
  "payment/hdfcPayment",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/submit-ccavenue", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const checkoutSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    closeModal: (state, action) => {
      state.data = {};
    },
    paymentModalAction: (state, action) => {
      state.isOpen = action.payload?.isOpen;
      state.selectedPlan = action.payload?.item;
    },
  },

  extraReducers: {
    [inititatePayment.pending]: (state) => {
      state.status = "loading";
    },
    [inititatePayment.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = {
        isOpen: true,
        details: action.payload.data.result,
      };
    },
    [inititatePayment.rejected]: (state, action) => {
      state.status = "error";
    },

    [paymentCompletion.pending]: (state) => {
      state.paymentCompleteStatus = "loading";
    },
    [paymentCompletion.fulfilled]: (state, action) => {
      state.paymentCompleteStatus = "success";
      // console.log(action.payload, "ress completion");
    },
    [paymentCompletion.rejected]: (state, action) => {
      state.paymentCompleteStatus = "error";
    },

    [paymentCancellation.pending]: (state) => {
      state.paymentCancelStatus = "loading";
    },
    [paymentCancellation.fulfilled]: (state, action) => {
      state.paymentCancelStatus = "success";
      // console.log(action.payload, "ress completion");
    },
    [paymentCancellation.rejected]: (state, action) => {
      state.paymentCancelStatus = "error";
    },

    [hdfcPayment.pending]: (state) => {
      state.hdfc_status = "loading";
    },

    [hdfcPayment.fulfilled]: (state, action) => {
      state.hdfc_payment = action.payload.data.result;
      state.hdfc_status = "success";

    },
    [hdfcPayment.rejected]: (state, action) => {
      state.hdfc_status = "error";
    },
  },
});

export const { closeModal, paymentModalAction } = checkoutSlice.actions;

export default checkoutSlice.reducer;
