import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export const usePreview = ({
  showFullView,
  setShowFullView,
  setSlideCount,
}) => {
  const zoomRef = useRef(null);

  const [fullViewThumbs, setFullViewThumbs] = useState(null);
  const [fullView, setFullView] = useState(null);
  const [fullViewThumbIndex, setFullViewThumbIndex] = useState(
    showFullView.index
  );
  const [initialIndex, setInitialIndex] = useState(0);

  const [isZoom, setIsZoom] = useState(false);

  useLayoutEffect(() => {
    if (isZoom) {
      setSlideCount(fullViewThumbIndex);
      setShowFullView({
        ...showFullView,
        index: fullViewThumbIndex,
      });
    }
  }, [isZoom]);

  const handleSlideChange = (index) => {
    setFullViewThumbIndex(index);
    setInitialIndex(index);
    setShowFullView({
      ...showFullView,
      index: index,
    });
  };


  useEffect(() => {
    if (isZoom) {
      let elem = zoomRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
        document.addEventListener("fullscreenchange", revert);
      }
    }
    return () => {
      document.removeEventListener("fullscreenchange", revert);
    };
  }, [isZoom]);

  const handleZoom = () => {
    setIsZoom(!isZoom);
  };

  const revert = () => {
    if (!document.fullscreenElement) {
      if (isZoom) {
        setFullViewThumbs(false);
        setIsZoom(!isZoom);
      } else {
        setFullViewThumbs(true);
        setShowFullView({
          isOpen: false,
          index: fullViewThumbIndex,
        });
      }
      document.removeEventListener("fullscreenchange", revert);
    }
  };

  const closeButtonHandle = () => {
    if (isZoom) {
      setFullViewThumbs(false);
      setIsZoom(!isZoom);
    } else {
      setFullViewThumbs(true);
      setShowFullView({
        isOpen: false,
        index: fullViewThumbIndex,
      });
    }
    document.removeEventListener("fullscreenchange", revert);
  };

  const handleEscape = () => {
    setIsZoom(false);
    setFullViewThumbs(false);
    setShowFullView({});
  };

  return {
    zoomRef,
    fullViewThumbs,
    fullView,
    fullViewThumbIndex,
    isZoom,
    initialIndex,
    setInitialIndex,
    setIsZoom,
    setFullViewThumbs,
    setFullView,
    setFullViewThumbIndex,
    handleZoom,
    closeButtonHandle,
    handleEscape,
    handleSlideChange,
  };
};
