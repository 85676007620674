import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginCreateOtp,
  loginUser,
  verifyOtp,
  storeCurrentAuthData,
  forgotPassword,
  showSignInState,
  changeScreen,
  authModalHandler,
  storeAuthDataProgress,
} from "../store/slices/authSlice";
import { getProfileDetails } from "../store/slices/userprofileSlice";
import { useRegister } from "./useRegister";

export const useLogin = () => {
  const [loginErrors, setLoginErrors] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginUserFunction = async (data) => {
    let authDataProgress = {
      ...data,
      pageFrom: "login",
    };

    dispatch(storeAuthDataProgress(authDataProgress));

    const resultAction = await dispatch(loginUser(data));
    if (loginUser.fulfilled.match(resultAction)) {
      dispatch(changeScreen(""));
      dispatch(authModalHandler(false));
      dispatch(getProfileDetails()).then((res) => {
        navigate("/dashboard");
      });
    } else {
      if (resultAction.payload) {
        setLoginErrors(resultAction.payload?.message);
        setTimeout(() => {
          setLoginErrors("");
        }, 5000);
      }
    }
  };

  const loginCreateOtpFunction = async (data) => {
    data = {
      ...data,
      pageFrom: "login_phone",
    };
    dispatch(storeCurrentAuthData(data));

    let authDataProgress = {
      ...data,
      pageFrom: "login",
    };
    dispatch(storeAuthDataProgress(authDataProgress));
    const resultAction = await dispatch(
      loginCreateOtp({
        country_dial_code: data?.country_dial_code
          ? data?.country_dial_code
          : "+91",
        country_code: data?.country_code ? data?.country_code : "IN",
        phone_number: data?.phone_number,
      })
    );
    if (loginCreateOtp.fulfilled.match(resultAction)) {
      data = {
        ...data,
        token: resultAction.payload.data.result.token,
        user_id: resultAction.payload.data.result.user_id,
      };
      dispatch(storeCurrentAuthData(data));
      dispatch(changeScreen("otp"));
    } else {
      dispatch(storeCurrentAuthData({}));
      if (resultAction.payload.validation) {
        let errors = resultAction.payload.validation.errors;
        Object.keys(data).forEach((key, index) => {
          if (errors[key] != undefined) {
            setLoginErrors(errors[key][0]);
            setTimeout(() => {
              setLoginErrors("");
            }, 3000);
          }
        });
      }
    }
  };

  const forgotPasswordHandler = async (data, { resetForm, setErrors }) => {
    dispatch(storeCurrentAuthData({ mail: data.mail }));
    const resultAction = await dispatch(forgotPassword({ email: data.mail }));
    if (forgotPassword.fulfilled.match(resultAction)) {
      setShowForgotPassword(true);
      resetForm();
      setErrors({});
    } else {
      setLoginErrors("Please enter valid Email ID ");
      setTimeout(() => {
        setLoginErrors("");
      }, 1500);
    }
  };

  return {
    setLoginErrors,
    loginUserFunction,
    loginCreateOtpFunction,
    loginErrors,
    forgotPasswordHandler,
    showForgotPassword,
    setShowForgotPassword,
  };
};
