import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Style from "./SlideShowEditNew.module.scss";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";

import useSlideShowEdit from "../../../Logic/useSlideShowEdit";
import CommonModal from "../../Modals/commonModal";
import MainSlider from "./MainSlider";
import { useSlideShowEdit1 } from "./useSlideShowEdit1";
import SlideThumbList from "./SlideThumbList";
import SlideHeader from "./SlideHeader";

export const SlideShowEditNew = () => {
  const {
    swiperRef,
    effect,
    setEffect,
    slides,
    slideData,
    slideChangeHandler,
    slideIndex,
    activeThumb,
    setActiveThumb,
    setSlideIndex,
    pausePlay,
    setPausePlay,
    time,
    setTime,
    loop,
    setLoop,
  } = useSlideShowEdit1();

  //old code
  // let navigate = useNavigate();
  // let { id } = useParams();
  // const dispatch = useDispatch();
  // const fullScreenRef = useRef();
  // const location = useLocation();
  // const windowDimensions = useDimensions();

  // const { musicList, sections, isSlideShowSaving, isSlideShowEditing } =
  //   useSelector((state) => state.gallery);
  // const [musicIndex, setMusicIndex] = useState(0);
  // useLayoutEffect(() => {
  //   if (musicList?.length == 0) {
  //     dispatch(getMusicList());
  //   }
  // }, []);

  const {
    // slides,
    setSlides,
    selectedItems,
    slideShowState,
    handleSave,
    getSlideShowsData,
    addMoreImages,
    // slideData,
    setSlideData,
    currentSelected,
    handleGoto,
    deleteImageFromState,
    // activeThumb,
    // setActiveThumb,
    effects,
    setEffects,
    audioIndex,
    setAudioIndex,
    activeIndex,
    setActiveIndex,
    showSlideShow,
    setShowSlideShow,
    showPlayPause,
    setShowPlayPause,
    inputWidth,
    setInputWidth,
    // slideDuration,
    // setSlideDuration,
    isInitialized,
    setIsInitialized,

    // swiperRef,
    swiperThumbRef,
    // slideChangeHandler,
    handleClickAddImages,
    handleClickGoBack,
    handleChange,
    handleEffectClick,
    inputFocusRef,
    enableEditing,
    changeHandler,
    handleIncrement,
    handleDecrement,
    isEditable,
    setIsEditable,
    slideShowAlertModal,
    setSlideShowAlertModal,
    handleClickedBack,
  } = useSlideShowEdit();

  return (
    <>
      {!showSlideShow ? (
        <>
          <div className={Style.slideshow_section}>
            <SlideHeader slideData={slideData} />
            <MainSlider
              slideData={slideData}
              slides={slides}
              effect={effect}
              setEffect={setEffect}
              swiperRef={swiperRef}
              slideChangeHandler={slideChangeHandler}
              slideIndex={slideIndex}
              activeThumb={activeThumb}
              setSlideIndex={setSlideIndex}
              setActiveThumb={setActiveThumb}
              pausePlay={pausePlay}
              setPausePlay={setPausePlay}
              time={time}
              setTime={setTime}
              loop={loop}
              setLoop={setLoop}
            />
            <SlideThumbList
              slides={slides}
              slideIndex={slideIndex}
              setActiveThumb={setActiveThumb}
              setTime={setTime}
              slideData={slideData}
            />
          </div>
        </>
      ) : (
        <>
          {/* <SlideShowFullView //FIXME slide show need to hide
            mainSlideIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            playTrack={playTrack}
            trackIndex={trackIndex}
            effects={effects}
            showSlideShow={showSlideShow}
            setShowSlideShow={setShowSlideShow}
            slides={slides}
            setActiveThumb={setActiveThumb}
            showPlayPause={showPlayPause}
            setShowPlayPause={setShowPlayPause}
            isMute={mute}
            setIsMute={setMute}
            muteToggle={muteToggle}
            ref={fullScreenRef}
          /> */}
        </>
      )}

      <CommonModal
        showModal={slideShowAlertModal}
        hide={() => setSlideShowAlertModal(!slideShowAlertModal)}
        className={Style.delete_this_photo_modal}
        title={""}
        content={
          <>
            <h3 className={Style.delete_title}>
              {" "}
              Do You Want To Cancel These Changes?
            </h3>
            <p className={Style.delete_content}>
              {`Your Changes Won't Be Saved. Would you like to cancel ongoing Changes?`}
            </p>
            <div className={Style.delete_btn_wrap}>
              <button
                className="btn-danger"
                onClick={() => setSlideShowAlertModal(!slideShowAlertModal)}
              >
                Continue Changes
              </button>
              <button
                className="btn-outline-gray"
                onClick={() => handleClickedBack()}
              >
                Cancel Changes
              </button>
            </div>
          </>
        }
      />
    </>
  );
};
