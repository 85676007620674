import React from "react";
import Style from "./DashboardLoader.module.scss";

export const DashboardLoader = ({ data }) => {
  return (
    <div
      className={`${Style.loader_spinner_wrap} ${
        data?.length > 0 ? Style.Data_loader : Style.noData_loader
      } noData_loader`}
    >
      <div className={Style.loader_spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
